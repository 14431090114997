import React, { Fragment, useState } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, setLocale } from 'react-redux-i18n';
import initials from 'initials';
import { motion } from 'framer-motion';
import { APPLICATION_BASE_PATH } from '../../constants';
import './NavigationBar.scss';
import CanPerform from '../CanPerform';
import { toggleNightMode } from '../../actions';
import ToggleSwitch from '../ToggleSwitch';
import useClickOutside from '../../hooks/useClickOutside';

const NavigationBar = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, currentMember, currentSystemUser, authority } = props;
  const navigationOptions = [
    {
      url: '',
      linkTextI18n: 'menu.user_search',
      requiredAuthority: [authority.viewPatients]
    },
    {
      url: '/users',
      linkTextI18n: 'menu.system_users',
      requiredAuthority: [authority.manageUsers]
    },
    {
      url: '/services',
      linkTextI18n: 'menu.services',
      requiredAuthority: [
        authority.readInvoiceReport,
        authority.managePatients,
        authority.manageBilling,
        authority.sendCommunications
      ]
    }
  ];

  const changeLocale = () => {
    if (props.locale === 'sv') {
      props.setLocale('en');
    } else {
      props.setLocale('sv');
    }
  };

  let currentUser;

  if (props.location.pathname.includes('member/')) {
    currentUser = currentMember;
  } else if (props.location.pathname.includes('user/')) {
    currentUser = currentSystemUser;
  }

  return (
    <Fragment>
      <nav className="navigation-bar">
        <div className="desktop columns">
          <div className="column is-2 logo-wrapper">
            <div className="section-logo"></div>
          </div>
          <div className="column navigation-tabs">
            <ul>
              {navigationOptions.map((nav, i) => (
                <CanPerform action={nav.requiredAuthority} key={i}>
                  <li>
                    <NavLink exact={nav.url === ''} to={`${props.match.url}${nav.url}`} activeClassName="is-active">
                      <Translate value={nav.linkTextI18n} />
                    </NavLink>
                  </li>
                </CanPerform>
              ))}
            </ul>
          </div>
          <div className="column is-2 right-align">
            <UserDropdown
              user={user}
              onToggleNightMode={props.toggleNightMode}
              nightModeActive={props.theme.nightMode}
              profileImageUrl={!props.profileImageError && props.profileImageUrl}
              onChangeLocale={changeLocale}
            />
          </div>
        </div>
        <div className="mobile">
          <div className="burger-icon" onClick={() => setDrawerOpen(!drawerOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul>
            {currentUser && currentUser.givenName ? (
              <li>
                <span className="current-page">{`${currentUser.givenName} ${currentUser.familyName}`}</span>
              </li>
            ) : (
              navigationOptions.map((nav, i) => (
                <CanPerform action={nav.requiredAuthority} key={i}>
                  <li>
                    <NavLink exact={nav.url === ''} to={`${props.match.url}${nav.url}`} activeClassName="current-page">
                      <Translate value={nav.linkTextI18n} />
                    </NavLink>
                  </li>
                </CanPerform>
              ))
            )}
          </ul>
        </div>
      </nav>
      <div className={`drawer-overlay ${drawerOpen ? 'active' : ''}`} onClick={() => setDrawerOpen(!drawerOpen)}></div>
      <div className={`drawer-menu ${drawerOpen ? 'open' : ''}`}>
        <div className="current-user">
          <UserDropdown
            user={user}
            onToggleNightMode={props.toggleNightMode}
            nightModeActive={props.theme.nightMode}
            profileImageUrl={!props.profileImageError && props.profileImageUrl}
            onChangeLocale={changeLocale}
          />
        </div>
        <div className="current-section">
          <div className="section-logo"></div>
        </div>
        <div className="nav-options">
          {navigationOptions.map((nav, i) => (
            <CanPerform action={nav.requiredAuthority} key={i}>
              <div onClick={() => setDrawerOpen(false)}>
                <NavLink exact={nav.url === ''} to={`${props.match.url}${nav.url}`} activeClassName="nav-option-active">
                  <Translate value={nav.linkTextI18n} />
                </NavLink>
              </div>
            </CanPerform>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.token.user,
    currentMember: state.members.currentMember,
    currentSystemUser: state.systemUsers.currentSystemUser,
    authority: state.sharedData.authorityTypes,
    theme: state.theme,
    profileImageUrl: state.user.profileImageUrl,
    profileImageError: state.user.profileImageError,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  toggleNightMode,
  setLocale
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(NavigationBar));

const UserDropdown = ({ user, onToggleNightMode, nightModeActive, profileImageUrl, onChangeLocale }) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const toggle = () => setDropdownActive(!dropdownActive);
  const ref = useClickOutside(() => setDropdownActive(false));

  const menuVariants = {
    open: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -10 }
  };

  return (
    <div ref={ref} className={`dropdown mr-20 ${dropdownActive ? 'is-active' : ''}`}>
      <div className="user-dropdown" onClick={toggle}>
        <span className="user-image">
          {profileImageUrl ? (
            <img src={profileImageUrl} />
          ) : (
            <span>{initials(`${user.givenName} ${user.familyName}`)}</span>
          )}
        </span>
        <span className="username">{`${user.givenName} ${user.familyName}`}</span>
        <span className={`icon small chevron ${dropdownActive ? 'rotated' : ''}`}></span>
      </div>
      <motion.div
        className="dropdown-menu mt-15"
        initial="hidden"
        animate={dropdownActive ? 'open' : 'hidden'}
        variants={menuVariants}
        transition={{ duration: 0.1 }}
      >
        <div className="dropdown-content">
          <div className="dropdown-item space-between" onClick={onToggleNightMode}>
            <div>
              <Translate value="global.dark_mode" />
            </div>
            <div>
              <ToggleSwitch onChange={onToggleNightMode} checked={nightModeActive} size="small" />
            </div>
          </div>
          <div className="dropdown-item">
            <div onClick={onChangeLocale}>
              <Translate value="global.change_language" />
            </div>
          </div>
          <Link to={`/${APPLICATION_BASE_PATH}/logout`} className="dropdown-item">
            <Translate value="global.logout" />
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

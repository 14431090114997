import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, DataTableCell, TableBody } from '@david.kucsai/react-pdf-table';
import { DATE_FORMAT } from '../../constants';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    marginTop: 10
  },
  header: {
    fontSize: 20,
    margin: 10
  },
  footer: {
    position: 'absolute',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 5,
    borderColor: 'lightgrey'
  },
  tableHeaderRightAligned: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 5,
    borderColor: 'lightgrey',
    textAlign: 'right'
  },
  tableCellHeader: {
    fontSize: 10,
    padding: 5,
    borderColor: 'lightgrey'
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
    borderColor: 'lightgrey',
    textAlign: 'right'
  }
});

const formatter = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' });

const FinalNoteBreakdownPdf = ({
  doctor,
  tableData,
  totalFinalNotes,
  totalExclVat,
  totalInclVat,
  finalNoteMeta,
  billingPeriod
}) => {
  let period;

  if (billingPeriod === 'monthly') {
    period = moment(finalNoteMeta.previousMonthStart, DATE_FORMAT).format('MMMM YYYY');
  } else {
    period = `${moment(finalNoteMeta.previousQuarterStart, DATE_FORMAT).format('MM-YYYY')}–${moment(
      finalNoteMeta.previousQuarterEnd,
      DATE_FORMAT
    )
      .add(-1, 'day')
      .format('MM-YYYY')}`;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page} debug={false}>
        <View style={styles.header}>
          <Text>Debiteringsunderlag Blodtrycksdoktorn</Text>
          <Text style={{ fontSize: 16, marginTop: 10 }}>Läkare: {doctor.label}</Text>
          <View>
            <Text style={{ fontSize: 16, marginTop: 20 }}>
              {I18n.t(
                `reports_view.doctor_statistics.final_notes_for_${billingPeriod === 'monthly' ? 'month' : 'quarter'}`,
                { period }
              )}
            </Text>
          </View>
        </View>
        <View style={{ padding: 10 }}>
          <Table data={tableData}>
            <TableHeader>
              <TableCell style={styles.tableHeader}>Besökstyp</TableCell>
              <TableCell style={styles.tableHeader}>Antal</TableCell>
              <TableCell style={styles.tableHeader}>Ersättning</TableCell>
              <TableCell style={styles.tableHeader}>Totalt exkl. moms</TableCell>
              <TableCell style={styles.tableHeader}>Total inkl. moms</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell style={styles.tableCellHeader} getContent={(r) => r.noteType} />
              <DataTableCell style={styles.tableCell} getContent={(r) => r.numFinalNotes} />
              <DataTableCell style={styles.tableCell} getContent={(r) => r.remuneration} />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => formatter.format(r.totalExclVat).slice(0, -3)}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => formatter.format(r.totalInclVat).slice(0, -3)}
              />
            </TableBody>
          </Table>
          <Table>
            <TableHeader>
              <TableCell style={styles.tableHeader}>Totalt</TableCell>
              <TableCell style={styles.tableHeaderRightAligned}>{totalFinalNotes}</TableCell>
              <TableCell style={styles.tableHeaderRightAligned}>&nbsp;</TableCell>
              <TableCell style={styles.tableHeaderRightAligned}>
                {formatter.format(totalExclVat).slice(0, -3)}
              </TableCell>
              <TableCell style={styles.tableHeaderRightAligned}>
                {formatter.format(totalInclVat).slice(0, -3)}
              </TableCell>
            </TableHeader>
          </Table>
        </View>
        <View style={styles.footer}>
          <View>
            <Text style={{ fontSize: 13, paddingBottom: 5 }}>BLODTRYCKSDOKTORN</Text>
          </View>
          <View>
            <Text>Bredbandet 1, 392 30 Kalmar • 0480-797 960 • support@blodtrycksdoktorn.se</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FinalNoteBreakdownPdf;

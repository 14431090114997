import React, { useRef, useEffect } from 'react';
import { default as ReactSelect } from 'react-select';
import DropdownIndicator from '../DropdownIndicator';

function useReactSelectFocusFix() {
  const selectRef = useRef();
  useEffect(() => {
    if (selectRef.current && selectRef.current.select) {
      selectRef.current.select.getNextFocusedOption = () => null;
    }
  }, [selectRef]);
  return selectRef;
}

const Select = (props) => {
  const ref = useReactSelectFocusFix();
  return <ReactSelect {...props} ref={ref} components={{ DropdownIndicator }} />;
};

export default Select;

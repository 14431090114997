import React, { Fragment, useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Translate, I18n } from 'react-redux-i18n';
import initials from 'initials';
import moment from 'moment';
import MemberTable from '../MemberTable';
import './SearchResultsTable.scss';
import { formatPersonalNumber, decamelize } from '../../../utils';
import { DATE_FORMAT, MEMBER_SEARCH_LIMIT } from '../../../constants';
import Button from '../../Button';
import CanPerform from '../../CanPerform';

const SearchResultsTable = ({
  data,
  isLoading,
  fetchData,
  pagination,
  limit,
  jumpToFirstPage,
  initialPage,
  initalSort,
  urlFragment,
  onEditUserInline,
  onAssignmentPriorityOverview,
  onAssignmentPriority
}) => {
  const columns = useMemo(() => {
    const editAssignmentPriority = (user) => {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        onEditUserInline(user);
        onAssignmentPriority();
      };
    };

    const assignmentPriorityOverview = (user) => {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        onEditUserInline(user);
        onAssignmentPriorityOverview();
      };
    };

    return [
      {
        Header: I18n.t('global.name'),
        accessor: 'nameAndGender',
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              <span
                className={`member-avatar-fallback ${urlFragment} ${value.gender} ${
                  initials(value.name).length > 4 ? 'long-initial' : ''
                }`}
              >
                {initials(value.name)}
              </span>
              <strong>{value.name}</strong>
            </Fragment>
          );
        }
      },
      {
        Header: I18n.t('global.personal_number'),
        accessor: 'personalNumber',
        show: urlFragment === 'member',
        Cell: ({ cell: { value } }) => {
          return <span>{formatPersonalNumber(value)}</span>;
        }
      },
      {
        Header: I18n.t('global.user_title'),
        accessor: 'title',
        show: urlFragment === 'user',
        Cell: ({ cell: { value } }) => {
          return <span>{value || <Translate value="global.administrator" />}</span>;
        }
      },
      {
        Header: I18n.t('member_details.email'),
        accessor: 'email',
        show: urlFragment === 'user',
        Cell: ({ cell: { value } }) => {
          return <span>{value || '-'}</span>;
        }
      },
      {
        Header: I18n.t('global.created'),
        accessor: 'createdDate',
        show: urlFragment === 'member',
        Cell: ({ cell: { value } }) => {
          return <span>{moment(value, DATE_FORMAT).format('YYYY-MM-DD')}</span>;
        }
      },
      {
        Header: '',
        accessor: 'icons',
        show: urlFragment === 'member',
        Cell: ({ cell: { value } }) => {
          return <Icons icons={value} />;
        }
      },
      {
        Header: '',
        accessor: 'guid',
        show: urlFragment === 'user',
        Cell: ({ cell }) => {
          const systemUser = cell.row.original;
          if (systemUser && systemUser.authorities && systemUser.authorities.includes('caregiver')) {
            return (
              <CanPerform action="manageUsers">
                <span className="row-button-container">
                  {systemUser.assignmentPriority ? (
                    <Button buttonType="tertiary" onClick={assignmentPriorityOverview(systemUser)}>
                      <Translate value="system_user_search.assignment_priority.has_priority" />
                    </Button>
                  ) : (
                    <Button buttonType="secondary" onClick={editAssignmentPriority(systemUser)}>
                      <Translate value="system_user_search.assignment_priority.give_priority" />
                    </Button>
                  )}
                </span>
              </CanPerform>
            );
          }
          return null;
        }
      }
    ];
  }, [urlFragment, onAssignmentPriorityOverview, onAssignmentPriority, onEditUserInline]);

  return (
    <div className="search-results-container">
      {pagination && pagination.totalNumberOfItems && pagination.totalNumberOfItems > 1 ? (
        <div className="search-results-count">
          {
            <Translate
              value="member_search.showing_x_of_y_results"
              currentPage={`${(pagination.offset / MEMBER_SEARCH_LIMIT) * MEMBER_SEARCH_LIMIT + 1}–${Math.min(
                (pagination.offset / MEMBER_SEARCH_LIMIT + 1) * MEMBER_SEARCH_LIMIT,
                pagination.totalNumberOfItems
              )}`}
              totalCount={pagination.totalNumberOfItems}
            />
          }
        </div>
      ) : null}
      {pagination && pagination.totalNumberOfItems !== undefined && pagination.totalNumberOfItems === 0 ? (
        <div className="search-results-table__empty">
          <Translate value="member_search.no_results" />
        </div>
      ) : (
        <div className={`search-results-table ${isLoading ? 'loading' : ''}`}>
          <MemberTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            jumpToFirstPage={jumpToFirstPage}
            initialPage={initialPage}
            initalSort={initalSort}
            urlFragment={urlFragment}
          />
        </div>
      )}
    </div>
  );
};

export default SearchResultsTable;

const Icons = ({ icons }) => {
  return (
    <div className="member-icons-container">
      {icons
        .filter((icon) => icon.data.value)
        .map((icon, i) => {
          let tooltip;
          switch (icon.dataType) {
            case 'date':
              tooltip = `${I18n.t(`icon.${decamelize(icon.data.key)}`)}: ${moment(icon.data.value, DATE_FORMAT).format(
                'YYYY-MM-DD:HH:mm'
              )}`;
              break;
            case 'enum':
              tooltip = I18n.t(`icon.${decamelize(icon.data.key)}.${icon.data.value}`);
              break;
            case 'number':
              tooltip = I18n.t(`icon.${decamelize(icon.data.key)}`, { value: icon.data.value });
              break;
            default:
              tooltip = '';
          }
          return (
            <Tooltip key={i} title={tooltip} arrow placement="top">
              <span className={`tooltip search-result-icon ${icon.iconType}`}>
                {icon.dataType === 'number' ? (
                  <span className={`badge ${decamelize(icon.data.key, '-')}`}>{icon.data.value}</span>
                ) : null}
              </span>
            </Tooltip>
          );
        })}
    </div>
  );
};

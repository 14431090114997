import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ContentLoader from '../ContentLoader';
import ProfileCard from '../ProfileCard';
import { NULL_GUID, HIDDEN_AUTHORITIES } from '../../constants';
import SaveableMultiSelect from '../SaveableMultiSelect';
import VacationPlanner from '../VacationPlanner';
import Modal from '../Modal';
import CanPerform from '../CanPerform';
import Button from '../Button';
import ErrorFallback from '../ErrorFallback';
import { decamelize } from '../../utils';
import {
  getSystemUser,
  clearCurrentSystemUser,
  updateSystemUser,
  toggleHiddenRegion,
  clearHiddenRegions,
  toggleAuthority,
  clearAuthorities,
  updateAuthorities,
  updateHiddenSources,
  updateHiddenRegions,
  clearHiddenSources,
  toggleHiddenSource,
  getVacations,
  getSystemUserProfileImage
} from '../../actions';
import './SystemUserDetailsView.scss';

class SystemUserDetailsView extends Component {
  constructor(props) {
    super(props);

    this.onDisallowedRegionChange = this.onDisallowedRegionChange.bind(this);
    this.onDisallowedSourceChange = this.onDisallowedSourceChange.bind(this);
    this.onAuthoritiesChange = this.onAuthoritiesChange.bind(this);
    this.saveAuthorites = this.saveAuthorites.bind(this);
    this.saveHiddenRegions = this.saveHiddenRegions.bind(this);
    this.saveHiddenSources = this.saveHiddenSources.bind(this);
    this.toggleAuthoritiesModal = this.toggleAuthoritiesModal.bind(this);

    this.state = {
      systemUserUpdates: {},
      hiddenSourcesUpdates: [],
      authoritiesModalVisible: false
    };
  }

  componentDidMount() {
    this.props.getSystemUser(this.props.authToken, this.props.match.params.memberId);
    this.props.getVacations(this.props.authToken, this.props.match.params.memberId);
  }

  componentDidUpdate(prevProps) {
    const { currentSystemUser } = this.props.systemUsers;

    if (currentSystemUser && currentSystemUser.guid !== prevProps.systemUsers.currentSystemUser.guid) {
      if (currentSystemUser.profileImage && currentSystemUser.profileImage.id) {
        this.props.getSystemUserProfileImage(
          this.props.authToken,
          this.props.match.params.memberId,
          currentSystemUser.profileImage.id
        );
      }
    }
  }

  onDisallowedRegionChange(value, event) {
    switch (event.action) {
      case 'clear':
        this.props.clearHiddenRegions();
        break;
      case 'remove-value':
        this.props.toggleHiddenRegion(event.removedValue.value);
        break;
      case 'select-option':
        this.props.toggleHiddenRegion(event.option.value);
        break;
      default:
        return;
    }
  }

  onDisallowedSourceChange(value, event) {
    switch (event.action) {
      case 'clear':
        this.props.clearHiddenSources();
        break;
      case 'remove-value':
        this.props.toggleHiddenSource(event.removedValue.value);
        break;
      case 'select-option':
        this.props.toggleHiddenSource(event.option.value);
        break;
      default:
        return;
    }
  }

  onAuthoritiesChange(event) {
    this.props.toggleAuthority(event.target.value);
  }

  saveAuthorites() {
    const { updateAuthorities, authToken, match, systemUsers } = this.props;

    const updatedFields = {
      user: {},
      systemUserExtras: {
        authorities: systemUsers.currentSystemUserAuthorities
      }
    };

    updateAuthorities(authToken, match.params.memberId, updatedFields);

    this.setState({
      authoritiesModalVisible: false
    });
  }

  saveHiddenSources() {
    const { updateHiddenSources, authToken, match, systemUsers } = this.props;

    const updatedFields = {
      user: {},
      systemUserExtras: {
        hiddenSources: systemUsers.currentSystemUserHiddenSources
      }
    };

    updateHiddenSources(authToken, match.params.memberId, updatedFields);
  }

  saveHiddenRegions() {
    const { updateHiddenRegions, authToken, match, systemUsers, sharedData } = this.props;

    const updatedFields = {
      user: {},
      systemUserExtras: {
        hiddenRegions: sharedData.regions.filter(
          (region) => systemUsers.currentSystemUserHiddenRegionIds.indexOf(region.id) > -1
        )
      }
    };

    updateHiddenRegions(authToken, match.params.memberId, updatedFields);
  }

  toggleAuthoritiesModal() {
    this.setState({
      authoritiesModalVisible: !this.state.authoritiesModalVisible
    });
  }

  render() {
    const {
      currentSystemUser,
      fetchingSystemUser,
      systemUserError,
      updatingHiddenSources,
      updatingHiddenRegions,
      currentSystemUserAuthorities
    } = this.props.systemUsers;

    const { regions, sources, authorities, authorityTypes: authority } = this.props.sharedData;

    return (
      <Fragment>
        <Prompt
          message={(location) => {
            if (
              (this.props.systemUsers.hasHiddenRegionChanges ||
                this.props.systemUsers.hasHiddenSourcesChanges ||
                this.props.systemUsers.hasAuthorityChanges) &&
              location.pathname.indexOf(this.props.match.params.memberId) === -1
            ) {
              return I18n.t('member_details.unsaved_changes_prompt');
            }

            return true;
          }}
        />
        <div className="details-container system-user-details">
          {!systemUserError ? (
            <Fragment>
              <div className="columns is-marginless">
                <div className="column is-4">
                  <ProfileCard
                    member={currentSystemUser}
                    error={systemUserError}
                    isLoading={fetchingSystemUser}
                    userType="user"
                    getProfileImage={this.props.getSystemUserProfileImage}
                  />
                  <CanPerform action={authority.manageUsers}>
                    {currentSystemUser.numberOfPatients > 0 ? <VacationPlanner user={currentSystemUser} /> : null}
                  </CanPerform>
                </div>
                <div className="column">
                  <div className="card-container">
                    <ContentLoader
                      isLoading={fetchingSystemUser}
                      error={systemUserError}
                      errorTitleI18n="member_details.error_fetching"
                    >
                      <h4>
                        <Translate value="global.authorities" />
                      </h4>
                      <div className="mb-30">
                        <div className="authority-columns">
                          <div>
                            {currentSystemUser &&
                              currentSystemUser.authorities &&
                              currentSystemUser.authorities
                                .filter((authority) => !HIDDEN_AUTHORITIES.includes(authority))
                                .map((authority) => I18n.t(`authorities.${decamelize(authority, '_')}`))
                                .join(', ')}
                          </div>
                          <div className="is-1 text-right">
                            <Button iconButton onClick={this.toggleAuthoritiesModal}>
                              <span className="icon edit small"></span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <h4>
                        <Translate value="system_user_details.disallowed_sources" />
                      </h4>
                      <div className="mb-30">
                        <SaveableMultiSelect
                          requiredAuthority={authority.manageUsers}
                          isSaving={updatingHiddenSources}
                          onSave={this.saveHiddenSources}
                          actionI18nKey="global.buttons.save"
                          name="hidden-sources"
                          placeholderI18nKey="global.choose"
                          defaultValue={
                            currentSystemUser.hiddenSources && currentSystemUser.hiddenSources.length
                              ? currentSystemUser.hiddenSources.map((source) => {
                                  return { value: source, label: source };
                                })
                              : []
                          }
                          currentValue={this.props.systemUsers.currentSystemUserHiddenSources}
                          noOptionsI18nKey="system_user_details.no_more_sources"
                          onChange={this.onDisallowedSourceChange}
                          options={sources.map((source) => {
                            return { value: source, label: source };
                          })}
                        />
                      </div>
                      <h4>
                        <Translate value="system_user_details.disallowed_regions" />
                      </h4>
                      <div className="mb-30">
                        <SaveableMultiSelect
                          requiredAuthority={authority.manageUsers}
                          isSaving={updatingHiddenRegions}
                          onSave={this.saveHiddenRegions}
                          actionI18nKey="global.buttons.save"
                          name="disallowed-regions"
                          placeholderI18nKey="global.choose"
                          defaultValue={
                            currentSystemUser.hiddenRegions && currentSystemUser.hiddenRegions.length
                              ? currentSystemUser.hiddenRegions.map((region) => {
                                  return { value: region.id, label: region.name };
                                })
                              : []
                          }
                          currentValue={this.props.systemUsers.currentSystemUserHiddenRegionIds}
                          noOptionsI18nKey="system_user_details.no_more_regions"
                          onChange={this.onDisallowedRegionChange}
                          options={regions
                            .filter((region) => region.id.indexOf('null') === -1 && region.id !== NULL_GUID)
                            .map((region) => {
                              return { value: region.id, label: region.name };
                            })}
                          fallbackKey="label"
                        />
                      </div>
                    </ContentLoader>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <ErrorFallback>
              <Translate value="member_details.error_fetching" />
            </ErrorFallback>
          )}
        </div>
        <Modal
          visible={this.state.authoritiesModalVisible}
          actionCompletable={true}
          headerI18nKey="global.authorities"
          actionI18nKey="global.buttons.save"
          onClose={this.toggleAuthoritiesModal}
          onActionCompleted={this.saveAuthorites}
        >
          <div className="stack">
            {authorities.map((authority, i) => {
              return (
                <div className="authority-container" key={i}>
                  <input
                    type="checkbox"
                    value={authority}
                    id={`authority-${i}`}
                    checked={currentSystemUserAuthorities.includes(authority)}
                    onChange={this.onAuthoritiesChange}
                  />
                  <label htmlFor={`authority-${i}`}>
                    <Translate value={`authorities.${decamelize(authority, '_')}`} />
                    <div className="authority-description">
                      <Translate value={`authority_description.${decamelize(authority, '_')}`} />
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    systemUsers: state.systemUsers,
    sharedData: state.sharedData
  };
};

const mapActionsToProps = {
  getSystemUser,
  clearCurrentSystemUser,
  updateSystemUser,
  toggleHiddenRegion,
  clearHiddenRegions,
  toggleAuthority,
  clearAuthorities,
  updateAuthorities,
  updateHiddenSources,
  updateHiddenRegions,
  clearHiddenSources,
  toggleHiddenSource,
  getVacations,
  getSystemUserProfileImage
};

export default connect(mapStateToProps, mapActionsToProps)(SystemUserDetailsView);

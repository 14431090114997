import { updateObjectInArray } from "../utils";

type VactionValidationError =
  | "no_caregiver_selection"
  | "region"
  | "invalid_number";

type VacationReducerState = {
  validationError?: VactionValidationError;
  startDate?: Date;
  endDate?: Date;
  numRemaining?: number;
  numTotal: number;
  dateChangeModalVisible: boolean;
  selectedSecondaries: SecondaryCaregiver[];
};

type SecondaryCaregiver = {
  id: string;
  caregiverId?: string;
  numPatients: number;
  [key: string]: FixMe;
};

type VacationAction = {
  type: string;
  propertyName?: string;
  propertyValue?: string;
  date?: Date;
  numRemaining?: number;
  numTotal: number;
  validationError?: VactionValidationError;
  caregiverId?: string;
  id?: string;
};

export const initialState = {
  validationError: undefined,
  startDate: undefined,
  endDate: undefined,
  numRemaining: -1,
  numTotal: -1,
  dateChangeModalVisible: false,
  selectedSecondaries: [
    {
      id: Math.random().toString(36),
      caregiverId: undefined,
      numPatients: 0,
    },
  ],
};

const vacationReducer = (
  state: VacationReducerState = initialState,
  action: VacationAction
) => {
  switch (action.type) {
    case "setFromDate":
      return { ...state, startDate: action.date };
    case "setToDate":
      return { ...state, endDate: action.date };
    case "setNumRemaining":
      return {
        ...state,
        numRemaining: action.numRemaining,
        numTotal: action.numRemaining,
      };
    case "setNumTotal":
      return { ...state, numTotal: action.numTotal };
    case "addSecondary":
      return {
        ...state,
        selectedSecondaries: [
          ...state.selectedSecondaries,
          {
            id: Math.random().toString(36),
            caregiverId: action.caregiverId,
            numPatients: "",
          },
        ],
      };
    case "setPropertyForSecondary": {
      const updatedSecondaryIndex = state.selectedSecondaries.findIndex(
        (secondary) => secondary.id === action.id
      );
      const updatedSecondary = state.selectedSecondaries.find(
        (secondary) => secondary.id === action.id
      );
      if (updatedSecondary) {
        // Null coalesce to 'numPatients' as a fallback since the type definition states that `propertyName` can be undefined. Should never actually happen.
        updatedSecondary[action.propertyName ?? "numPatients"] =
          action.propertyValue;
      }

      const selectedSecondaries = updateObjectInArray(
        state.selectedSecondaries,
        {
          item: updatedSecondary,
          index: updatedSecondaryIndex,
        }
      );

      return {
        ...state,
        numRemaining: state.selectedSecondaries.reduce(
          (accumulator: number, currentValue: SecondaryCaregiver) => {
            return accumulator - currentValue.numPatients;
          },
          state.numTotal
        ),
        selectedSecondaries,
      };
    }
    case "setValidationError":
      return { ...state, validationError: action.validationError };
    case "setNewToDate":
      return { ...state, newToDate: action.date };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default vacationReducer;

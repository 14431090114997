import * as types from '../constants/actionTypes';
import { getGenderFromPersonalNumber, toggleItem, updateObjectInArray } from '../utils';
import { HTTP_STATUS_CODES } from '../constants';

const systemUsersReducer = (
  state = {
    members: [],
    returnToIndexPage: false,
    currentSystemUser: {},
    currentSystemUserHiddenRegionIds: [],
    hasHiddenRegionChanges: false,
    currentSystemUserAuthorities: [],
    currentSystemUserHiddenSources: [],
    hasAuthorityChanges: false,
    vacationDateChangeModalVisible: false,
    currentSearchQuery: {
      limit: 15,
      offset: 0,
      pageIndex: 0,
      query: '',
      returnToIndexPage: false,
      searchTerm: '',
      sortBy: undefined
    },
    assignmentEditUser: {}
  },
  action
) => {
  switch (action.type) {
    case types.GET_SYSTEM_USERS_REQUEST:
      return { ...state, fetchingMembers: true };
    case types.GET_SYSTEM_USERS_SUCCESS:
      return {
        ...state,
        fetchingMembers: false,
        members: action.payload.members.map((userSearchResult) => {
          return {
            original: userSearchResult,
            ...userSearchResult.user,
            ...userSearchResult.systemUserExtras,
            nameAndGender: {
              name: `${userSearchResult.user.givenName} ${userSearchResult.user.familyName}`,
              gender: getGenderFromPersonalNumber(userSearchResult.user.personalNumber)
            }
          };
        }),
        pagination: action.payload.pagination,
        returnToIndexPage: action.returnToIndexPage,
        membersError: undefined
      };
    case types.GET_SYSTEM_USERS_ERROR:
      return { ...state, fetchingMembers: false, membersError: action.payload };
    case types.SET_CURRENT_SYSTEM_USER_SEARCH_QUERY:
      return { ...state, currentSearchQuery: action.payload };
    case types.GET_SYSTEM_USER_REQUEST:
      return { ...state, fetchingSystemUser: true };
    case types.GET_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        fetchingSystemUser: false,
        currentSystemUser: {
          ...state.currentSystemUser,
          ...action.payload.user,
          ...action.payload.systemUserExtras,
          original: action.payload
        },
        currentSystemUserHiddenRegionIds: [
          ...((action.payload.systemUserExtras && action.payload.systemUserExtras.hiddenRegions) || []).map(
            (region) => region.id
          )
        ],
        currentSystemUserAuthorities: [
          ...((action.payload.systemUserExtras && action.payload.systemUserExtras.authorities) || [])
        ],
        currentSystemUserHiddenSources: [
          ...((action.payload.systemUserExtras && action.payload.systemUserExtras.hiddenSources) || [])
        ]
      };
    case types.GET_SYSTEM_USER_ERROR:
      return { ...state, fetchingSystemUser: false, systemUserError: action.payload };
    case types.CLEAR_CURRENT_SYSTEM_USER:
      return {
        ...state,
        currentSystemUser: {},
        systemUserUpdateError: undefined,
        hasAuthorityChanges: false,
        hasHiddenRegionChanges: false,
        hasHiddenSourcesChanges: false
      };
    case types.UPDATE_SYSTEM_USER_REQUEST:
      return { ...state, updatingSystemUser: true };
    case types.UPDATE_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        updatingSystemUser: false,
        hasHiddenRegionChanges: false,
        hasAuthorityChanges: false,
        currentSystemUser: { ...action.payload.user, ...action.payload.systemUserExtras, original: action.payload },
        systemUserUpdateError: undefined
      };
    case types.UPDATE_SYSTEM_USER_ERROR:
      return { ...state, updatingSystemUser: false, systemUserUpdateError: action.payload };
    case types.TOGGLE_HIDDEN_REGION:
      return {
        ...state,
        hasHiddenRegionChanges: true,
        currentSystemUserHiddenRegionIds: toggleItem(state.currentSystemUserHiddenRegionIds, action.payload)
      };
    case types.CLEAR_HIDDEN_REGIONS:
      return { ...state, hasHiddenRegionChanges: true, currentSystemUserHiddenRegionIds: [] };
    case types.TOGGLE_AUTHORITY:
      return {
        ...state,
        hasAuthorityChanges: true,
        currentSystemUserAuthorities: toggleItem(state.currentSystemUserAuthorities, action.payload)
      };
    case types.CLEAR_AUTHORITIES:
      return { ...state, hasAuthorityChanges: true, currentSystemUserAuthorities: [] };
    case types.TOGGLE_HIDDEN_SOURCE:
      return {
        ...state,
        hasHiddenSourcesChanges: true,
        currentSystemUserHiddenSources: toggleItem(state.currentSystemUserHiddenSources, action.payload)
      };
    case types.CLEAR_HIDDEN_SOURCES:
      return { ...state, hasHiddenSourcesChanges: true, currentSystemUserHiddenSources: [] };
    case types.UPDATE_AUTHORITIES_REQUEST:
      return { ...state, updatingAuthorities: true };
    case types.UPDATE_AUTHORITIES_SUCCESS:
      return {
        ...state,
        updatingAuthorities: false,
        hasAuthorityChanges: false,
        updateAuthoritiesError: undefined,
        currentSystemUser: {
          ...state.currentSystemUser,
          ...action.payload.user,
          ...action.payload.systemUserExtras,
          original: action.payload
        }
      };
    case types.UPDATE_AUTHORITIES_ERROR:
      return { ...state, updatingAuthorities: false, updateAuthoritiesError: action.payload };
    case types.UPDATE_HIDDEN_SOURCES_REQUEST:
      return { ...state, updatingHiddenSources: true };
    case types.UPDATE_HIDDEN_SOURCES_SUCCESS:
      return {
        ...state,
        updatingHiddenSources: false,
        hasHiddenSourcesChanges: false,
        updateHiddenSourcesError: undefined,
        currentSystemUser: {
          ...state.currentSystemUser,
          ...action.payload.user,
          ...action.payload.systemUserExtras,
          original: action.payload
        }
      };
    case types.UPDATE_HIDDEN_SOURCES_ERROR:
      return { ...state, updatingHiddenSources: false, updateHiddenSourcesError: action.payload };
    case types.UPDATE_HIDDEN_REGIONS_REQUEST:
      return { ...state, updatingHiddenRegions: true };
    case types.UPDATE_HIDDEN_REGIONS_SUCCESS:
      return {
        ...state,
        updatingHiddenRegions: false,
        hasHiddenRegionChanges: false,
        updateHiddenRegionsError: undefined,
        currentSystemUser: {
          ...state.currentSystemUser,
          ...action.payload.user,
          ...action.payload.systemUserExtras,
          original: action.payload
        }
      };
    case types.UPDATE_HIDDEN_REGIONS_ERROR:
      return { ...state, updatingHiddenRegions: false, updateHiddenRegionsError: action.payload };
    case types.POST_VACATION_REQUEST:
      return { ...state, postingVacation: true };
    case types.POST_VACATION_SUCCESS:
      return {
        ...state,
        postingVacation: false,
        currentSystemUser: { ...state.currentSystemUser, postedVacation: action.payload },
        vacationError: undefined
      };
    case types.POST_VACATION_ERROR:
      return { ...state, postingVacation: false, vacationError: action.payload };
    case types.DELETE_VACATION_REQUEST:
      return { ...state, deletingVacation: true };
    case types.DELETE_VACATION_SUCCESS:
      return {
        ...state,
        deletingVacation: false,
        currentSystemUser: { ...state.currentSystemUser, postedVacation: null },
        deleteVacationError: undefined
      };
    case types.DELETE_VACATION_ERROR:
      return { ...state, deletingVacation: false, deleteVacationError: action.payload };
    case types.UPDATE_VACATION_REQUEST:
      return { ...state, updatingVacation: true };
    case types.UPDATE_VACATION_SUCCESS:
      return {
        ...state,
        updatingVacation: false,
        vacationDateChangeModalVisible: false,
        currentSystemUser: { ...state.currentSystemUser, postedVacation: action.payload },
        UpdateVacationError: undefined
      };
    case types.UPDATE_VACATION_ERROR:
      return { ...state, updatingVacation: false, UpdateVacationError: action.payload };
    case types.SHOW_VACATION_DATE_CHANGE_MODAL:
      return { ...state, vacationDateChangeModalVisible: true };
    case types.HIDE_VACATION_DATE_CHANGE_MODAL:
      return { ...state, vacationDateChangeModalVisible: false };
    case types.GET_VACATIONS_REQUEST:
      return { ...state, loadingVacations: true };
    case types.GET_VACATIONS_SUCCESS:
      return {
        ...state,
        loadingVacations: false,
        currentSystemUser: { ...state.currentSystemUser, postedVacation: action.payload[0] },
        vacationsError: undefined
      };
    case types.GET_VACATIONS_ERROR:
      return {
        ...state,
        loadingVacations: false,
        vacationsError:
          action.payload.status && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.SET_ASSIGNMENT_EDIT_USER:
      return { ...state, assignmentEditUser: action.user };
    case types.REMOVE_ASSIGNMENT_PRIORITY: {
      let userIndex = state.members.findIndex((member) => member.guid === action.guid);
      return {
        ...state,
        members: updateObjectInArray(state.members, {
          index: userIndex,
          item: {
            assignmentPriority: null
          }
        })
      };
    }
    case types.ADD_ASSIGNMENT_PRIORITY: {
      let userIndex = state.members.findIndex((member) => member.guid === action.guid);
      return {
        ...state,
        members: updateObjectInArray(state.members, {
          index: userIndex,
          item: {
            assignmentPriority: action.assignmentPriority
          }
        })
      };
    }
    case types.GET_SYSTEM_USER_PROFILE_IMAGE_REQUEST:
      return { ...state, loadingProfileImage: true };
    case types.GET_SYSTEM_USER_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loadingProfileImage: false,
        currentSystemUser: { ...state.currentSystemUser, profileImageUrl: window.URL.createObjectURL(action.payload) },
        systemUserProfileImageError: undefined
      };
    case types.GET_SYSTEM_USER_PROFILE_IMAGE_ERROR:
      return { ...state, loadingProfileImage: false, systemUserProfileImageError: action.payload };
    default:
      return state;
  }
};

export default systemUsersReducer;

import { useState } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Card from '../Card';
import LoaderButton from '../LoaderButton';
import Button from '../Button';
import api from '../../api/apiClient';
import { showNotification } from '../../actions';
import { updateObjectInArray } from '../../utils';
import './TagIncomingPatientsView.scss';

const TagIncomingPatientsView = ({ authToken, showNotification }) => {
  const emptyReferral = {
    personalNumber: '',
    phoneNumber: ''
  };
  const [postingNumbers, setPostingNumbers] = useState(false);
  const [referredPatients, setReferredPatients] = useState([{ ...emptyReferral }]);
  const source = 'MinDoktor';

  const postMemberData = () => {
    setPostingNumbers(true);

    const formattedReferrals = referredPatients
      .filter((p) => p.personalNumber.length)
      .map((p) => {
        let formatted = p.personalNumber;
        formatted = formatted.trim();
        formatted = formatted.replace('-', '');
        formatted = !formatted.startsWith('19') && !/[a-zA-Z]/.test(formatted) ? `19${formatted}` : formatted;
        return {
          ...p,
          personalNumber: formatted.length === 12 ? formatted : p.personalNumber,
          personalNumberError: formatted.length !== 12 || /[a-zA-Z]/.test(formatted)
        };
      });

    if (formattedReferrals.some((r) => r.personalNumberError)) {
      setReferredPatients(formattedReferrals);
      setPostingNumbers(false);
      return;
    }

    formattedReferrals.forEach((r) => {
      delete r.personalNumberError;

      if (!r.phoneNumber.length) {
        delete r.phoneNumber;
      }
    });

    api
      .tagPersonalNumbersWithSource(authToken, formattedReferrals, source)
      .then(() => {
        setReferredPatients([{ ...emptyReferral }]);
        setPostingNumbers(false);
        showNotification(
          I18n.t(
            `notification.tag_incoming_patients.success_${formattedReferrals.length > 1 ? 'multiple' : 'single'}`,
            { numPatients: formattedReferrals.length, source }
          ),
          'success'
        );
      })
      .catch((error) => {
        console.log(error);
        setPostingNumbers(false);
        showNotification(I18n.t('notification.tag_incoming_patients.error'), 'error');
      });
  };

  const updatePersonalNumber = (i, value) => {
    setReferredPatients(
      updateObjectInArray(referredPatients, {
        index: i,
        item: { personalNumber: value }
      })
    );
  };

  const updatePhoneNumber = (i, value) => {
    setReferredPatients(
      updateObjectInArray(referredPatients, {
        index: i,
        item: { phoneNumber: value }
      })
    );
  };

  return (
    <Card
      titleI18nKey="reports_view.tag_incoming_patients.header"
      descriptionI18nKey="reports_view.tag_incoming_patients.description"
    >
      <div className="mb-20">
        <div className="flex">
          <div className="flex-1 mr-10">
            <h4>
              <Translate value="global.personal_number" />
            </h4>
          </div>
          <div className="flex-1">
            <h4>
              <Translate value="member_details.phone_number" />
            </h4>
          </div>
        </div>
        {referredPatients.map((_, i) => (
          <div className="flex mb-10" key={i}>
            <div className="flex-1 mr-10">
              <input
                className={referredPatients[i].personalNumberError ? 'error' : ''}
                type="text"
                value={referredPatients[i].personalNumber}
                onChange={(e) => updatePersonalNumber(i, e.target.value)}
              />
            </div>
            <div className="flex-1">
              <input
                type="text"
                placeholder={I18n.t('global.optional')}
                value={referredPatients[i].phoneNumber}
                onChange={(e) => updatePhoneNumber(i, e.target.value)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="mb-20 add-more-wrapper">
        <Button buttonType="secondary" onClick={() => setReferredPatients([...referredPatients, emptyReferral])}>
          <Translate value="reports_view.tag_incoming_patients.add_more" />
        </Button>
      </div>

      <div>
        <LoaderButton
          buttonType="primary"
          disabled={!referredPatients.some((r) => r.personalNumber)}
          onClick={postMemberData}
          isLoading={postingNumbers}
        >
          <Translate value="global.buttons.save" />
        </LoaderButton>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(TagIncomingPatientsView);

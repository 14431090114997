export const sv = {
  global: {
    title: 'Accumbo Admin',
    loading: 'Hämtar...',
    dark_mode: 'Mörkt läge',
    change_language: 'Byt språk',
    buttons: {
      save: 'Spara',
      done: 'Klar',
      cancel: 'Avbryt',
      ok: 'OK',
      generate: 'Generera',
      block: 'Blockera användare',
      reactivate: 'Återaktivera användare',
      send_message: 'Skicka meddelande',
      remove: 'Ta bort'
    },
    male: 'Man',
    female: 'Kvinna',
    authorities: 'Rättigheter',
    personal_number: 'Personnummer',
    name: 'Namn',
    created: 'Skapad',
    search: 'Sök',
    previous: 'Föregående',
    next: 'Nästa',
    device: {
      ANDROID: 'Android',
      IOS: 'iOS',
      UNKNOWN: 'Okänd'
    },
    years: 'år',
    show_error_info: 'Visa detaljerad information',
    reason: 'Orsak',
    logout: 'Logga ut',
    login: 'Logga in',
    status: 'Status',
    service_status: {
      active: 'Aktiv',
      hidden: 'Inaktiv',
      blocked: 'Blockerad',
      nurse: 'Hanteras av SSK',
      doctor: 'Hanteras av läkare'
    },
    choose: 'Välj...',
    all: 'Alla',
    choose_date: 'Välj datum',
    username: 'Användarnamn',
    password: 'Lösenord',
    disallowed_route: 'Du har inte åtkomst till denna sida. 😐',
    from_date: 'Från',
    to_date: 'Till',
    administrator: 'Administratör',
    user_title: 'Roll',
    no_address: 'Adress saknas',
    copy: 'Kopiera',
    yes: 'Ja',
    no: 'Nej',
    doctor: 'Läkare',
    patients: 'patienter',
    of: 'av',
    final_note_reason: {
      checkup: 'Ospecificerad kontroll',
      new_member_checkup: 'Första läkarkontrollen',
      three_month_checkup: '3-månaderskontroll',
      yearly_checkup: 'Årskontroll',
      extra_checkup: 'Extra kontroll',
      six_week_checkup: '6-veckorskontroll',
      initial_checkup: 'Initiering av hemblodtrycksmätning',
      nurse_treatment: 'Sjukvårdande behandling, hypertoni',
      nurse_new_member: 'SSK ny patient',
      nurse_two_week: 'SSK tvåveckorskontroll',
      nurse_six_month: 'SSK sexmånaderskontroll',
      nurse_yearly: 'SSK årskontroll',
      nurse_extra: 'SSK extrakontroll',
      missing: 'Kontrolltyp saknas'
    },
    payment_state: {
      created: 'Betalning begärd',
      pending: 'Påbörjad',
      exemption: 'Frikort',
      complete_unknown: 'Betald',
      complete_swish: 'Betald med Swish',
      complete_card: 'Betald med kort',
      complete_invoice: 'Betald med faktura',
      failed: 'Betalning misslyckades',
      error: 'Betalning misslyckades',
      cancelled: 'Avbruten'
    },
    payment_provider: {
      nordeaconnect: 'Nordea',
      invoice: 'Faktura',
      billogram: 'Billogram'
    },
    show_all: 'Visa alla',
    show_fewer: 'Visa färre',
    monitor_size: {
      S: 'Small (18-21 cm)',
      M: 'Medium (22-32 cm)',
      L: 'Large (33-42 cm)',
      XL: 'XLarge (43-56 cm)'
    },
    optional: 'Frivilligt',
    year: 'År',
    quarter: 'Kvartal',
    month: 'Månad'
  },
  icon: {
    service_status: {
      blocked: 'Blockerad'
    },
    last_measure_date: 'Senaste mätning',
    last_lab_result_date: 'Senaste labbsvar',
    closed_support_issues: 'Stängda supportärenden: %{value}',
    open_support_issues: 'Öppna supportärenden: %{value}',
    last_monitor_sent_date: 'Mätare skickad'
  },
  notification: {
    save_generic: {
      success: 'Nytt värde sparat.',
      error: 'Det nya värdet kunde inte sparas.'
    },
    service_status_active: {
      success: '%{name} har avblockerats',
      error: '%{name} kunde inte avblockeras'
    },
    service_status_blocked: {
      success: '%{name} har blockerats',
      error: '%{name} kunde inte blockeras'
    },
    service_status_hidden: {
      success: '%{name} har markerats som inaktiv',
      error: '%{name} kunde inte markeras som inaktiv'
    },
    save_arm_circumference: {
      success: 'Ny mätarstorlek sparad.',
      error: 'Det gick inte att spara mätarstorleken.'
    },
    send_bulk_message: {
      success: 'Meddelande skickat.',
      error: 'Meddelandet kunde inte skickas.'
    },
    update_authorities: {
      error: 'Det gick inte att uppdatera rättigheterna.'
    },
    update_hidden_regions: {
      error: 'Det gick inte att uppdatera otillåtna regioner.'
    },
    update_hidden_sources: {
      error: 'Det gick inte att uppdatera otillåtna källor.'
    },
    new_vacation: {
      success: 'Vikariat registrerat.',
      error: 'Det gick inte att registrera vikariatet.'
    },
    delete_vacation: {
      success: 'Vikariat borttaget.',
      error: 'Det gick inte att ta bort vikariatet.'
    },
    update_vacation: {
      success: 'Slutdatum för vikariatet ändrat.',
      error: 'Det gick inte att ändra slutdatum.'
    },
    send_bulk_referrals: {
      success: 'Utskickning av remisser påbörjad.',
      error: 'Det gick inte att skicka remisser.'
    },
    save_comment: {
      success: 'Anteckning sparad.',
      error: 'Det gick inte att spara anteckningen.'
    },
    create_jira_issue: {
      success: 'Ärende skapat',
      error: 'Kunde inte skapa ärendet.'
    },
    delete_shipment: {
      error: 'Kunde inte ta bort utskicket.'
    },
    add_manual_shipment_outgoing: {
      success: 'Försändelse skapad.',
      error: 'Kunde inte skapa försändelse.'
    },
    add_manual_shipment_return: {
      success: 'Retur skapad.',
      error: 'Kunde inte skapa retur.'
    },
    delay_shipment: {
      success: 'Försändelsen har skjutits upp.',
      error: 'Kunde inte skjuta upp försändelsen.'
    },
    undo_delay_shipment: {
      success: 'Uppskjutningen ångrades.',
      error: 'Uppskjutningen kunde inte ångras.'
    },
    update_shipment_delay: {
      success: 'Uppskjutningsdatumet uppdaterades.',
      error: 'Kunde inte uppdatera uppskjutningsdatumet.'
    },
    update_communication: {
      error: 'Kunde inte skjuta upp kommunikationen.'
    },
    delete_communication: {
      error: 'Kunde inte ta bort kommunikationen.'
    },
    update_canned_response_title: {
      success: 'Rubrik uppdaterad till "%{title}"',
      error: 'Kunde inte uppdatera rubriken.'
    },
    update_canned_response_body: {
      success: 'Text för "%{title}" uppdaterad.',
      error: 'Kunde inte uppdatera texten för "%{title}"'
    },
    create_canned_response: {
      error: 'Kunde inte skapa kategorin/rubriken.'
    },
    delete_canned_response: {
      error: 'Kunde inte ta bort kategorin/rubriken.'
    },
    update_canned_response: {
      error: 'Kunde inte uppdatera kategorin/rubriken.'
    },
    member_message: {
      success: 'Meddelande skickat.',
      error: 'Meddelandet kunde inte skickas. Försök igen eller kontakta teknisk support.'
    },
    export_journal: {
      error: 'Kunde inte ladda ner journal. Försök igen eller kontakta teknisk support.'
    },
    priority_saved: {
      success: 'Förtur sparad för %{name}.',
      error: 'Det gick inte att spara förtursinställningen.'
    },
    priority_removed: {
      success: 'Förtur borttagen för %{name}',
      error: 'Det gick inte att ta bort förturen.'
    },
    payment_exemption_added: {
      success: 'Frikort sparat.',
      error: 'Det gick inte att spara frikortet.'
    },
    payment_exemption_updated: {
      success: 'Frikort uppdaterat.',
      error: 'Det gick inte att uppdatera frikortet.'
    },
    payment_exemption_removed: {
      success: 'Frikort borttaget.',
      error: 'Det gick inte att ta bort frikortet.'
    },
    download_pdf: {
      error: 'Kunde inte ladda ner PDF. Försök igen eller kontakta teknisk support.'
    },
    download_receipt: {
      error: 'Kunde inte ladda ner kvittot. Försök igen eller kontakta teknisk support.'
    },
    download_invoice: {
      error: 'Kunde inte ladda ner faktura. Försök igen eller kontakta teknisk support.'
    },
    upload_profile_picture: {
      success: '%{name}s profilbild uppdaterades.',
      error: 'Det gick inte att ladda upp bilden.'
    },
    tag_incoming_patients: {
      success_single: 'Personnumret taggades med "%{source}".',
      success_multiple: '%{numPatients} personnummer taggades med "%{source}".',
      error: 'Det gick inte att tagga. Försök igen eller kontakta teknisk support.'
    },
    get_final_note_breakdown: {
      error: 'Kunde inte hämta slutanteckningsstatistiken.'
    },
    get_final_notes_per_type: {
      error: 'Kunde inte hämta slutanteckningsstatistiken.'
    }
  },
  menu: {
    user_search: 'Patienter',
    support: 'Supportcenter',
    system_users: 'Systemanvändare',
    reports: 'Rapporter',
    services: 'Tjänster'
  },
  login_view: {
    start_login: 'Starta inloggning',
    remember_me: 'Kom ihåg inloggningsuppgifter',
    start_bank_id: 'Starta appen Mobilt BankID',
    bank_id_instruction: 'För att logga in, starta din BankID-applikation på din mobil.',
    waiting_for_confirmation: 'Väntar på bekräftelse',
    footer_info: ' • Bredbandet 1, 392 30 Kalmar • 0480-797 960 • support@accumbo.se'
  },
  member_search: {
    input_placeholder: 'Sök på namn, personnummer, telefon eller e-postadress',
    no_results: 'Vi hittade ingen person som matchade dina sökkriterier.',
    showing_x_of_y_results: 'Visar %{currentPage} av %{totalCount} resultat',
    error: 'Kunde inte hämta användare.',
    show_advanced_search: 'Visa avancerade sökalternativ',
    hide_advanced_search: 'Dölj avancerade sökalternativ',
    special_searches: 'Specialsökningar',
    all_regions: 'Alla regioner',
    all_sources: 'Alla källor',
    all_platforms: 'Alla plattformar',
    all_arm_circumferences: 'Alla storlekar',
    created_date: 'Registreringsdatum'
  },
  system_user_search: {
    assignment_priority: {
      give_priority_header: 'Ge förtur till paxning av patienter',
      current_patients: 'Nuvarande antal patienter',
      patients_increase: 'Antal patienter att öka med',
      patients_increase_placeholder: 'Exempelvis 200',
      priority_overview_header: 'Översikt av förtur till paxning ',
      priority_start_date: 'Startdatum',
      patients_at_start: 'Vid start',
      priority_limit: 'Fick förtur till',
      assigned: 'Paxat',
      delete_button: 'Ta bort förtur',
      give_priority: 'Ge förtur',
      has_priority: 'Har förtur'
    }
  },
  member_details: {
    error_fetching: 'Kunde inte hämta användarinformation. Prova igen senare eller kontakta teknisk support.',
    error_fetching_region_list: 'Kunde inte hämta regionlista',
    change_details: 'Ändra uppgifter',
    given_name: 'Förnamn',
    family_name: 'Efternamn',
    phone_number: 'Telefon',
    email: 'E-post',
    age: 'Ålder',
    street_address: 'Gatuadress',
    postal_code: 'Postnummer',
    city: 'Stad',
    platform: 'Plattform',
    created: 'Skapad',
    region: 'Provtagningsregion',
    source: 'Källa',
    update_error: 'Kunde inte spara uppdaterade användaruppgifter. Vänligen försök igen.',
    unsaved_changes_prompt: 'Det finns osparade ändringar. Lämna sidan ändå?',
    responsible_doctor: 'Patientansvarig läkare',
    responsible_nurse: 'Patientansvarig sjuksköterska',
    not_active_yet: 'Ej aktiv ännu',
    error_fetching_pal_list: 'Kunde inte hämta läkarlista',
    error_fetching_pas_list: 'Kunde inte hämta sjuksköterskelista',
    confirm_member_block: 'Är du säker på att du vill blockera den här användaren?',
    block_member_error: 'Kunde inte blockera användaren.',
    reactivate_member_error: 'Kunde inte återaktivera användaren',
    treatment: 'Behandling',
    latest_lab_results: 'Senaste labbsvar',
    testing: 'Provtagning',
    arm_circumference: 'Mätarstorlek',
    select_monitor_size: 'Välj storlek',
    general: 'Allmänt',
    blocked: 'Blockerad/avaktiverad',
    address_information: 'Adressuppgifter',
    no_address_information: 'Inga adressuppgifter',
    contact_information: 'Kontaktuppgifter',
    no_contact_information: 'Inga kontaktuppgifter',
    edit_profile: 'Redigera profil',
    update_profile_error: 'Det gick inte att uppdatera profilen just nu.',
    save_details: 'Spara uppgifter',
    biometrics: 'Biometri',
    insurance_header: 'Försäkringsinformation',
    claim_id: 'SkadeID',
    create_issue: 'Skapa nytt ärende',
    no_issues: 'Inga ärenden',
    issue_single: '1 ärende',
    issue_multiple: '%{numIssues} ärenden',
    choose_region: 'Välj en region',
    comment: 'Anteckning',
    last_measurement: 'Senaste mätning',
    last_login: 'Senaste inloggning',
    send_message_to: 'Skicka meddelande till %{name}',
    send_message: 'Skicka meddelande',
    message_subject_header: 'Ämnesrad',
    message_body_header: 'Meddelande',
    message_discard_alert: 'Kasta meddelandet du börjat skriva?',
    monitor_sent: 'Mätare skickad',
    mark_inactive: 'Markera som inaktiv',
    inactive_tooltip:
      'Patienten kommer inte dyka upp i läkarens ärendelista förrän patienten har loggat in i tjänsten på nytt.',
    chat_communication_header: 'Läst senaste meddelande',
    empty_chat: 'Inga chattmeddelanden',
    choose_journal_timespan: 'Välj tidsperiod...',
    timespan_header: 'Tidsperiod',
    journal_timespan: {
      two_weeks: '2 veckor',
      one_month: '1 månad',
      ninety_days: '90 dagar'
    },
    updates: {
      responsible_doctor: {
        success: 'PAL ändrad till %{newValue}.',
        error: 'Kunde inte ändra PAL.'
      },
      responsible_nurse: {
        success: 'PAS ändrad till %{newValue}.',
        error: 'Kunde inte ändra PAS.'
      },
      region: {
        success: 'Region ändrad till %{newValue}.',
        error: 'Kunde inte ändra region.'
      }
    },
    actions: {
      create_shipment: 'Skicka mätare',
      create_return: 'Skapa retur',
      export_journal: 'Skapa journalutdrag',
      show_spar_info: 'Visa information från SPAR',
      open_in_clinic: 'Öppna i Clinic'
    },
    scheduled_communication: {
      header: 'Schemalagd kommunikation',
      empty: 'Ingen kommunikation har schemalagts för denna patient.',
      name: 'Ärende',
      sends_in: 'Skickas om',
      delete: 'Ta bort',
      confirm_delete: 'Är du säker på att du vill ta bort kommunikationen?',
      postpone: 'Förskjut 1 vecka',
      edit: 'Ändra',
      error: 'Kunde inte hämta schemalagd kommunikation.',
      type: {
        email: 'E-post',
        sms: 'SMS',
        push: 'Push',
        jira: 'Jira',
        chat: 'Chatt'
      }
    },
    spar: {
      modal_header: 'Information från SPAR',
      error: 'Kunde inte hämta data från SPAR.',
      deregistration_code: {
        A: 'Avliden',
        AV: 'Avliden',
        G: 'Gammalt nummer',
        O: 'Övrig orsak',
        UV: 'Utvandrad',
        GN: 'Gammalt personnummer',
        GS: 'Gammalt samordningsnummer',
        AS: 'Annullering av samordningsnummer',
        OB: 'Försvunnen',
        TA: 'Teknisk avregistrering',
        FI: 'Falsk identitet'
      }
    },
    payment_exemption_header: 'Frikort',
    payment_exemption_card_number: 'Frikortsnummer',
    payment_exemption_card_date: 'Giltigt till',
    payment_exemption_confirm_removal: 'Är du säker på att du vill ta bort patientens frikort?',
    payment_history: {
      header: 'Patientavgiftshistorik',
      table_headers: {
        visit_type: 'Kontroll',
        status: 'Betalningsstatus',
        date: 'Datum',
        receipt: 'Kvitto'
      },
      error: 'Kunde inte hämta patientavgiftshistorik.',
      no_history: 'Patienten har ingen betalningshistorik.',
      download_receipt: 'Ladda ner kvitto',
      download_invoice: 'Ladda ner faktura'
    }
  },
  new_lab_results_view: {
    header: 'Patienter med nya labbsvar',
    search_button: 'Generera',
    action_button: 'Kopiera till urklipp',
    copied: 'Kopierat!',
    since_date: 'Sedan datum',
    no_results: 'Inga patienter med nya labbsvar sedan',
    error: 'Kunde inte hämta patienter med nya labbsvar.'
  },
  new_members_dispatch_view: {
    header: 'Utskick till nya patienter',
    no_results: 'Inga patienter registrerades, lade till sitt armmått eller angav sin region detta datum.',
    error: 'Kunde inte hämta patientlista.'
  },
  system_user_details: {
    disallowed_regions: 'Otillåtna områden',
    disallowed_sources: 'Otillåtna källor',
    no_more_regions: 'Inga regioner återstår',
    no_more_sources: 'Inga källor återstår',
    no_more_authorities: 'Inga rättigheter återstår',
    title: 'Titel',
    vacation_planning: {
      header: 'Vikariat',
      secondary_caregivers_header: 'Vikarier',
      add_caregiver: '+ Lägg till fler',
      patients_remaining_header: 'Patienter kvar att fördela',
      patients_remaining: '%{numPatients} patienter',
      patients_remaining_singular: '1 patient',
      choose_doctor: 'Välj en läkare',
      choose_nurse: 'Välj sjuksköterska',
      save_vacation: 'Registrera vikariat',
      change_to_date: 'Ändra slutdatum',
      save_to_date: 'Spara slutdatum',
      remove_vacation: 'Ta bort vikariat',
      confirm_removal: 'Är du säker på att du vill ta bort vikariatet?',
      period_header: 'Period',
      caregiver_list_header: 'Vikarierande läkare',
      error: {
        region: 'Kan inte fördela alla patienter till den valda läkaren.',
        no_caregiver_selection: 'Vänligen välj en läkare.',
        invalid_number: 'Skriv in ett giltigt antal patienter.',
        fetching_doctors: 'Kunde inte hämta läkarlista.',
        fetching_vacations: 'Kunde inte hämta aktiva vikariat.'
      }
    }
  },
  reports_view: {
    select_page: 'Välj bland tjänster i menyn',
    page: {
      invoice_report: 'Faktureringsunderlag',
      region_skane_referrals: 'Remisser för Region Skåne',
      bulk_message: 'Patientmeddelande',
      bulk_referrals: 'Remissutskick',
      shipments: 'Hantera försändelser',
      canned_responses: 'Hantera chattmallar',
      doctor_statistics: 'Vårdgivarstatistik',
      tag_incoming_patients: 'Tagga inkommande patienter'
    },
    invoice_report: {
      header: 'Faktureringsunderlag',
      month_header: 'Månad',
      year_header: 'År',
      no_data: 'Det finns inget faktureringsunderlag för den valda månaden.',
      error: 'Det gick inte att ladda ner faktureringsunderlaget.'
    },
    referrals: {
      header: 'Generera remisser för Region Skåne-patienter',
      modal_header: 'Konfigurera utskriftsinställningar',
      save_settings: 'Spara inställningar',
      new_referral: 'ny remiss',
      new_referrals: 'nya remisser',
      no_new_referrals: 'Inga nya remisser',
      error: 'Kunde inte hämta remisser.',
      test_error: 'Kunde inte hämta testremiss.',
      main_button: 'Generera remisser',
      rutin: 'Rutin 1',
      special: 'Special 3',
      x_offset: 'X-offset',
      y_offset: 'Y-offset',
      test_referral: 'Generera testremiss',
      configure: 'Konfigurera',
      desktop_only_note: 'Remissgenereringen är inte tillgänglig via mobilen.',
      get_error: 'Kunde inte hämta remisser.',
      no_referrals: 'Inga remisser att hantera.'
    },
    bulk_message: {
      header: 'Patientmeddelande',
      description:
        'När du skriver ett meddelande, markerar en eller flera läkare och klickar på "Skicka meddelande" nedan kommer ett chattmeddelande att gå iväg till alla de patienter som har de markerade läkarna som PAL. Notera att det inte går att ändra i meddelandet efter att det har skickats, och att det kan ta en stund innan det har nått alla mottagare.',
      no_more_caregivers: 'Inga fler läkare',
      select_caregivers: 'Välj behandlande läkare',
      caregiver_header: 'Behandlande läkare',
      message_header: 'Meddelande till patienter',
      send_message: 'Skicka meddelande',
      message_placeholder: 'Skriv meddelande här',
      confirm: 'Är du säker på att du vill skicka detta meddelande?'
    },
    bulk_referrals: {
      header: 'Skicka remisser till chatten',
      description:
        'Ladda upp en CSV-fil med personnummer för de patienter som i chatten ska få remissinformation från sin läkare. Filen ska innehålla ett personnummer per rad. Baserat på vald region kommer systemet för varje patient automatiskt att avgöra vilken remissinformation som skickas.',
      send_referrals: 'Skicka %{numReferrals} remisser',
      message_header: 'Meddelande i patientens chattflöde',
      message_placeholder: 'Skriv meddelande här',
      confirm: 'Är du säker på att du vill skicka remisser till dessa patienter?',
      file_format_error: 'Felaktigt formaterad data i den valda filen.',
      select_file: 'Välj CSV-fil'
    },
    shipments: {
      header: 'Hantera försändelser',
      no_shipments: 'Inga försändelser att hantera.',
      no_delayed_shipments: 'Inga dolda försändelser att hantera.',
      error: 'Kunde inte hämta försändelser.',
      done_button: 'Skicka till Logistified',
      created_at: 'Skapad',
      reason: 'Orsak',
      reasons: {
        manual: 'Manuell',
        batch: 'Automatisk',
        service_cancelled: 'Tjänst avslutad'
      },
      type: {
        outgoing: 'Utskick',
        return: 'Retur'
      },
      active_shipments: 'Försändelselista',
      delayed_shipments: 'Dolda försändelser',
      delay: 'Skjut upp',
      delay_header: 'Skjut upp utskick',
      update_delay: 'Uppdatera datum',
      update_delay_header: 'Ändra uppskjutningsdatum',
      current_delay: 'Uppskjuten till %{delayDate}',
      undo_delay: 'Ångra uppskjutning',
      change_delay_date: 'Ändra datum',
      address_missing: 'Adress saknas'
    },
    canned_responses: {
      header: 'Hantera chattmallar',
      invalid_tag: 'Texten innehåller en ogiltig tagg. Kontrollera taggarna och försök igen.',
      start_editing: 'Redigera',
      cancel_editing: 'Avbryt redigering',
      save: 'Spara ändringar',
      add_node: '+ Textmall',
      add_sub_category: '+ Underkategori',
      add_main_category: '+ Huvudkategori',
      edit_text: 'Ändra text',
      tags_header: 'Tillgängliga taggar',
      confirm_cancel: 'Det finns ändringar som inte sparats. Vill du kasta dessa?',
      expand_all: 'Expandera alla',
      collapse_all: 'Stäng alla'
    },
    doctor_statistics: {
      header: 'Läkarstatistik',
      final_notes_header: 'Slutanteckningar',
      final_notes_per_type_header: 'Slutanteckningar per typ',
      choose_year: 'Välj år',
      choose_doctor: 'Välj läkare',
      doctor_table_header: 'Läkare',
      nurse_table_header: 'Sjuksköterska',
      patient_totals_header: 'Antal patienter',
      total: 'Totalt',
      active: 'Aktiva',
      hidden: 'Inaktiva',
      blocked: 'Blockerade',
      statistics_error: 'Kunde inte hämta slutanteckningsstatistik.',
      patient_totals_error: 'Kunde inte hämta patientantal.',
      final_note_type_breakdown_header: 'Debiteringsunderlag',
      quarter: 'Kvartal (%{monthRange})',
      final_notes_for_month: 'Slutanteckningar under %{period}',
      final_notes_for_quarter: 'Slutanteckningar under kvartal %{period}',
      show_details: 'Visa detaljer',
      download_pdf: 'Ladda ner PDF',
      final_note_remuneration: 'Ersättning per slutanteckning, inkl. moms',
      total_excl_vat: 'Totalt exkl. moms',
      total_incl_vat: 'Totalt inkl. moms',
      monthly_billing: 'Månad',
      quarterly_billing: 'Kvartal',
      billing_period: 'Debiteringsperiod',
      caregiver_type: 'Vårdgivartyp'
    },
    tag_incoming_patients: {
      header: 'Tagga inkommande patienter',
      description:
        'Skriv in personnumret för en patient som ska taggas som en Min Doktor-patient vid registrering. Om du anger ett mobilnummer till patienten används detta för att skicka ett sms med en nedladdningslänk.',
      add_more: '+ Lägg till fler'
    }
  },
  jira: {
    select_issue_type: 'Välj ärendetyp',
    modal_header: 'Skapa supportärende',
    modal_button: 'Skapa ärende',
    history_header: 'Supportärenden för denna patient',
    history_error: 'Kunde inte hämta ärendehistorik.',
    history_empty: 'Inga tidigare ärenden.',
    history_column: {
      issue: 'Ärende',
      created: 'Skapat',
      status: 'Status'
    },
    description: 'Beskrivning'
  },
  profile_image_upload: {
    header: 'Uppdatera profilbild',
    header_new: 'Välj profilbild',
    action: 'Ladda upp bild',
    select_file: 'Välj fil',
    drag_here: 'eller dra en bild hit'
  },
  authorities: {
    healthscientist: 'Hälsovetare',
    caregiver: 'Läkare',
    nurse: 'Sjuksköterska',
    login_clinic: 'Clinic-login',
    login_admin: 'Admin-login',
    manage_patients: 'Hantera patienter',
    manage_users: 'Hantera systemanvändare',
    manage_content: 'Hantera innehåll',
    send_communications: 'Patientkommunikation',
    manage_billing: 'Hantera fakturering',
    read_invoice_report: 'Skapa Priveraunderlag',
    view_patients: 'Visa patienter',
    export_journal: 'Exportera journaldata'
  },
  authority_description: {
    healthscientist: 'Alla hälsovetare i systemet måste ha denna.',
    caregiver: 'Alla läkare i systemet måste ha denna.',
    nurse: 'Alla sjuksköterskor i systemet måste ha denna.',
    login_clinic: 'Krävs för att kunna logga in i Clinic.',
    login_admin: 'Krävs för att kunna logga in i Admin.',
    manage_patients: 'Krävs för att ändra patientdata (e-postadress, telefonnummer osv.).',
    manage_users:
      'Krävs för att kunna administrera systemanvändare, och ändra data för dessa (t.ex. rättigheter eller semesterperioder) samt för att ändra en patients PAL.',
    manage_content: 'Krävs för att kunna göra ändringar i chattmallarna.',
    send_communications:
      'Krävs för att göra massutskick från Admin (Patientmeddelande, Remissutskick), samt för att skicka e-post till enskilda patienter.',
    manage_billing:
      'Krävs för att se statistik över läkarnas slutanteckningar och tilldelade patienter. Kommer att kunna användas för att skapa fakturautdrag för läkare i Admin.',
    read_invoice_report: 'Krävs för att kunna skapa Privera-filen (fakturaunderlaget till Region Sörmland).',
    view_patients: 'Krävs för att över huvud taget kunna se någon patientdata.',
    export_journal: 'Krävs för att kunna exportera en patientjournal som PDF.'
  },
  error_boundary: {
    header: 'Något gick fel :(',
    summary: 'Information om felet har vidarebefordrats till utvecklingsteamet.'
  },
  server_error: {
    db_access_error: 'Databasfel',
    internal: 'Internt serverfel',
    unknown_user: 'Okänd användare',
    insufficient_privileges: 'Användaren saknar behörighet',
    authentication_error: 'Autentiseringsfel',
    user_canceled_login: 'Användaren avbröt inloggningen',
    login_already_in_process: 'Inloggning redan påbörjad',
    external_service_timeout: 'Extern tjänst tog för lång tid att svara',
    not_implemented: 'Inte implementerad',
    invalid_data_posted: 'Data skickades i felaktigt format',
    missing_data: 'Data saknas',
    id_already_exists: 'ID existerar redan',
    fallback: 'Okänt fel - kontakta teknisk support',
    connectivity: 'Kunde inte kontakta servern. Kontrollera internetanslutningen.',
    unauthorized: 'Saknar behörighet'
  }
};

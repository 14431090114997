import { v4 as uuidv4 } from 'uuid';
import { changeNodeAtPath, addNodeUnderParent, removeNodeAtPath, toggleExpandedForAll } from 'react-sortable-tree';
import { removeItemFromArray, updateObjectInArray } from '../../utils';

export const getNodeKey = ({ node }) => node.id;

export const initialState = {
  treeData: [],
  originalTreeData: [],
  isEditing: false,
  isUpdatingResponse: false,
  editModalVisible: false,
  editedNode: null,
  editedNodeBody: '',
  editedPath: null,
  addedNodes: [],
  removedNodes: [],
  updatedNodes: [],
  allNodesExpanded: false
};

export const actionTypes = {
  setTreeData: 'setTreeData',
  setEditMode: 'setEditMode',
  setModalVisibility: 'setModalVisibility',
  startEditingNode: 'startEditingNode',
  setEditedNode: 'setEditedNode',
  setEditedPath: 'setEditedPath',
  setEditedNodeBody: 'setEditedNodeBody',
  updateNodeBodySuccess: 'updateNodeBodySuccess',
  updateNodeTitleSuccess: 'updateNodeTitleSuccess',
  updateEditedNodeBody: 'updateEditedNodeBody',
  addNode: 'addNode',
  removeNode: 'removeNode',
  updateCannedResponseRequest: 'updateCannedResponseRequest',
  addNodeSuccess: 'addNodeSuccess',
  removeNodesSuccess: 'removeNodesSuccess',
  nodeOrderUpdated: 'nodeOrderUpdated',
  updateNodeOrderSuccess: 'updateNodeOrderSuccess',
  addTopLevelNode: 'addTopLevelNode',
  toggleExpandAll: 'toggleExpandAll'
};

export const reducer = (state = initialState, action) => {
  let updatedTreeData;
  let addedNodes = state.addedNodes;
  let removedNodes = state.removedNodes;
  let updatedNodes = state.updatedNodes;

  switch (action.type) {
    case actionTypes.setTreeData:
      return {
        ...state,
        treeData: action.treeData,
        originalTreeData: state.treeData.length ? state.originalTreeData : action.treeData
      };
    case actionTypes.setEditMode: {
      let treeData = state.treeData;
      addedNodes = state.addedNodes;
      let originalTreeData;

      if (!action.editable) {
        treeData = state.originalTreeData;
        addedNodes = [];
        removedNodes = [];
        updatedNodes = [];
      } else {
        originalTreeData = state.treeData;
      }

      return {
        ...state,
        treeData,
        addedNodes,
        removedNodes,
        updatedNodes,
        originalTreeData,
        isEditing: action.editable
      };
    }
    case actionTypes.setModalVisibility:
      return { ...state, editModalVisible: action.visible };
    case actionTypes.startEditingNode:
      return {
        ...state,
        editModalVisible: true,
        editedNode: action.node,
        editedPath: action.path,
        editedNodeBody: action.node.body
      };
    case actionTypes.updateNodeBodySuccess:
      updatedTreeData = changeNodeAtPath({
        treeData: state.treeData,
        path: state.editedPath,
        getNodeKey,
        newNode: { ...state.editedNode, body: state.editedNodeBody }
      });

      addedNodes = state.addedNodes;
      if (state.addedNodes.map((n) => n.id).includes(state.editedNode.id)) {
        let foundIndex = state.addedNodes.findIndex((node) => node.id === state.editedNode.id);
        addedNodes = updateObjectInArray(state.addedNodes, { index: foundIndex, item: { body: state.editedNodeBody } });
      }

      return {
        ...state,
        editModalVisible: false,
        isUpdatingResponse: false,
        treeData: updatedTreeData,
        originalTreeData: state.addedNodes.map((n) => n.id).includes(state.editedNode.id)
          ? state.originalTreeData
          : updatedTreeData,
        addedNodes,
        editedNode: null,
        editedPath: null,
        editedNodeBody: ''
      };
    case actionTypes.updateNodeTitleSuccess:
      updatedTreeData = changeNodeAtPath({
        treeData: state.treeData,
        path: action.path,
        getNodeKey,
        newNode: { ...action.node, title: action.newTitle }
      });

      if (state.addedNodes.map((n) => n.id).includes(action.node.id)) {
        let foundIndex = state.addedNodes.findIndex((node) => node.id === action.node.id);
        addedNodes = updateObjectInArray(state.addedNodes, { index: foundIndex, item: { title: action.newTitle } });
      }

      return {
        ...state,
        isUpdatingResponse: false,
        addedNodes,
        treeData: updatedTreeData,
        originalTreeData: state.addedNodes.map((n) => n.id).includes(action.node.id)
          ? state.originalTreeData
          : updatedTreeData
      };
    case actionTypes.updateEditedNodeBody:
      return { ...state, editedNodeBody: action.body };
    case actionTypes.addNode: {
      const newNode = {
        id: uuidv4(),
        title: `${action.isCategory ? 'Ny kategori' : 'Ny textmall'}`,
        parent: action.path[action.path.length - 1],
        isCategory: action.isCategory,
        body: action.isCategory ? null : '',
        order: -1,
        isNew: true
      };

      return {
        ...state,
        addedNodes: [...state.addedNodes, newNode],
        treeData: addNodeUnderParent({
          treeData: state.treeData,
          parentKey: action.path[action.path.length - 1],
          expandParent: true,
          getNodeKey,
          newNode,
          addAsFirstChild: true
        }).treeData
      };
    }
    case actionTypes.addTopLevelNode: {
      const newTopLevelNode = {
        id: uuidv4(),
        parent: null,
        title: 'Ny huvudkategori',
        body: null,
        isNew: true,
        isCategory: true,
        order: state.treeData.length + 1,
        children: []
      };

      return {
        ...state,
        addedNodes: [...state.addedNodes, newTopLevelNode],
        treeData: [...state.treeData, newTopLevelNode]
      };
    }
    case actionTypes.updateCannedResponseRequest:
      return { ...state, isUpdatingResponse: true };
    case actionTypes.removeNode:
      return {
        ...state,
        addedNodes: state.addedNodes.map((n) => n.id).includes(action.node.id)
          ? removeItemFromArray(state.addedNodes, {
              index: state.addedNodes.findIndex((node) => node.id === action.node.id)
            })
          : state.addedNodes,
        removedNodes: state.addedNodes.map((n) => n.id).includes(action.node.id)
          ? state.removedNodes
          : [...state.removedNodes, action.node],
        treeData: removeNodeAtPath({
          treeData: state.treeData,
          path: action.path,
          getNodeKey
        })
      };
    case actionTypes.addNodeSuccess:
      return {
        ...state,
        addedNodes: removeItemFromArray(state.addedNodes, {
          index: state.addedNodes.findIndex((n) => n.id === action.nodeId)
        }),
        originalTreeData: state.treeData
      };
    case actionTypes.removeNodesSuccess:
      return { ...state, removedNodes: [], originalTreeData: state.treeData };
    case actionTypes.updateNodeOrderSuccess:
      return { ...state, updatedNodes: [], originalTreeData: state.treeData };
    case actionTypes.nodeOrderUpdated: {
      let parentNode = action.node;

      if (!parentNode) {
        // Top level, create a synthetic node to contain all top level categories.
        parentNode = {
          id: 'top-level',
          children: state.treeData
        };
      }

      const foundUpdatedIndex = state.updatedNodes.findIndex((node) => node.id === parentNode.id);
      if (foundUpdatedIndex > -1) {
        updatedNodes = updateObjectInArray(state.updatedNodes, { index: foundUpdatedIndex, item: parentNode });
      } else {
        updatedNodes = [...updatedNodes, parentNode];
      }

      parentNode.children.forEach((child, index) => {
        if (state.addedNodes.map((n) => n.id).includes(child.id)) {
          let updateIndex = state.addedNodes.findIndex((node) => node.id === child.id);
          addedNodes = updateObjectInArray(state.addedNodes, { index: updateIndex, item: { ...child, order: index } });
        }

        child.order = index;
      });

      return { ...state, updatedNodes, addedNodes };
    }
    case actionTypes.toggleExpandAll:
      return {
        ...state,
        allNodesExpanded: !state.allNodesExpanded,
        treeData: toggleExpandedForAll({ treeData: state.treeData, expanded: !state.allNodesExpanded })
      };
    default:
      throw new Error(`Unrecognized action type: ${action.type}`);
  }
};

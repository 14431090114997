import { useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { CAREGIVER_ROLE } from '../../../constants';
import Card from '../../Card';
import DatePickerWithFallback from '../../DatePickerWithFallback';
import Select from '../../Select';
import { decamelize } from '../../../utils';

const caregiverRoles = [
  {
    label: 'Läkare',
    value: CAREGIVER_ROLE.DOCTOR
  },
  {
    label: 'Sjuksköterska',
    value: CAREGIVER_ROLE.NURSE
  }
];

const FinalNotesPerTypeList = ({ getFinalNotesPerType, finalNotesPerType, isLoading, error, locale }) => {
  const [caregiverRole, setCaregiverRole] = useState(caregiverRoles[0]);
  const [fromDate, setFromDate] = useState(moment().add(-1, 'month').toDate());
  const [toDate, setToDate] = useState(moment().toDate());

  useEffect(() => {
    getFinalNotesPerType(
      moment(fromDate).format('YYYY-MM-DD'),
      moment(toDate).format('YYYY-MM-DD'),
      caregiverRole.value
    );
  }, [fromDate, toDate, caregiverRole]);

  return (
    <Card
      titleI18nKey="reports_view.doctor_statistics.final_notes_per_type_header"
      isLoading={isLoading}
      error={error}
      errorI18nKey="reports_view.doctor_statistics.statistics_error"
      additionalClass="x-large"
    >
      <div className="doctor-statistics">
        <div className="space-between mb-20">
          <div className="date-pickers space-between">
            <div className="mr-20">
              <h4>
                <Translate value="global.from_date" />
              </h4>
              <DatePickerWithFallback
                locale={locale}
                selectedDate={fromDate}
                maxDate={null}
                minDate={null}
                selectsStart
                startDate={fromDate}
                endDate={toDate}
                placeholderI18nKey="global.choose_date"
                onChange={(date) => {
                  setFromDate(date);
                }}
                onChangeNative={(e) =>
                  e.target.value ? setFromDate(moment(e.target.value).toDate()) : setFromDate(undefined)
                }
              />
            </div>
            <div>
              <h4>
                <Translate value="global.to_date" />
              </h4>
              <DatePickerWithFallback
                locale={locale}
                selectedDate={toDate}
                maxDate={null}
                minDate={null}
                selectsEnd
                startDate={toDate}
                endDate={toDate}
                placeholderI18nKey="global.choose_date"
                onChange={(date) => {
                  setToDate(date);
                }}
                onChangeNative={(e) =>
                  e.target.value ? setToDate(moment(e.target.value).toDate()) : setToDate(undefined)
                }
              />
            </div>
          </div>
          <div className="picker">
            <h4>
              <Translate value="reports_view.doctor_statistics.caregiver_type" />
            </h4>
            <Select
              isSearchable={false}
              value={caregiverRole}
              onChange={(e) => {
                setCaregiverRole(e);
              }}
              classNamePrefix="single-select"
              options={caregiverRoles}
            />
          </div>
        </div>
        <div className={`table-container ${isLoading ? 'opacity-6' : ''}`}>
          <table className="table is-striped">
            <thead>
              <tr>
                <th>
                  <Translate value={`reports_view.doctor_statistics.${caregiverRole.value}_table_header`} />
                </th>
                {finalNotesPerType.checkupTypes.map((checkupType) => (
                  <th key={checkupType}>
                    <Translate value={`global.final_note_reason.${decamelize(checkupType)}`} />
                  </th>
                ))}
                <th>
                  <Translate value="reports_view.doctor_statistics.total" />
                </th>
              </tr>
            </thead>
            <tbody>
              {finalNotesPerType.statistics.map((row, i) => {
                return (
                  <tr key={i}>
                    <th>
                      {row.caregiver.givenName} {row.caregiver.familyName}
                    </th>
                    {row.finalNotes.map((numNotes, j) => (
                      <td key={j}>{numNotes}</td>
                    ))}
                    <td>
                      {row.finalNotes.reduce((acc, curr) => {
                        return acc + curr;
                      }, 0)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th>
                  <Translate value="reports_view.doctor_statistics.total" />
                </th>
                {Array.from({ length: finalNotesPerType.checkupTypes.length }, (_, i) => (
                  <th key={i}>
                    {finalNotesPerType.statistics.reduce((acc, curr) => {
                      return acc + curr.finalNotes[i];
                    }, 0)}
                  </th>
                ))}
                <th>
                  {finalNotesPerType.statistics.reduce((acc, curr) => {
                    return (
                      acc +
                      curr.finalNotes.reduce((a, c) => {
                        return a + c;
                      }, 0)
                    );
                  }, 0)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default FinalNotesPerTypeList;

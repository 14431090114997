import { useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';
import { CAREGIVER_ROLE } from '../../../constants';
import Card from '../../Card';
import Select from '../../Select';

const caregiverRoles = [
  {
    label: 'Läkare',
    value: CAREGIVER_ROLE.DOCTOR
  },
  {
    label: 'Sjuksköterska',
    value: CAREGIVER_ROLE.NURSE
  }
];

const YearlyFinalNoteStatistics = ({ getYearlyStatistics, caregiverStatistics, years, isLoading, error }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [caregiverRole, setCaregiverRole] = useState(caregiverRoles[0]);

  useEffect(() => {
    getYearlyStatistics(year, caregiverRole.value);
  }, [year, caregiverRole]);

  return (
    <Card
      titleI18nKey="reports_view.doctor_statistics.final_notes_header"
      isLoading={isLoading}
      error={error}
      errorI18nKey="reports_view.doctor_statistics.statistics_error"
      additionalClass="x-large"
    >
      <div className="doctor-statistics">
        <div className="space-between mb-20">
          <div className="picker">
            <h4>
              <Translate value="reports_view.doctor_statistics.choose_year" />
            </h4>
            <Select
              isSearchable={false}
              defaultValue={years[0]}
              onChange={(e) => {
                setYear(e.value);
              }}
              classNamePrefix="single-select"
              options={years}
            />
          </div>
          <div className="picker">
            <h4>
              <Translate value="reports_view.doctor_statistics.caregiver_type" />
            </h4>
            <Select
              isSearchable={false}
              value={caregiverRole}
              onChange={(e) => {
                setCaregiverRole(e);
              }}
              classNamePrefix="single-select"
              options={caregiverRoles}
            />
          </div>
        </div>
        <div className={`table-container ${isLoading ? 'opacity-6' : ''}`}>
          <table className="table is-striped">
            <thead>
              <tr>
                <th>
                  <Translate value={`reports_view.doctor_statistics.${caregiverRole.value}_table_header`} />
                </th>
                <th>Jan.</th>
                <th>Feb.</th>
                <th>Mar.</th>
                <th>Apr.</th>
                <th>Maj</th>
                <th>Jun.</th>
                <th>Jul.</th>
                <th>Aug.</th>
                <th>Sep.</th>
                <th>Okt.</th>
                <th>Nov.</th>
                <th>Dec.</th>
                <th>
                  <Translate value="reports_view.doctor_statistics.total" />
                </th>
              </tr>
            </thead>
            <tbody>
              {caregiverStatistics.map((row, i) => {
                return (
                  <tr key={i}>
                    <th>
                      {row.caregiver.givenName} {row.caregiver.familyName}
                    </th>
                    {row.finalNotes.map((numNotes, j) => (
                      <td key={j}>{numNotes}</td>
                    ))}
                    <td>
                      {row.finalNotes.reduce((acc, curr) => {
                        return acc + curr;
                      }, 0)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th>
                  <Translate value="reports_view.doctor_statistics.total" />
                </th>
                {Array.from({ length: 12 }, (_, i) => (
                  <th key={i}>
                    {caregiverStatistics.reduce((acc, curr) => {
                      return acc + curr.finalNotes[i];
                    }, 0)}
                  </th>
                ))}
                <th>
                  {caregiverStatistics.reduce((acc, curr) => {
                    return (
                      acc +
                      curr.finalNotes.reduce((a, c) => {
                        return a + c;
                      }, 0)
                    );
                  }, 0)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default YearlyFinalNoteStatistics;

import { CURRENT_ENV } from '../constants';
import { v4 as uuidv4 } from 'uuid';

const apiFactory = require('accumbo-js-client');
const metadata = require('../metadata.json');

// const mock = require('./mock.json');

const superagent = require('superagent');
const client = new apiFactory.ApiClient();
const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
client.basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');
client.defaultHeaders['x-accumbo-api-version'] = '1.3.26';

const userAgent = navigator.userAgent;

const regionSkaneId = '05d07812-7cc0-11e9-9348-342eb68fac74';

function logInTest(data) {
  const authApi = new apiFactory.AuthorizationApi(client);
  const loginRequest = new apiFactory.LoginRequest.constructFromObject(data);
  const opts = {};

  return authApi.loginUsingPOST(userAgent, loginRequest, opts);
}

function challenge(identifier) {
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const challengeObj = {
    identifier: identifier,
    method: 'bankid'
  };
  const challengeRequest = new apiFactory.ChallengeRequest.constructFromObject(challengeObj);
  const opts = {};

  return authorizationApi.challengeUsingPOST(userAgent, challengeRequest, opts);
}

function login(refToken, identifier) {
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const loginObj = {
    method: 'bankid',
    identifier
  };

  const opts = {
    authorization: `Token ${refToken}`
  };

  const loginRequest = new apiFactory.LoginRequest.constructFromObject(loginObj);

  return authorizationApi.loginUsingPOST(userAgent, loginRequest, opts);
}

function refreshToken(jwtToken) {
  console.log('refreshing token');
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return authorizationApi.loginUsingGET(userAgent, opts);
}

function getMembers(jwtToken, query, offset, limit, sort = 'familyName:asc,givenName:asc', extraFields) {
  const membersListApi = new apiFactory.ListMembersApi(client);

  const options = {
    authorization: `Bearer ${jwtToken}`,
    q: query,
    offset,
    limit,
    sort
  };

  if (extraFields) {
    options.extraFields = extraFields.join(',');
  }

  return membersListApi.getMembersUsingGET(userAgent, options);
}

function getMember(jwtToken, memberGuid) {
  const memberUserProfileApi = new apiFactory.MemberUserProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberUserProfileApi.getMemberByGUIDUsingGET(userAgent, memberGuid, opts);
}

function updateMember(jwtToken, memberGuid, updatedMemberFields) {
  const memberUserProfileApi = new apiFactory.MemberUserProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberUserProfileApi.updateMemberUsingPATCH(userAgent, memberGuid, updatedMemberFields, opts);
}

function updateMemberAnamnesisAnswer(jwtToken, memberGuid, questionId, newAnwer) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.postAnamnesisAnswerUsingPOST(userAgent, memberGuid, questionId, newAnwer, opts);
}

function getMemberDevices(jwtToken, guid) {
  const memberMobileDevicesApi = new apiFactory.MemberMobileDevicesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const deviceId = '00000000-0000-0000-0000-000000000000'; // Only ID supported before multi device support

  return memberMobileDevicesApi.getMobileDeviceUsingGET(userAgent, guid, deviceId, opts);
}

function getSystemUsers(jwtToken, query, offset, limit, sort = 'familyName:asc,givenName:asc', extraFields) {
  const userAdminApi = new apiFactory.UserAdminApi(client);

  const options = {
    authorization: `Bearer ${jwtToken}`,
    q: query,
    offset,
    limit,
    sort
  };

  if (extraFields) {
    options.extraFields = extraFields.join(',');
  }

  return userAdminApi.getSystemUsersUsingGET(userAgent, options);
}

function getSystemUser(jwtToken, guid) {
  const userAdminApi = new apiFactory.UserAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.getSystemUserByGUIDUsingGET(userAgent, guid, opts);
}

function updateSystemUser(jwtToken, guid, updatedFields) {
  const userAdminApi = new apiFactory.UserAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.updateSystemUserUsingPATCH(userAgent, guid, updatedFields, opts);
}

function getRegions(jwtToken) {
  const regionsApi = new apiFactory.RegionsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return regionsApi.getRegionsUsingGET(userAgent, opts);
}

function getInvoiceReport(jwtToken, date) {
  const invoiceDataApi = new apiFactory.InvoiceDataApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return invoiceDataApi.getInvoicereportUsingGET(userAgent, date, 'privera', opts);
}

function getReferralDocuments(jwtToken, offsetSettings, referralIds, isTest = false) {
  const paperReferralsApi = new apiFactory.PaperReferralsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const referralsRequest = {
    region: regionSkaneId,
    dryRun: isTest,
    referralIds,
    offset1: [offsetSettings.rutin.x * 10, offsetSettings.rutin.y * 10],
    offset2: [offsetSettings.special.x * 10, offsetSettings.special.y * 10]
  };

  return paperReferralsApi.generateReferralsZipUsingPOSTWithHttpInfo(userAgent, referralsRequest, opts);
}

function getAuthorities() {
  const authorities = { ...apiFactory.Authority };
  delete authorities.constructFromObject;
  return new Promise((resolve) => {
    resolve(Object.getOwnPropertyNames(authorities));
  });
}

function bulkSendChatMessage(jwtToken, caregivers, messageText) {
  const adminChatMessagesApi = new apiFactory.AdminChatMessagesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const message = {
    caregivers,
    text: messageText
  };

  return adminChatMessagesApi.postMultiChatMessageUsingPOST(userAgent, message, opts);
}

function postVacation(jwtToken, id, startDate, endDate, caregiverGuid, patientDistribution) {
  const vacationAdministrationApi = new apiFactory.VacationAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const vacation = {
    id,
    startDate,
    endDate,
    caregiver: caregiverGuid,
    patientDistribution
  };

  return vacationAdministrationApi.createVacationUsingPOST(userAgent, vacation, opts);
}

function deleteVacation(jwtToken, vacationId) {
  const vacationAdministrationApi = new apiFactory.VacationAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return vacationAdministrationApi.deleteVacationUsingDELETE(userAgent, vacationId, opts);
}

function updateVacation(jwtToken, updatedVacation) {
  const vacationAdministrationApi = new apiFactory.VacationAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return vacationAdministrationApi.updateVacationUsingPUT(userAgent, updatedVacation.id, updatedVacation, opts);
}

function getVacations(jwtToken, caregiverGuid) {
  const vacationAdministrationApi = new apiFactory.VacationAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return vacationAdministrationApi.getVacationsUsingGET(userAgent, caregiverGuid, opts);
}

function postBulkReferrals(jwtToken, personalNumbers, messageText) {
  const adminChatMessagesApi = new apiFactory.AdminChatMessagesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const message = {
    personalNumbers,
    text: messageText
  };

  return adminChatMessagesApi.postMultiReferralMessageUsingPOST(userAgent, message, opts);
}

function getMemberComment(jwtToken, memberGuid) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.getAdminMemberCommentUsingGET(userAgent, memberGuid, opts);
}

function updateMemberComment(jwtToken, memberGuid, memberComment) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.updateAdminMemberCommentUsingPUT(userAgent, memberGuid, memberComment, opts);
}

function getFile(jwtToken, memberGuid, fileId, maxWidth, maxHeight) {
  const filesApi = new apiFactory.FilesApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    maxWidth,
    maxHeight
  };

  return filesApi.getFileUsingGETWithHttpInfo(userAgent, 'secured', memberGuid, fileId, opts);
}

function getShipmentItems(jwtToken) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.getShipmentsUsingGET(userAgent, opts);
}

function scheduleShipments(jwtToken, shipmentIds) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.transferShipmentsUsingPOST(userAgent, shipmentIds, opts);
}

function addManualShipment(jwtToken, memberGuid, shipmentType) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const shipment = {
    guid: memberGuid,
    shipmentType
  };

  return shippingAdministrationApi.addShipmentUsingPOST(userAgent, shipment, opts);
}

function deleteShipment(jwtToken, shipmentId) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.deleteShipmentUsingDELETE(userAgent, shipmentId, opts);
}

function delayShipment(jwtToken, shipment, delayDate) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.updateShipmentUsingPATCH(userAgent, shipment.id, { ...shipment, delayDate }, opts);
}

function getMemberJiraIssues(jwtToken, memberGuid) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    guid: memberGuid
  };

  return jiraApi.getJiraIssuesUsingGET(userAgent, opts);
}

function postMemberJiraIssue(jwtToken, jiraIssue) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return jiraApi.createJiraIssueUsingPOST(userAgent, jiraIssue, opts);
}

function getJiraIssueTypes(jwtToken, project) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return jiraApi.getJiraIssueTypesUsingGET(userAgent, project, opts);
}

function getReferrals(jwtToken, regionId) {
  const paperReferralsApi = new apiFactory.PaperReferralsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paperReferralsApi.getReferralsToPrintUsingGET(userAgent, regionId, opts);
}

function getScheduledCommunication(jwtToken, memberGuid) {
  const scheduledCommunicationApi = new apiFactory.MemberAdminScheduledCommunicationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return scheduledCommunicationApi.getMemberScheduledCommunicationsUsingGET(userAgent, memberGuid, opts);
}

function updateScheduledCommunication(jwtToken, memberGuid, communicationId, updatedCommunication) {
  const scheduledCommunicationApi = new apiFactory.MemberAdminScheduledCommunicationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return scheduledCommunicationApi.updateMemberScheduledCommunicationUsingPATCH(
    userAgent,
    memberGuid,
    communicationId,
    updatedCommunication,
    opts
  );
}

function deleteScheduledCommunication(jwtToken, memberGuid, communicationId) {
  const scheduledCommunicationApi = new apiFactory.MemberAdminScheduledCommunicationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return scheduledCommunicationApi.deleteScheduledCommunicationUsingDELETE(
    userAgent,
    memberGuid,
    communicationId,
    opts
  );
}

function getCannedResponses(jwtToken) {
  return superagent
    .get(`${metadata.environments[env].apiUrl.replace(/\/+$/, '')}/caregiver/chatresponse`)
    .set('Authorization', `Bearer ${jwtToken}`);
}

function updateCannedResponse(jwtToken, newCannedResponse) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return cannedChatResponsesApi.updateChatResponseUsingPUT(userAgent, newCannedResponse.id, newCannedResponse, opts);
}

function postCannedResponse(jwtToken, cannedResponse) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  delete cannedResponse.isNew;
  delete cannedResponse.path;

  return cannedChatResponsesApi.createChatResponseUsingPOST(userAgent, cannedResponse, opts);
}

function deleteCannedResponse(jwtToken, cannedResponseId) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return cannedChatResponsesApi.deleteChatResponseUsingDELETE(userAgent, cannedResponseId, opts);
}

function getTemplateTags(jwtToken) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return cannedChatResponsesApi.getChatResponseMetatagsUsingGET(userAgent, opts);
}

function sendEmail(jwtToken, memberGuid, subject, body) {
  const memberEmailApi = new apiFactory.MemberEmailApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const email = {
    subject,
    body
  };

  return memberEmailApi.sendEmailToMemberUsingPOST(userAgent, memberGuid, email, opts);
}

function getCaregiverStatistics(jwtToken, year, caregiverRole) {
  const caregiverStatisticsApi = new apiFactory.CaregiverStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    caregiverRole
  };

  return caregiverStatisticsApi.getFinalNoteStatisticsUsingGET(userAgent, year, opts);
}

function getDoctorPatientTotals(jwtToken) {
  const caregiverStatisticsApi = new apiFactory.CaregiverStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return caregiverStatisticsApi.getPatientTotalsUsingGET(userAgent, opts);
}

function getDoctorFinalNoteBreakdown(jwtToken, guid, baseDate) {
  const caregiverStatisticsApi = new apiFactory.CaregiverStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    baseDate
  };

  return caregiverStatisticsApi.getFinalNoteBreakdownUsingGET(userAgent, guid, opts);
}

function getMemberChatMessages(jwtToken, memberGuid) {
  const memberChatApi = new apiFactory.MemberChatApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberChatApi.getChatMessagesUsingGET(userAgent, memberGuid, opts);
}

function getMemberJournal(jwtToken, memberGuid, periodDays) {
  const journalApi = new apiFactory.JournalApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return journalApi.getJournalToPrintUsingGETWithHttpInfo(userAgent, memberGuid, periodDays, opts);
}

function postAssignmentPriority(jwtToken, guid, assignmentPriority) {
  const userAdminApi = new apiFactory.UserAdminApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.postSystemUserAssignmentPriorityUsingPOST(userAgent, guid, assignmentPriority, opts);
}

function deleteAssignmentPriority(jwtToken, guid) {
  const userAdminApi = new apiFactory.UserAdminApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.deleteSystemUserAssignmentPriorityUsingDELETE(userAgent, guid, opts);
}

function getMemberSparData(jwtToken, memberGuid) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.getAdminMemberSparUpdateUsingGET(userAgent, memberGuid, opts);
}

function postMemberPaymentExemption(jwtToken, memberGuid, paymentExemption) {
  const paymentExemptionApi = new apiFactory.FrikortApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    paymentExemption
  };

  return paymentExemptionApi.postMemberPaymentExemptionUsingPOST(userAgent, memberGuid, opts);
}

function updateMemberPaymentExemption(jwtToken, memberGuid, paymentExemption) {
  const paymentExemptionApi = new apiFactory.FrikortApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    paymentExemption
  };

  return paymentExemptionApi.patchMemberPaymentExemptionUsingPATCH(userAgent, memberGuid, opts);
}

function deleteMemberPaymentExemption(jwtToken, memberGuid) {
  const paymentExemptionApi = new apiFactory.FrikortApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paymentExemptionApi.deleteMemberPaymentExemptionUsingDELETE(userAgent, memberGuid, opts);
}

function getRemunerationScheme() {
  return new Promise((resolve) => {
    resolve({
      newMemberCheckup: 165,
      threeMonthCheckup: 165,
      yearlyCheckup: 165,
      extraCheckup: 140,
      sixWeekCheckup: 140,
      initialCheckup: 95
    });
  });
}

function getMemberPaymentHistory(jwtToken, memberGuid) {
  const paymentHistoryApi = new apiFactory.PaymentHistoryApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paymentHistoryApi.getPaymentHistoryUsingGET(userAgent, memberGuid, opts);
}

function getInvoice(jwtToken, paymentId) {
  const paymentApi = new apiFactory.PaymentApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paymentApi.getInvoicePdfUsingGETWithHttpInfo(userAgent, paymentId, opts);
}

function assignCaregiver(jwtToken, memberGuid, caregiverGuid) {
  const caregiverApi = new apiFactory.CaregiverApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return caregiverApi.setCaregiverForMemberUsingPOST(userAgent, memberGuid, { uuid: caregiverGuid }, opts);
}

function uploadFile(jwtToken, memberGuid, filename, file, contentType) {
  const memberFilesApi = new apiFactory.MemberFilesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };
  const fileId = uuidv4();

  return memberFilesApi.postMemberFilesUsingPOST(userAgent, memberGuid, fileId, filename, file, contentType, opts);
}

function tagPersonalNumbersWithSource(jwtToken, invites, source) {
  const tagReferredPatientApi = new apiFactory.TagReferredPatientApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const externalPatientReferral = {
    invites,
    source
  };

  return tagReferredPatientApi.tagPersonalNumberWithSourceUsingPOST(userAgent, externalPatientReferral, opts);
}

function getFinalNotesPerType(jwtToken, fromDate, toDate, caregiverRole) {
  const caregiverStatisticsApi = new apiFactory.CaregiverStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    caregiverRole
  };

  return caregiverStatisticsApi.getFinalNotePerTypeUsingGET(userAgent, fromDate, toDate, opts);
}

const apiMethods = {
  logInTest,
  challenge,
  login,
  refreshToken,
  getMembers,
  getMember,
  updateMember,
  updateMemberAnamnesisAnswer,
  getMemberDevices,
  getSystemUsers,
  getSystemUser,
  updateSystemUser,
  getRegions,
  getInvoiceReport,
  getReferralDocuments,
  getAuthorities,
  bulkSendChatMessage,
  postVacation,
  deleteVacation,
  updateVacation,
  getVacations,
  postBulkReferrals,
  getMemberComment,
  updateMemberComment,
  getFile,
  getMemberJiraIssues,
  postMemberJiraIssue,
  getJiraIssueTypes,
  getShipmentItems,
  scheduleShipments,
  addManualShipment,
  deleteShipment,
  delayShipment,
  getReferrals,
  getScheduledCommunication,
  updateScheduledCommunication,
  deleteScheduledCommunication,
  getCannedResponses,
  updateCannedResponse,
  postCannedResponse,
  deleteCannedResponse,
  getTemplateTags,
  sendEmail,
  getCaregiverStatistics,
  getDoctorPatientTotals,
  getMemberChatMessages,
  getMemberJournal,
  postAssignmentPriority,
  deleteAssignmentPriority,
  getMemberSparData,
  getDoctorFinalNoteBreakdown,
  updateMemberPaymentExemption,
  deleteMemberPaymentExemption,
  postMemberPaymentExemption,
  getRemunerationScheme,
  getMemberPaymentHistory,
  getInvoice,
  assignCaregiver,
  uploadFile,
  tagPersonalNumbersWithSource,
  getFinalNotesPerType
};

export default apiMethods;

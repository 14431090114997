import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { I18n, Translate } from 'react-redux-i18n';
import Tooltip from '@material-ui/core/Tooltip';
import { decamelize } from '../../../utils';
import { DATE_FORMAT } from '../../../constants';
import ContentLoader from '../../ContentLoader';
import api from '../../../api/apiClient';
import { showNotification } from '../../../actions';
import DropdownMenu from '../../DropdownMenu';
import './PatientFeeHistory.scss';

const PatientCheckupName = ({ checkupType }) => {
  return (
    <div className="column is-4">
      <Translate
        value={`global.final_note_reason.${typeof checkupType !== 'string' ? 'missing' : decamelize(checkupType)}`}
      />
    </div>
  );
};

const getPatientPaymentStatus = (paymentState, paymentMethod) => {
  if (paymentState === 'COMPLETE') {
    if (paymentMethod) {
      return (
        <Translate
          value={`global.payment_state.${paymentState.toLocaleLowerCase()}_${paymentMethod.toLocaleLowerCase()}`}
        />
      );
    }

    return <Translate value={`global.payment_state.${paymentState.toLocaleLowerCase()}_unknown`} />;
  }

  return <Translate value={`global.payment_state.${paymentState.toLocaleLowerCase()}`} />;
};

const PatientPaymentStatus = ({ paymentState, paymentMethod, paymentProvider }) => {
  const paymentStatus = (
    <span className={`payment-state payment-state__${paymentState.toLocaleLowerCase()}`}>
      {getPatientPaymentStatus(paymentState, paymentMethod)}
    </span>
  );

  return (
    <div className="column is-4 no-wrap">
      {paymentProvider ? (
        <Tooltip
          title={
            <div className="fs-12">
              <Translate value={`global.payment_provider.${paymentProvider}`} />
            </div>
          }
          arrow
          placement="top"
        >
          {paymentStatus}
        </Tooltip>
      ) : (
        paymentStatus
      )}
    </div>
  );
};

const PatientPaymentDate = ({ stateTimestamp }) => (
  <div className="column is-2 no-wrap">{moment(stateTimestamp, DATE_FORMAT).format('YYYY-MM-DD')}</div>
);

const PatientPaymentActions = ({ payment, onDownloadReceipt, onDownloadInvoice, userAuthorities, authority }) => {
  const actions = [];

  if (payment.receiptInfo) {
    actions.push({
      i18nKey: 'member_details.payment_history.download_receipt',
      authority: authority.viewPatients,
      onClick: () => onDownloadReceipt(payment.receiptInfo)
    });
  }

  if (payment.paymentMethod === 'invoice') {
    actions.push({
      i18nKey: 'member_details.payment_history.download_invoice',
      authority: authority.managePatients,
      onClick: () => onDownloadInvoice(payment.paymentId)
    });
  }

  if (!actions.length) {
    return null;
  }

  return (
    <div className="column is-2 no-padding text-center">
      <DropdownMenu actions={actions} userAuthorities={userAuthorities} />
    </div>
  );
};

const PatientFeeHistory = ({
  history,
  isLoading,
  error,
  authToken,
  member,
  showNotification,
  userAuthorities,
  authority
}) => {
  const [expanded, setExpanded] = useState(false);
  const HIDDEN_LIMIT = 3;
  const visibleHistory = expanded ? history : history.slice(0, HIDDEN_LIMIT + 1);

  const downloadReceipt = (receiptInfo) => {
    api
      .getFile(authToken, member.guid, receiptInfo.id)
      .then((data) => {
        const blob = new Blob([data.response.body], {
          type: 'application/pdf'
        });
        saveAs(blob, `Blodtrycksdoktorn_kvitto_${member.givenName}_${member.familyName}.pdf`);
      })
      .catch((error) => {
        console.log(error);
        showNotification(I18n.t('notification.download_receipt.error'), 'error');
      });
  };

  const downloadInvoice = (paymentId) => {
    api
      .getInvoice(authToken, paymentId)
      .then((data) => {
        const blob = new Blob([data.response.body], {
          type: 'application/pdf'
        });
        saveAs(blob, `Blodtrycksdoktorn_faktura_${member.givenName}_${member.familyName}.pdf`);
      })
      .catch((error) => {
        console.log(error);
        showNotification(I18n.t('notification.download_invoice.error'), 'error');
      });
  };

  return (
    <div className="payment-history">
      <h2 className="mt-30">
        <Translate value="member_details.payment_history.header" />
      </h2>
      <ContentLoader isLoading={isLoading} error={error} errorTitleI18n="member_details.payment_history.error">
        {!history.length ? (
          <Translate value="member_details.payment_history.no_history" />
        ) : (
          <div className="mb-30">
            <div className="columns is-mobile">
              <div className="column is-4 pb-0">
                <h4>
                  <Translate value="member_details.payment_history.table_headers.visit_type" />
                </h4>
              </div>
              <div className="column is-4 pb-0">
                <h4>
                  <Translate value="member_details.payment_history.table_headers.status" />
                </h4>
              </div>
              <div className="column pb-0">
                <h4>
                  <Translate value="member_details.payment_history.table_headers.date" />
                </h4>
              </div>
            </div>
            {visibleHistory.map((visit, i) => (
              <div
                className={`columns is-mobile mb-10 ${
                  i === HIDDEN_LIMIT && !expanded ? 'partially-hidden' : i > HIDDEN_LIMIT && !expanded ? 'hidden' : ''
                }`}
                key={visit.payment.paymentId}
              >
                <PatientCheckupName checkupType={visit.checkupType} />
                <PatientPaymentStatus
                  paymentState={visit.payment.state}
                  paymentMethod={visit.payment.paymentMethod}
                  paymentProvider={visit.payment.paymentProvider}
                />
                <PatientPaymentDate stateTimestamp={visit.payment.stateTimestamp} />
                <PatientPaymentActions
                  payment={visit.payment}
                  onDownloadReceipt={downloadReceipt}
                  onDownloadInvoice={downloadInvoice}
                  userAuthorities={userAuthorities}
                  authority={authority}
                />
              </div>
            ))}
            {history.length > HIDDEN_LIMIT ? (
              <div className="text-center">
                <span className="text-button" onClick={() => setExpanded(!expanded)}>
                  <Translate value={`global.show_${expanded ? 'fewer' : 'all'}`} />
                </span>
              </div>
            ) : null}
          </div>
        )}
      </ContentLoader>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    userAuthorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(PatientFeeHistory);

import React, { Component, Fragment } from 'react';
import { Prompt } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  getMember,
  updateMemberSuccess,
  getMemberDevices,
  toggleEditMemberDetails,
  getAssignableDoctors,
  setMemberServiceStatus,
  showNotification,
  updateMemberAnamnesisAnswer,
  getMemberComment,
  updateMemberComment,
  updateMemberCommentText,
  getMemberProfileImage,
  showJiraModal,
  hideJiraModal,
  getMemberJiraIssues,
  getJiraIssueTypes,
  postMemberJiraIssue,
  setJiraIssueType,
  setJiraIssueDescription,
  hideJiraHistoryModal,
  showJiraHistoryModal,
  addManualShipment,
  getScheduledCommunication,
  updateScheduledCommunication,
  deleteScheduledCommunication,
  showMessageModal,
  hideMessageModal,
  sendMemberMessage,
  updateMessageSubject,
  updateMessageBody,
  toggleExportJournalModal,
  getMemberJournalRequest,
  getMemberJournalSuccess,
  getMemberJournalError,
  getMemberChatMessages,
  toggleSparModal,
  getMemberSparData,
  updateMemberPaymentExemption,
  updatePaymentExemption,
  deleteMemberPaymentExemption,
  postMemberPaymentExemption,
  getMemberPaymentHistory,
  assignCaregiver
} from '../../actions';
import { decamelize, getEnvironmentPrefix } from '../../utils';
import EditableSelect from '../EditableSelect';
import ContentLoader from '../ContentLoader';
import CanPerform from '../CanPerform';
import {
  DATE_FORMAT,
  ARM_CIRCUMFERENCE_ID,
  COMMENT_COLORS,
  COMMUNICATION_POSTPONE_DAYS,
  MEMBER_SERVICE_STATUS,
  CAREGIVER_ROLE,
  CURRENT_ENV,
  MONITOR_SIZES
} from '../../constants';
import ToggleSwitch from '../ToggleSwitch';
import api from '../../api/apiClient';
import LoaderButton from '../LoaderButton';
import ProfileCard from '../ProfileCard';
import LoadingIcon from '../LoadingIcon';
import Modal from '../Modal';
import JiraSupportModalContent from '../JiraSupportModalContent';
import Button from '../Button';
import ScheduledCommunication from './ScheduledCommunication';
import MemberMessageEditor from './MemberMessageEditor';
import DatePickerWithFallback from '../DatePickerWithFallback';
import PatientFeeHistory from './PatientFeeHistory';
import ErrorFallback from '../ErrorFallback';
import './MemberDetailsView.scss';

class MemberDetailsView extends Component {
  constructor(props) {
    super(props);

    this.toggleBlockMember = this.toggleBlockMember.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.updateAnamnesisAnswer = this.updateAnamnesisAnswer.bind(this);
    this.saveNewPropertyValue = this.saveNewPropertyValue.bind(this);
    this.changeCommentColor = this.changeCommentColor.bind(this);
    this.saveNewComment = this.saveNewComment.bind(this);
    this.createJiraIssue = this.createJiraIssue.bind(this);
    this.createManualShipment = this.createManualShipment.bind(this);
    this.createManualReturn = this.createManualReturn.bind(this);
    this.updateCommunication = this.updateCommunication.bind(this);
    this.deleteCommunication = this.deleteCommunication.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.closeMessageModal = this.closeMessageModal.bind(this);
    this.hideMember = this.hideMember.bind(this);
    this.downloadMemberJournal = this.downloadMemberJournal.bind(this);
    this.showSparData = this.showSparData.bind(this);
    this.convertSparToReadable = this.convertSparToReadable.bind(this);
    this.savePaymentExemption = this.savePaymentExemption.bind(this);
    this.updatePaymentExemption = this.updatePaymentExemption.bind(this);
    this.deletePaymentExemption = this.deletePaymentExemption.bind(this);
    this.updateCaregiver = this.updateCaregiver.bind(this);
    this.saveNewCaregiver = this.saveNewCaregiver.bind(this);

    this.state = {
      memberUpdates: {},
      caregivers: {},
      hasChanges: false,
      selectedIssueType: null,
      commentColor: null,
      selectedJournalInterval: null
    };
  }

  componentDidMount() {
    this.props.getMember(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberDevices(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberComment(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberJiraIssues(this.props.authToken, this.props.match.params.memberId);
    this.props.getScheduledCommunication(this.props.authToken, this.props.match.params.memberId);
    this.props.getJiraIssueTypes(this.props.authToken);
    this.props.getMemberChatMessages(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberPaymentHistory(this.props.authToken, this.props.match.params.memberId);
  }

  componentDidUpdate(prevProps) {
    const { currentMember } = this.props.members;
    const { authorities, authority } = this.props;

    if (currentMember && currentMember.guid !== prevProps.members.currentMember.guid) {
      if (currentMember.region && currentMember.responsibleDoctor && authorities.includes(authority.managePatients)) {
        this.props.getAssignableDoctors(this.props.authToken, currentMember.region.id, currentMember.source);
      }

      if (currentMember.profileImage && currentMember.profileImage.id) {
        this.props.getMemberProfileImage(
          this.props.authToken,
          this.props.match.params.memberId,
          currentMember.profileImage.id
        );
      }
    }
  }

  updateProperty(event, property) {
    let updatedValue = event.value;

    if (property === 'region') {
      const selectedRegion = this.props.sharedData.regions.filter((region) => region.id === event.value)[0];

      if (selectedRegion) {
        updatedValue = {
          id: selectedRegion.id,
          name: selectedRegion.name
        };
      } else {
        updatedValue = undefined;
      }
    }

    this.setState({
      memberUpdates: {
        ...this.state.memberUpdates,
        [property]: updatedValue
      }
    });
  }

  saveNewPropertyValue(propertyName) {
    return (e) => {
      e.preventDefault();
      const { updateMemberSuccess, showNotification } = this.props;

      this.setState({
        [`saving${propertyName}`]: true
      });

      api
        .updateMember(this.props.authToken, this.props.match.params.memberId, {
          [propertyName]: this.state.memberUpdates[propertyName]
        })
        .then((response) => {
          updateMemberSuccess(response);

          let newValue;

          if (propertyName === 'region') {
            newValue = response.region.name;
          }

          showNotification(
            I18n.t(`member_details.updates.${decamelize(propertyName, '_')}.success`, { newValue }),
            'success'
          );

          this.setState({
            [`saving${propertyName}`]: false,
            memberUpdates: {
              ...this.state.memberUpdates,
              [propertyName]: undefined
            }
          });
        })
        .catch((error) => {
          console.log(error);

          this.setState({
            [`saving${propertyName}`]: false
          });

          if (Object.hasOwnProperty.call(this.state.memberUpdates, propertyName)) {
            showNotification(I18n.t(`member_details.updates.${decamelize(propertyName, '_')}.error`), 'error');
          }
        });
    };
  }

  updateCaregiver(event, caregiverRole) {
    const selectedCaregiver = (
      caregiverRole === CAREGIVER_ROLE.DOCTOR ? this.props.members.assignableDoctors : this.props.sharedData.nurses
    ).filter((caregiver) => caregiver.guid === event.value)[0];

    const updatedValue = {
      guid: selectedCaregiver.guid,
      givenName: selectedCaregiver.givenName,
      familyName: selectedCaregiver.familyName,
      role: caregiverRole
    };

    this.setState({
      caregivers: {
        ...this.state.caregivers,
        [caregiverRole]: updatedValue
      }
    });
  }

  saveNewCaregiver(caregiverRole) {
    return () => {
      this.props.assignCaregiver(
        this.props.authToken,
        this.props.members.currentMember.guid,
        this.state.caregivers[caregiverRole]
      );
    };
  }

  toggleBlockMember() {
    const { currentMember } = this.props.members;
    const memberName = `${currentMember.givenName} ${currentMember.familyName}`;
    if (currentMember && currentMember.serviceStatus === MEMBER_SERVICE_STATUS.BLOCKED) {
      this.props.setMemberServiceStatus(
        this.props.authToken,
        this.props.match.params.memberId,
        MEMBER_SERVICE_STATUS.HIDDEN,
        memberName
      );
    } else {
      this.props.setMemberServiceStatus(
        this.props.authToken,
        this.props.match.params.memberId,
        MEMBER_SERVICE_STATUS.BLOCKED,
        memberName
      );
    }
  }

  hideMember() {
    const { currentMember } = this.props.members;
    this.props.setMemberServiceStatus(
      this.props.authToken,
      this.props.match.params.memberId,
      MEMBER_SERVICE_STATUS.HIDDEN,
      `${currentMember.givenName} ${currentMember.familyName}`
    );
  }

  updateAnamnesisAnswer(questionId, memberProperty) {
    const { authToken, match, updateMemberAnamnesisAnswer } = this.props;
    updateMemberAnamnesisAnswer(
      authToken,
      match.params.memberId,
      questionId,
      [MONITOR_SIZES[this.state.memberUpdates[memberProperty]]],
      memberProperty
    );
  }

  changeCommentColor(e) {
    const color = e.target.getAttribute('data-color');
    this.setState({
      commentColor: color
    });

    this.props.updateMemberComment(this.props.authToken, this.props.match.params.memberId, {
      text: this.props.members.memberCommentText,
      color: color ? color.slice(1) : null
    });
  }

  saveNewComment() {
    this.props.updateMemberComment(
      this.props.authToken,
      this.props.match.params.memberId,
      { ...this.props.members.memberComment, text: this.props.members.memberCommentText },
      true
    );
  }

  createJiraIssue() {
    const { jira } = this.props;
    const jiraIssue = {
      project: process.env.REACT_APP_JIRA_PROJECT_KEY || 'ST',
      type: jira.selectedSupportType.name,
      summary: jira.selectedSupportType.name,
      description: jira.issueDescription,
      accumboAdminLink: `https://admin.accumbo.se/app/member/${this.props.match.params.memberId}`,
      accumboMemberGuid: this.props.match.params.memberId
    };
    this.props.postMemberJiraIssue(this.props.authToken, jiraIssue);
  }

  createManualShipment() {
    this.props.addManualShipment(this.props.authToken, this.props.match.params.memberId, 'outgoing');
  }

  createManualReturn() {
    this.props.addManualShipment(this.props.authToken, this.props.match.params.memberId, 'return');
  }

  updateCommunication(communication) {
    const updatedCommunication = {
      ...communication,
      scheduledDate: moment(communication.scheduledDate, DATE_FORMAT)
        .add(COMMUNICATION_POSTPONE_DAYS, 'd')
        .format(DATE_FORMAT)
    };

    this.props.updateScheduledCommunication(
      this.props.authToken,
      this.props.match.params.memberId,
      communication.id,
      updatedCommunication,
      communication.scheduledDate
    );
  }

  deleteCommunication(communication) {
    if (window.confirm(I18n.t('member_details.scheduled_communication.confirm_delete'))) {
      this.props.deleteScheduledCommunication(this.props.authToken, this.props.match.params.memberId, communication.id);
    }
  }

  sendMessage() {
    const { authToken, match, members } = this.props;
    this.props.sendMemberMessage(authToken, match.params.memberId, members.messageSubject, members.messageBody);
  }

  closeMessageModal() {
    const { messageSubject, messageBody } = this.props.members;

    if (messageSubject.length || messageBody.length) {
      if (window.confirm(I18n.t('member_details.message_discard_alert'))) {
        this.props.hideMessageModal();
      }
    } else {
      this.props.hideMessageModal();
    }
  }

  downloadMemberJournal() {
    this.props.getMemberJournalRequest();

    api
      .getMemberJournal(this.props.authToken, this.props.match.params.memberId, this.state.selectedJournalInterval)
      .then((response) => {
        this.props.getMemberJournalSuccess(response);
        const blob = new Blob([response.response.body], {
          type: 'application/pdf;charset=utf-8'
        });
        saveAs(blob, `Accumbo-journal_${this.props.members.currentMember.personalNumber}.pdf`);
        this.setState({ selectedJournalInterval: null });
      })
      .catch((error) => {
        console.log(error);
        this.props.getMemberJournalError(error);
        this.props.showNotification(I18n.t('notification.export_journal.error'), 'error');
      });
  }

  showSparData() {
    this.props.toggleSparModal();
    this.props.getMemberSparData(this.props.authToken, this.props.match.params.memberId);
  }

  convertSparToReadable(sparObj) {
    if (sparObj === undefined) {
      return;
    }

    const dateRegex = /\d{4}-\d{2}-\d{2}/;

    return Object.getOwnPropertyNames(sparObj).map((name, i) => {
      if (typeof sparObj[name] === 'boolean') {
        return (
          <div key={i}>
            <strong>{name}:</strong> <Translate value={`global.${sparObj[name] ? 'yes' : 'no'}`} />
          </div>
        );
      }

      if (Array.isArray(sparObj[name])) {
        return (
          <div key={i} className={name === 'utlandsadress' && sparObj[name].length ? 'spar-highlight' : ''}>
            <strong>{name}:</strong>
            <ol>
              {sparObj[name].length
                ? sparObj[name].map((item, i) => (
                    <li key={`item-${i}`} className="mb-10">
                      {this.convertSparToReadable(item)}
                    </li>
                  ))
                : '-'}
            </ol>
          </div>
        );
      }

      if (typeof sparObj[name] === 'object' && sparObj[name] !== null) {
        return (
          <div key={i}>
            <strong>{name}</strong>
            {this.convertSparToReadable(sparObj[name])}
          </div>
        );
      }

      if (name === 'avregistreringsorsakKod' && sparObj[name]) {
        return (
          <Fragment key={i}>
            <strong>{name}:</strong>{' '}
            <span className="spar-highlight">
              {sparObj[name]} (<Translate value={`member_details.spar.deregistration_code.${sparObj[name]}`} />)
            </span>
            <br />
          </Fragment>
        );
      }

      return (
        <Fragment key={i}>
          <strong>{name}:</strong>{' '}
          <span>{dateRegex.exec(sparObj[name]) ? dateRegex.exec(sparObj[name])[0] : sparObj[name] || '-'}</span>
          <br />
        </Fragment>
      );
    });
  }

  savePaymentExemption() {
    const { authToken, match, updateMemberPaymentExemption, postMemberPaymentExemption } = this.props;

    if (!this.props.members.currentMember.paymentExemption) {
      postMemberPaymentExemption(authToken, match.params.memberId, this.props.members.paymentExemption);
    } else {
      updateMemberPaymentExemption(authToken, match.params.memberId, this.props.members.paymentExemption);
    }
  }

  updatePaymentExemption(property, isNative) {
    return (e) => {
      let value;

      switch (property) {
        case 'cardNumber':
          value = e.target.value;
          break;
        case 'validUntil': {
          let date;
          if (!isNative) {
            date = e;
          } else if (e.target.value) {
            date = moment(e.target.value).toDate();
          }

          value = date;
          break;
        }
        default:
          throw new TypeError(`Unrecognized property: ${property}`);
      }

      this.props.updatePaymentExemption(property, value);
    };
  }

  deletePaymentExemption() {
    if (window.confirm(I18n.t('member_details.payment_exemption_confirm_removal'))) {
      this.props.deleteMemberPaymentExemption(this.props.authToken, this.props.match.params.memberId);
    }
  }

  render() {
    const {
      currentMember,
      fetchingMember,
      memberError,
      memberDevice,
      assignableDoctors,
      assignableDoctorsError,
      loadingAssignableDoctors,
      updatingAnamnesisAnswer,
      memberComment,
      memberCommentText,
      loadingScheduledCommunication,
      scheduledCommunication,
      scheduledCommunicationError,
      messageSubject,
      messageBody,
      fetchingChatMessages,
      latestMessageRead,
      hasChatMessages,
      paymentExemption,
      fetchingPaymentHistory,
      paymentHistoryError,
      paymentHistory
    } = this.props.members;

    const { fetchingRegions, regionsError } = this.props.sharedData;

    const { jira, authority } = this.props;

    const regions = this.props.sharedData.regions.filter((region) => region.id.indexOf('null') === -1);
    const currentCommentColor =
      this.state.commentColor || memberComment.color ? this.state.commentColor || `#${memberComment.color}` : null;

    const nurses = [...this.props.sharedData.nurses];
    const responsibleNurseIndex = nurses.findIndex((nurse) => nurse.guid === currentMember.responsibleNurse?.guid);

    // Show the current PAS at the top of the list
    if (responsibleNurseIndex > -1) {
      nurses.splice(responsibleNurseIndex, 1);
      nurses.unshift(currentMember.responsibleNurse);
    }

    return (
      <Fragment>
        <Prompt
          message={(location) => {
            if ((messageSubject.length || messageBody.length) && !location.pathname.endsWith(currentMember.guid)) {
              return I18n.t('member_details.unsaved_changes_prompt');
            }

            return true;
          }}
        />
        <div className="details-container">
          {!memberError ? (
            <Fragment>
              <div className="columns is-marginless">
                <div className="column is-4">
                  <ProfileCard
                    member={currentMember}
                    memberActions={[
                      {
                        i18nKey: 'member_details.actions.create_shipment',
                        authority: authority.managePatients,
                        onClick: this.createManualShipment
                      },
                      {
                        i18nKey: 'member_details.actions.create_return',
                        authority: authority.managePatients,
                        onClick: this.createManualReturn
                      },
                      {
                        i18nKey: 'member_details.actions.export_journal',
                        authority: authority.exportJournal,
                        onClick: this.props.toggleExportJournalModal
                      },
                      {
                        i18nKey: 'member_details.actions.show_spar_info',
                        authority: authority.managePatients,
                        onClick: this.showSparData
                      },
                      {
                        i18nKey: 'member_details.actions.open_in_clinic',
                        authority: authority.loginClinic,
                        type: 'link',
                        url: `https://${
                          process.env.REACT_APP_API_ENV !== 'production'
                            ? getEnvironmentPrefix(process.env.REACT_APP_API_ENV || CURRENT_ENV) + '.'
                            : ''
                        }clinic.accumbo.se/app/member/${currentMember.guid}`
                      }
                    ]}
                    actionsAuthority={[authority.managePatients, authority.exportJournal]}
                    userAuthorities={this.props.authorities}
                    error={memberError}
                    isLoading={fetchingMember}
                    onSendMessage={this.props.showMessageModal}
                    userType="member"
                  />
                  <CanPerform action={authority.managePatients}>
                    <div className="card-container">
                      <div className="columns is-mobile">
                        <div className="column">
                          <span>
                            <Translate value="member_details.blocked" />
                          </span>
                        </div>
                        <div className="column text-right">
                          <ToggleSwitch
                            onChange={this.toggleBlockMember}
                            type="destructive"
                            scope="member-status"
                            checked={currentMember.serviceStatus === MEMBER_SERVICE_STATUS.BLOCKED}
                          />
                        </div>
                      </div>
                      <div className="columns is-mobile">
                        <div className="column">
                          <Button
                            onClick={this.hideMember}
                            fullWidth={true}
                            buttonType="secondary"
                            disabled={
                              currentMember.serviceStatus === MEMBER_SERVICE_STATUS.HIDDEN ||
                              currentMember.serviceStatus === MEMBER_SERVICE_STATUS.BLOCKED
                            }
                          >
                            <Translate value="member_details.mark_inactive" />
                          </Button>
                        </div>
                        <div className="column flex-none vertical-align">
                          <Tooltip
                            title={
                              <div className="fs-12">
                                <Translate value="member_details.inactive_tooltip" />
                              </div>
                            }
                            arrow
                            placement="top"
                          >
                            <span className="info-circle">?</span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </CanPerform>
                  <CanPerform action={authority.managePatients}>
                    <div
                      className="card-container comment-container"
                      style={currentCommentColor ? { backgroundColor: currentCommentColor } : null}
                    >
                      <div className="columns is-mobile">
                        <div className={`column is-4 comment-header ${currentCommentColor ? 'has-color' : ''}`}>
                          <Translate value="member_details.comment" />
                        </div>
                        <div className="column text-right">
                          <div className="color reset" data-color={null} onClick={this.changeCommentColor}></div>
                          {COMMENT_COLORS.map((color) => (
                            <div
                              key={color}
                              className="color"
                              style={{ backgroundColor: color }}
                              data-color={color}
                              onClick={this.changeCommentColor}
                            ></div>
                          ))}
                        </div>
                      </div>
                      <DebounceInput
                        element="textarea"
                        maxLength="160"
                        value={memberCommentText}
                        debounceTimeout={200}
                        onChange={this.props.updateMemberCommentText}
                      />
                      {memberCommentText !== memberComment.text && !this.props.members.loadingMemberComment ? (
                        <div className="mt-20">
                          <LoaderButton
                            isLoading={this.props.members.updatingMemberComment}
                            fullWidth={true}
                            onClick={this.saveNewComment}
                          >
                            <Translate value="global.buttons.save" />
                          </LoaderButton>
                        </div>
                      ) : null}
                    </div>
                  </CanPerform>
                  <CanPerform action={authority.managePatients}>
                    <div className="card-container">
                      <div className="jira-service-desk-logo"></div>
                      <div className="mb-25">
                        {jira.loadingJiraIssues ? (
                          <LoadingIcon type="spinner-secondary" size="small" />
                        ) : jira.jiraIssues.length === 0 ? (
                          <Translate className="jira__no-content" value="member_details.no_issues" />
                        ) : (
                          <span className="text-button fw-normal" onClick={this.props.showJiraHistoryModal}>
                            <Translate
                              value={`member_details.issue_${jira.jiraIssues.length === 1 ? 'single' : 'multiple'}`}
                              numIssues={jira.jiraIssues.length}
                            />
                          </span>
                        )}
                      </div>
                      <div className="create-issue-container">
                        <Button onClick={this.props.showJiraModal} fullWidth={true}>
                          <span className="icon jira"></span>
                          <Translate value="member_details.create_issue" />
                        </Button>
                      </div>
                    </div>
                  </CanPerform>
                </div>
                <div className="column">
                  <div className="card-container">
                    <ContentLoader
                      isLoading={fetchingMember}
                      error={memberError}
                      errorTitleI18n="member_details.error_fetching"
                    >
                      <h2>
                        <Translate value="member_details.general" />
                      </h2>
                      <div className="columns">
                        <div className="column">
                          <h4>
                            <Translate value="member_details.created" />
                          </h4>
                          {moment(currentMember.createdDate, DATE_FORMAT).format('YYYY-MM-DD')}
                        </div>
                        <div className="column">
                          <h4>
                            <Translate value="global.status" />
                          </h4>
                          {currentMember.serviceStatus === 'hidden' && !currentMember.region ? (
                            <span>
                              <Translate value="member_details.not_active_yet" />
                            </span>
                          ) : (
                            <Translate value={`global.service_status.${currentMember.serviceStatus}`} />
                          )}
                        </div>
                        <div className="column">
                          <h4>
                            <Translate value="member_details.platform" />
                          </h4>
                          <Translate value={`global.device.${memberDevice.platform || 'UNKNOWN'}`} />
                        </div>
                        <div className="column">
                          <h4>
                            <Translate value="member_details.last_login" />
                          </h4>
                          {currentMember.lastLoginDate
                            ? moment(currentMember.lastLoginDate, DATE_FORMAT).format('YYYY-MM-DD')
                            : '-'}
                        </div>
                      </div>
                      <div className="columns mb-30">
                        <div className="column">
                          <h4>
                            <Translate value="member_details.monitor_sent" />
                          </h4>
                          {currentMember.lastMonitorSentDate
                            ? moment(currentMember.lastMonitorSentDate, DATE_FORMAT).format('YYYY-MM-DD')
                            : '-'}
                        </div>
                      </div>
                      <h2>
                        <Translate value="member_details.biometrics" />
                      </h2>
                      <div className="columns mb-30">
                        <div className="column">
                          <h4>
                            <Translate value="member_details.arm_circumference" />
                          </h4>
                          <EditableSelect
                            isEditable={true}
                            isEditing={true}
                            requiredAuthority={authority.managePatients}
                            text={currentMember && currentMember.armCircumference}
                            options={Object.keys(MONITOR_SIZES).map((size) => {
                              return { value: size, label: I18n.t(`global.monitor_size.${size}`) };
                            })}
                            defaultValue={currentMember && currentMember.armCircumference}
                            placeholder={I18n.t('member_details.select_monitor_size')}
                            isLoadingOptions={false}
                            error={undefined}
                            errorI18nKey="member_details.error_fetching_region_list"
                            onChange={(e) => this.updateProperty(e, 'armCircumference')}
                            name="member-monitor-size-select"
                          />
                          {this.state.memberUpdates.armCircumference &&
                          this.state.memberUpdates.armCircumference !== currentMember.armCircumference ? (
                            <div className="pos-rel">
                              <div className="editable-value__control active">
                                <LoaderButton
                                  onClick={() => this.updateAnamnesisAnswer(ARM_CIRCUMFERENCE_ID, 'armCircumference')}
                                  buttonType="input-attached"
                                  isLoading={updatingAnamnesisAnswer}
                                >
                                  <Translate value="global.buttons.save" />
                                </LoaderButton>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="column"></div>
                        <div className="column"></div>
                      </div>
                      <h2>
                        <Translate value="member_details.testing" />
                      </h2>
                      <div className="columns mb-30">
                        <div className="column">
                          <h4>
                            <Translate value="member_details.region" />
                          </h4>
                          <EditableSelect
                            isEditable={true}
                            isEditing={true}
                            requiredAuthority={authority.managePatients}
                            text={currentMember.region && currentMember.region.name}
                            options={regions.map((region) => {
                              return { value: region.id, label: region.name };
                            })}
                            defaultValue={currentMember.region && currentMember.region.id}
                            placeholder={I18n.t('member_details.choose_region')}
                            isLoadingOptions={fetchingRegions}
                            error={regionsError}
                            errorI18nKey="member_details.error_fetching_region_list"
                            onChange={(e) => this.updateProperty(e, 'region')}
                            name="member-region-select"
                          />
                          {this.state.memberUpdates.region && this.state.memberUpdates.region.id !== '0' ? (
                            <div className="pos-rel">
                              <div className="editable-value__control active">
                                <LoaderButton
                                  onClick={this.saveNewPropertyValue('region')}
                                  buttonType="input-attached"
                                  isLoading={this.state.savingregion}
                                >
                                  <Translate value="global.buttons.save" />
                                </LoaderButton>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="column">
                          <h4>
                            <Translate value="member_details.latest_lab_results" />
                          </h4>
                          {currentMember.lastLabResultDate
                            ? moment(currentMember.lastLabResultDate, DATE_FORMAT).format('YYYY-MM-DD')
                            : '-'}
                        </div>
                        <div className="column"></div>
                      </div>
                      <h2>
                        <Translate value="member_details.treatment" />
                      </h2>
                      <div className="columns">
                        <div className="column">
                          <h4>
                            <Translate value="member_details.responsible_doctor" />
                          </h4>
                          <EditableSelect
                            isEditable={currentMember.region && currentMember.responsibleDoctor}
                            isEditing={true}
                            requiredAuthority="managePatients"
                            text={
                              currentMember.responsibleDoctor
                                ? `${currentMember.responsibleDoctor.givenName} ${currentMember.responsibleDoctor.familyName}`
                                : '-'
                            }
                            options={assignableDoctors.map((doctor) => {
                              return { value: doctor.guid, label: `${doctor.givenName} ${doctor.familyName}` };
                            })}
                            defaultValue={currentMember.responsibleDoctor && currentMember.responsibleDoctor.guid}
                            isLoadingOptions={loadingAssignableDoctors}
                            error={assignableDoctorsError}
                            errorI18nKey="member_details.error_fetching_pal_list"
                            onChange={(e) => this.updateCaregiver(e, CAREGIVER_ROLE.DOCTOR)}
                            name="member-pal-select"
                          />
                          {this.state.caregivers.doctor &&
                          this.state.caregivers.doctor.guid !== currentMember.responsibleDoctor.guid ? (
                            <div className="pos-rel">
                              <div className="editable-value__control active">
                                <LoaderButton
                                  onClick={this.saveNewCaregiver(CAREGIVER_ROLE.DOCTOR)}
                                  buttonType="input-attached"
                                  isLoading={this.props.members.assigningCaregiver}
                                >
                                  <Translate value="global.buttons.save" />
                                </LoaderButton>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="column">
                          <h4>
                            <Translate value="member_details.last_measurement" />
                          </h4>
                          {currentMember.lastMeasureDate
                            ? moment(currentMember.lastMeasureDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')
                            : '-'}
                        </div>
                        <div className="column">
                          <h4>
                            <Translate value="member_details.chat_communication_header" />
                          </h4>
                          {fetchingChatMessages ? (
                            <LoadingIcon type="spinner-secondary" size="small" />
                          ) : hasChatMessages ? (
                            <Translate value={`global.${latestMessageRead ? 'yes' : 'no'}`} />
                          ) : (
                            <Translate value="member_details.empty_chat" />
                          )}
                        </div>
                      </div>
                      <div className="columns mb-30">
                        <div className="column">
                          <h4>
                            <Translate value="member_details.responsible_nurse" />
                          </h4>
                          <EditableSelect
                            isEditable={!!currentMember.responsibleNurse}
                            isEditing={true}
                            requiredAuthority="managePatients"
                            text={
                              currentMember.responsibleNurse
                                ? `${currentMember.responsibleNurse.givenName} ${currentMember.responsibleNurse.familyName}`
                                : '-'
                            }
                            options={nurses.map((nurse) => {
                              return { value: nurse.guid, label: `${nurse.givenName} ${nurse.familyName}` };
                            })}
                            defaultValue={currentMember.responsibleNurse && currentMember.responsibleNurse.guid}
                            isLoadingOptions={this.props.sharedData.loadingNurses}
                            error={this.props.sharedData.nursesError}
                            errorI18nKey="member_details.error_fetching_pas_list"
                            onChange={(e) => this.updateCaregiver(e, CAREGIVER_ROLE.NURSE)}
                            name="member-pas-select"
                          />
                          {this.state.caregivers.nurse &&
                          this.state.caregivers.nurse.guid !== currentMember.responsibleNurse.guid ? (
                            <div className="pos-rel">
                              <div className="editable-value__control active">
                                <LoaderButton
                                  onClick={this.saveNewCaregiver(CAREGIVER_ROLE.NURSE)}
                                  buttonType="input-attached"
                                  isLoading={this.props.members.assigningCaregiver}
                                >
                                  <Translate value="global.buttons.save" />
                                </LoaderButton>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="column"></div>
                        <div className="column"></div>
                      </div>
                      <h2>
                        <Translate value="member_details.payment_exemption_header" />
                      </h2>
                      <div className="columns mb-30">
                        <div className="column is-3">
                          <h4>
                            <Translate value="member_details.payment_exemption_card_number" />
                          </h4>
                          <CanPerform
                            action={authority.managePatients}
                            fallbackComponent={() => (
                              <span>
                                {currentMember.paymentExemption ? currentMember.paymentExemption.cardNumber : '-'}
                              </span>
                            )}
                          >
                            <input
                              type="text"
                              value={paymentExemption ? paymentExemption.cardNumber : ''}
                              onChange={this.updatePaymentExemption('cardNumber')}
                            />
                          </CanPerform>
                        </div>
                        <div className="column is-3">
                          <h4>
                            <Translate value="member_details.payment_exemption_card_date" />
                          </h4>
                          <CanPerform
                            action={authority.managePatients}
                            fallbackComponent={() => (
                              <span>
                                {currentMember.paymentExemption ? currentMember.paymentExemption.validUntil : '-'}
                              </span>
                            )}
                          >
                            <DatePickerWithFallback
                              locale={'sv'}
                              minDate={moment().add(1, 'd').toDate()}
                              maxDate={null}
                              selectedDate={
                                paymentExemption &&
                                paymentExemption.validUntil &&
                                moment(paymentExemption.validUntil).toDate()
                              }
                              placeholderI18nKey="global.choose_date"
                              onChange={this.updatePaymentExemption('validUntil')}
                              onChangeNative={this.updatePaymentExemption('validUntil', true)}
                              isValid={
                                paymentExemption && paymentExemption.validUntil
                                  ? moment(paymentExemption.validUntil).isAfter(moment())
                                  : true
                              }
                            />
                          </CanPerform>
                        </div>
                        <div className="column">
                          <CanPerform action={authority.managePatients}>
                            <h4 className="desktop-only">&nbsp;</h4>
                            <div className="flex">
                              {(currentMember.paymentExemption &&
                                currentMember.paymentExemption.cardNumber &&
                                currentMember.paymentExemption.cardNumber !== paymentExemption.cardNumber) ||
                              (!currentMember.paymentExemption && paymentExemption.cardNumber) ||
                              (currentMember.paymentExemption &&
                                currentMember.paymentExemption.validUntil &&
                                currentMember.paymentExemption.validUntil !== paymentExemption.validUntil) ||
                              (!currentMember.paymentExemption && paymentExemption.validUntil) ? (
                                <div className="mr-10 mb-5">
                                  <LoaderButton
                                    buttonType="secondary"
                                    disabled={!paymentExemption.cardNumber || !paymentExemption.validUntil}
                                    isLoading={
                                      this.props.members.updatingPaymentExemption ||
                                      this.props.members.postingPaymentExemption
                                    }
                                    onClick={this.savePaymentExemption}
                                  >
                                    <Translate value="global.buttons.save" />
                                  </LoaderButton>
                                </div>
                              ) : null}
                              {currentMember.paymentExemption ? (
                                <div>
                                  <LoaderButton
                                    buttonType="destructive"
                                    isLoading={this.props.members.deletingPaymentExemption}
                                    onClick={this.deletePaymentExemption}
                                  >
                                    <Translate value="global.buttons.remove" />
                                  </LoaderButton>
                                </div>
                              ) : null}
                            </div>
                          </CanPerform>
                        </div>
                      </div>
                      <CanPerform action={authority.managePatients}>
                        <h2>
                          <Translate value="member_details.scheduled_communication.header" />
                        </h2>
                        <ScheduledCommunication
                          isLoading={loadingScheduledCommunication}
                          items={scheduledCommunication}
                          error={scheduledCommunicationError}
                          onUpdate={this.updateCommunication}
                          onDelete={this.deleteCommunication}
                        />
                      </CanPerform>
                      <PatientFeeHistory
                        member={currentMember}
                        history={paymentHistory}
                        isLoading={fetchingPaymentHistory}
                        error={paymentHistoryError}
                      />
                      {currentMember.claimId ? (
                        <Fragment>
                          <h2 className="mt-30">
                            <Translate value="member_details.insurance_header" />
                          </h2>
                          <div className="columns">
                            <div className="column">
                              <h4>
                                <Translate value="member_details.claim_id" />
                              </h4>
                              {currentMember.claimId}
                            </div>
                          </div>
                        </Fragment>
                      ) : null}
                    </ContentLoader>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <ErrorFallback>
              <Translate value="member_details.error_fetching" />
            </ErrorFallback>
          )}
        </div>
        <Modal
          headerI18nKey="jira.modal_header"
          actionI18nKey="jira.modal_button"
          visible={jira.modalVisible}
          onClose={this.props.hideJiraModal}
          onActionCompleted={this.createJiraIssue}
          actionCompletable={!!jira.selectedSupportType}
          actionCompleting={jira.creatingIssue}
          contentClass="min-height-400"
        >
          <JiraSupportModalContent
            content={jira}
            onSelectIssueType={this.props.setJiraIssueType}
            onChangeDescription={this.props.setJiraIssueDescription}
          />
        </Modal>
        <Modal
          headerI18nKey="jira.history_header"
          actionI18nKey="global.buttons.ok"
          visible={jira.historyModalVisible}
          onClose={this.props.hideJiraHistoryModal}
          onActionCompleted={this.props.hideJiraHistoryModal}
          actionCompletable={true}
        >
          {jira.jiraIssues.length ? (
            <Fragment>
              <div className="columns fs-14 mb-5 is-mobile">
                <div className="column is-6 no-padding">
                  <Translate value="jira.history_column.issue" />
                </div>
                <div className="column is-4 no-padding">
                  <Translate value="jira.history_column.created" />
                </div>
                <div className="column is-2 no-padding">
                  <Translate value="jira.history_column.status" />
                </div>
              </div>
              {jira.jiraIssues.map((issue) => {
                return (
                  <div className="columns member-jira-issue is-mobile" key={issue.key}>
                    <div className="column is-6 no-padding">
                      <a
                        href={`https://jira.accumbo.com/browse/${issue.key}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {issue.summary}
                      </a>
                    </div>
                    <div className="column is-4 no-padding">
                      {moment(issue.createdDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
                    </div>
                    <div className="column is-2 no-padding">
                      <Translate value={issue.status} />
                    </div>
                  </div>
                );
              })}
            </Fragment>
          ) : (
            <Translate value="jira.history_empty" />
          )}
        </Modal>
        <Modal
          header={I18n.t('member_details.send_message_to', {
            name: `${currentMember.givenName} ${currentMember.familyName}`
          })}
          actionI18nKey="global.buttons.send_message"
          visible={this.props.members.messageModalActive}
          onClose={this.closeMessageModal}
          onActionCompleted={this.sendMessage}
          actionCompletable={messageBody.trim().length > 0 && messageSubject.trim().length > 0}
          actionCompleting={this.props.members.sendingMessage}
          size="medium"
        >
          <MemberMessageEditor
            locale={this.props.locale}
            messageSubject={messageSubject}
            messageBody={messageBody}
            onSubjectChanged={this.props.updateMessageSubject}
            onBodyChanged={this.props.updateMessageBody}
          />
        </Modal>
        <Modal
          headerI18nKey="member_details.actions.export_journal"
          actionI18nKey="member_details.actions.export_journal"
          visible={this.props.members.exportJournalModalVisible}
          onClose={this.props.toggleExportJournalModal}
          onActionCompleted={this.downloadMemberJournal}
          actionCompletable={this.state.selectedJournalInterval !== null}
          actionCompleting={this.props.members.fetchingMemberJournal}
          size="auto"
          contentClass="min-height-210"
        >
          <h4 className="mt-0">
            <Translate value="member_details.timespan_header" />
          </h4>
          <EditableSelect
            isEditable={true}
            isEditing={true}
            requiredAuthority="manageUsers"
            options={[
              { value: '14', label: I18n.t('member_details.journal_timespan.two_weeks') },
              { value: '30', label: I18n.t('member_details.journal_timespan.one_month') },
              { value: '90', label: I18n.t('member_details.journal_timespan.ninety_days') }
            ]}
            errorI18nKey="member_details.error_fetching_pal_list"
            onChange={(e) => {
              this.setState({ selectedJournalInterval: e.value });
            }}
            placeholder={I18n.t('member_details.choose_journal_timespan')}
            name="export-journal-select"
          />
        </Modal>
        <Modal
          headerI18nKey="member_details.spar.modal_header"
          actionI18nKey="global.buttons.ok"
          visible={this.props.members.sparModalVisible}
          onClose={this.props.toggleSparModal}
          onActionCompleted={this.props.toggleSparModal}
          actionCompletable={true}
          size="medium"
          additionalFooterContent={
            <Fragment>
              <div className="columns mb-5">
                <div className="no-padding fs-14">
                  <strong>Tilltalsnamn</strong>: Kod som anger vilka av förnamnen som är tilltalsnamn. Om det inte finns
                  någon kod för tilltalsnamn finns inget tilltalsnamn angivet i folkbokföringen och därmed inte i SPAR.
                </div>
              </div>
              <div className="columns mb-10">
                <div className="no-padding fs-14">
                  <strong>Exempel</strong>: 20 = 2 anger att det andra förnamnet är tilltalsnamnet. 12 = 12 anger att
                  det första och andra förnamnet är tilltalsnamn. (förnamn med bindestreck betraktas som två namn)
                </div>
              </div>
            </Fragment>
          }
        >
          <ContentLoader
            isLoading={this.props.members.fetchingSparData}
            error={this.props.members.sparError}
            errorTitleI18n="member_details.spar.error"
            type="spinner-secondary"
          >
            {this.convertSparToReadable(this.props.members.sparData)}
          </ContentLoader>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    authorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes,
    members: state.members,
    jira: state.jira,
    sharedData: state.sharedData,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  getMember,
  updateMemberSuccess,
  getMemberDevices,
  toggleEditMemberDetails,
  getAssignableDoctors,
  setMemberServiceStatus,
  showNotification,
  updateMemberAnamnesisAnswer,
  getMemberComment,
  updateMemberComment,
  updateMemberCommentText,
  getMemberProfileImage,
  showJiraModal,
  hideJiraModal,
  getMemberJiraIssues,
  getJiraIssueTypes,
  postMemberJiraIssue,
  setJiraIssueType,
  setJiraIssueDescription,
  hideJiraHistoryModal,
  showJiraHistoryModal,
  addManualShipment,
  getScheduledCommunication,
  updateScheduledCommunication,
  deleteScheduledCommunication,
  showMessageModal,
  hideMessageModal,
  sendMemberMessage,
  updateMessageSubject,
  updateMessageBody,
  toggleExportJournalModal,
  getMemberJournalRequest,
  getMemberJournalSuccess,
  getMemberJournalError,
  getMemberChatMessages,
  toggleSparModal,
  getMemberSparData,
  updateMemberPaymentExemption,
  updatePaymentExemption,
  deleteMemberPaymentExemption,
  postMemberPaymentExemption,
  getMemberPaymentHistory,
  assignCaregiver
};

export default connect(mapStateToProps, mapActionsToProps)(MemberDetailsView);

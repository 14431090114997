import React, { FC } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Redirect } from 'react-router-dom';
import { getMembers, setAdditionalSearchCriteria } from "../../actions";
import { APPLICATION_BASE_PATH } from "../../constants";
import MemberSearch from "../MemberSearch";

type ArmCircumferenceInterval = {
  id: string;
  name: "string";
};

type SearchOptions = {
  query: string;
  offset: number;
  limit: number;
  returnToIndexPage: boolean;
  searchTerm: string;
  sortBy?: string;
};

type SearchState = {
  members: FixMe[];
  additionalCriteria: Record<string, unknown>;
};

type IndexViewProps = {
  authToken: string;
  search: SearchState;
  sources: string[];
  regions: Region[];
  armCircumferenceIntervals: ArmCircumferenceInterval[];
  authorities: string[];
  authority: Record<string, string>;
  getMembers: (jwt: string, options: SearchOptions) => void;
  setAdditionalSearchCriteria: () => void;
};

const IndexView: FC<IndexViewProps> = (props) => {
  const getMembers = (options: SearchOptions) => {
    props.getMembers(props.authToken, options);
  };

  const { search, setAdditionalSearchCriteria } = props;

  const predefinedSearches = [
    {
      linkTextI18nKey: "new_lab_results_view.header",
      page: "/members/new-lab-results",
    },
    {
      linkTextI18nKey: "new_members_dispatch_view.header",
      page: "/members/new-members-dispatch",
    },
  ];

  const additionalCriteriaOptions = [
    {
      queryProperty: "properties.key.source",
      headerI18n: "member_details.source",
      optionsList: [I18n.t("member_search.all_sources"), ...props.sources],
      onChange: setAdditionalSearchCriteria,
      type: "select",
    },
    {
      queryProperty: "region.id",
      headerI18n: "member_details.region",
      optionsList: [I18n.t("member_search.all_regions"), ...props.regions],
      onChange: setAdditionalSearchCriteria,
      type: "select",
    },
    {
      queryProperty: "mobileDevice.platform",
      headerI18n: "member_details.platform",
      optionsList: [I18n.t("member_search.all_platforms"), "Android", "iOS"],
      onChange: setAdditionalSearchCriteria,
      type: "select",
    },
    {
      queryProperty: "serviceStatus",
      headerI18n: "global.status",
      optionsList: [
        I18n.t("global.all"),
        { id: "doctor", name: I18n.t("global.service_status.doctor") },
        { id: "nurse", name: I18n.t("global.service_status.nurse") },
        { id: "hidden", name: I18n.t("global.service_status.hidden") },
        { id: "blocked", name: I18n.t("global.service_status.blocked") },
      ],
      onChange: setAdditionalSearchCriteria,
      type: "select",
    },
    {
      queryProperty: "armCircumference",
      headerI18n: "member_details.arm_circumference",
      optionsList: [
        I18n.t("member_search.all_arm_circumferences"),
        ...props.armCircumferenceIntervals,
      ],
      onChange: setAdditionalSearchCriteria,
      type: "select",
    },
    {
      queryProperty: "createdDate",
      headerI18n: "member_search.created_date",
      optionsList: [],
      onChange: setAdditionalSearchCriteria,
      type: "datepicker",
    },
  ];

  if (props.authority.viewPatients && !props.authorities.includes(props.authority.viewPatients)) {
    return <Redirect to={`${APPLICATION_BASE_PATH}/services`} />;
  }

  return (
    <MemberSearch
      onSearch={getMembers}
      searchResult={search}
      urlFragment="member"
      predefinedSearches={predefinedSearches}
      additionalCriteriaOptions={additionalCriteriaOptions}
      additionalCriteria={search.additionalCriteria}
    />
  );
};

const mapStateToProps = (state: FixMe) => {
  return {
    authToken: state.auth.token.jwt,
    search: state.members,
    sources: state.sharedData.sources,
    regions: state.sharedData.regions,
    armCircumferenceIntervals: state.sharedData.armCircumferenceIntervals,
    authorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes,
  };
};

const mapActionsToProps = {
  getMembers,
  setAdditionalSearchCriteria,
};

export default connect(mapStateToProps, mapActionsToProps)(IndexView);

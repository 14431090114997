import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import IndexView from '../../components/IndexView';
import LogoutView from '../../components/LogoutView';
import PrivateRoute from '../../components/PrivateRoute';
import { APPLICATION_BASE_PATH } from '../../constants';
import NavigationBar from '../../components/NavigationBar';
import ThemeProvider from '../../components/ThemeProvider';
import MemberDetailsView from '../../components/MemberDetailsView';
import EditMemberDetailsView from '../../components/MemberDetailsView/EditMemberDetailsView';
import MembersWithNewLabResultsView from '../../components/MembersWithNewLabResultsView';
import NewMembersDispatchView from '../../components/NewMembersDispatchView';
import SystemUserSearchView from '../../components/SystemUserSearchView';
import SystemUserDetailsView from '../../components/SystemUserDetailsView';
import EditSystemUserDetailsView from '../../components/SystemUserDetailsView/EditSystemUserDetailsView';
import ServicesView from '../../components/ServicesView';
import Notification from '../../components/Notification';
import {
  getRegions,
  getAuthorities,
  hideNotification,
  getCaregivers,
  getRemunerationScheme,
  getNurses,
  getProfileImage
} from '../../actions';

class App extends Component {
  componentDidMount() {
    this.props.getRegions(this.props.authToken);
    this.props.getCaregivers(this.props.authToken);
    this.props.getNurses(this.props.authToken);
    this.props.getAuthorities();
    this.props.getRemunerationScheme(this.props.authToken);

    if (this.props.user.profileImage) {
      this.props.getProfileImage(this.props.authToken, this.props.user.guid, this.props.user.profileImage.id);
    }
  }

  render() {
    const { authority } = this.props;
    if (!authority) return null;
    return (
      <ThemeProvider>
        <NavigationBar />
        <div className="page-content">
          <Switch>
            <PrivateRoute exact path={`/${APPLICATION_BASE_PATH}`} component={IndexView} />
            <PrivateRoute
              exact
              path={`/${APPLICATION_BASE_PATH}/member/:memberId`}
              component={MemberDetailsView}
              requiredAuthority={authority.viewPatients}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/member/:memberId/edit`}
              component={EditMemberDetailsView}
              requiredAuthority={authority.managePatients}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/members/new-lab-results`}
              component={MembersWithNewLabResultsView}
              requiredAuthority={authority.managePatients}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/members/new-members-dispatch`}
              component={NewMembersDispatchView}
              requiredAuthority={authority.managePatients}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/users`}
              component={SystemUserSearchView}
              requiredAuthority={authority.manageUsers}
            />
            <PrivateRoute
              exact
              path={`/${APPLICATION_BASE_PATH}/user/:memberId`}
              component={SystemUserDetailsView}
              requiredAuthority={authority.manageUsers}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/user/:memberId/edit`}
              component={EditSystemUserDetailsView}
              requiredAuthority={authority.manageUsers}
            />
            <PrivateRoute path={`/${APPLICATION_BASE_PATH}/services`} component={ServicesView} />
            <PrivateRoute path={`/${APPLICATION_BASE_PATH}/logout`} component={LogoutView} />
          </Switch>
        </div>
        <Notification {...this.props.notification} onClose={this.props.hideNotification} />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    notification: state.notifications,
    authority: state.sharedData.authorityTypes
  };
};

const mapActionsToProps = {
  getRegions,
  getAuthorities,
  hideNotification,
  getCaregivers,
  getNurses,
  getRemunerationScheme,
  getProfileImage
};

export default connect(mapStateToProps, mapActionsToProps)(Sentry.withProfiler(App));

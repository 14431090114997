import React from "react";
import "./LoadingIcon.scss";

type Props = {
  size?: string;
  type: "icon" | "spinner" | "spinner-secondary";
};

const LoadingIcon: React.FC<Props> = ({ size = "", type }: Props) => {
  return <span className={`loading-icon ${size} ${type}`}></span>;
};

export default LoadingIcon;

import moment from "moment";
import xRegExp from "xregexp";
import { DATE_FORMAT, CAREGIVER_ROLE } from "../constants";

moment.updateLocale("sv", {
  calendar: {
    sameDay: "[I dag] \\k\\l\\. HH:mm",
  },
});

moment.updateLocale("en", {
  calendar: {
    sameDay: "[Today] \\a\\t HH:mm",
  },
});

export const configuredMoment = moment;

export const getAgeFromPersonalNumber = (personalNumber?: string) => {
  if (!personalNumber) return;
  const year = personalNumber.substring(0, 4);
  const month = personalNumber.substring(4, 6);
  const day = personalNumber.substring(6, 8);

  return moment().diff(`${year}-${month}-${day}`, "years");
};

export const getGenderFromPersonalNumber = (personalNumber: string): Gender => {
  if (!personalNumber) return;
  return parseInt(personalNumber.charAt(10), 10) % 2 === 0 ? "female" : "male";
};

export const camelcase = (input: string) => {
  return input
    .replace(/^[_.\- ]+/, "")
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (m, p1) => p1.toUpperCase());
};

export const isTokenNearingExpiry = (token: LoginResponse) => {
  const now = moment();
  const expires = moment(token.expires, DATE_FORMAT).add(-15, "minutes");

  return now.isAfter(expires);
};

export function updateObjectInArray<T>(
  array: T[],
  action: ArrayAction<T>
): T[] {
  return array.map((item, index) => {
    if (index !== action.index) {
      return item;
    }
    return {
      ...item,
      ...action.item,
    };
  });
}

export function removeItemFromArray<T>(
  array: T[],
  action: ArrayAction<T>
): T[] {
  return [...array.slice(0, action.index), ...array.slice(action.index + 1)];
}

export function addItemToArrayAtPosition<T>(
  array: T[],
  action: ArrayAction<T>
): T[] {
  return [
    ...array.slice(0, action.index),
    action.item,
    ...array.slice(action.index),
  ];
}

export const convertCuffSizeToInt = (size: CuffSize) => {
  switch (size) {
    case "S":
      return 1;
    case "M":
      return 2;
    case "L":
      return 3;
    case "XL":
      return 4;
    default:
      return 0;
  }
};

export const calculateBmi = (height: number, weight: number) => {
  height = height / 100;
  const bmi = weight / (height * height);

  return bmi.toPrecision(3);
};

export const groupBy = (objectArray: FixMe[], key: string): Record<string, unknown> => {
  return objectArray.reduce((acc, curr) => {
    (acc[curr[key]] = acc[curr[key]] || []).push(curr);
    return acc;
  }, {});
};

export const decamelize = (text: string, separator?: string) => {
  separator = typeof separator === "undefined" ? "_" : separator;

  const regex1 = xRegExp("([\\p{Ll}\\d])(\\p{Lu})", "g");
  const regex2 = xRegExp("(\\p{Lu}+)(\\p{Lu}[\\p{Ll}\\d]+)", "g");

  return text
    .replace(regex1, `$1${separator}$2`)
    .replace(regex2, `$1${separator}$2`)
    .toLowerCase();
};

export function toggleItem<T>(selectedItems: T[], toggledItem: T): T[] {
  let selectedItemsCopy = [...selectedItems];
  const toggledItemIndex = selectedItemsCopy.indexOf(toggledItem);

  if (toggledItemIndex > -1) {
    selectedItemsCopy.splice(toggledItemIndex, 1);
  } else {
    selectedItemsCopy = [...selectedItemsCopy, toggledItem];
  }

  return selectedItemsCopy;
}

export const extractProperties = (obj: FixMe, keys: string[]) => {
  const newObj = { ...obj };

  keys.forEach((key) => {
    if (obj.properties) {
      const found = obj.properties.find((el: Record<string, string>) => el.key === key);
      if (found) {
        newObj[key] = found.value;
      }
    }
  });

  return newObj;
};

export const formatPersonalNumber = (personalNumber?: string) => {
  if (!personalNumber) return;
  return `${personalNumber.substr(0, 8)}-${personalNumber.substr(8, 4)}`;
};

export const getQueryString = (
  searchTerm = '',
  additionalCriteria: AdditionalCriterionOption[]
) => {
  let query = '';
  const numberRegex = /\d/g;
  const emailRegex = /@/g;
  const trimmedSearchTerm = searchTerm.trim();

  if (emailRegex.test(trimmedSearchTerm)) {
    query = `(email=="*${trimmedSearchTerm}*")`;
  } else if (numberRegex.test(trimmedSearchTerm)) {
    const number = trimmedSearchTerm;
    query = `(personalNumber==*${number.replace('-', '').replace(/\s+/g, '')}*,phoneNumber==*${number.replace(/\s+/g, '')}*)`;
  } else if (trimmedSearchTerm.length) {
    query = `concatenatedNames=="*${trimmedSearchTerm}*"`;
  }

  if (additionalCriteria) {
    for (const [key, value] of Object.entries(additionalCriteria)) {
      if (value) {
        // @ts-ignore
        if (value.includes('=')) {
          query += `${query.length ? ';' : ''}${key}${value}`;
        } else {
          query += `${query.length ? ';' : ''}(${key}==${value})`;
        }
      }
    }
  }

  return query;
};

export const extractCaregivers = (member: FixMe) => {
  if (member.caregivers && member.caregivers.length) {
    const newMember = { ...member };

    const foundResponsibleNurse = member.caregivers.filter((c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.NURSE);
    if (foundResponsibleNurse.length) {
      newMember.responsibleNurse = foundResponsibleNurse[0].caregiver;
    }

    const foundResponsibleDoctor = member.caregivers.filter(
      (c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.DOCTOR && c.priority === 1
    );
    if (foundResponsibleDoctor.length) {
      newMember.responsibleDoctor = foundResponsibleDoctor[0].caregiver;
    }

    const foundSecondaryResponsibleDoctor = member.caregivers.filter(
      (c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.DOCTOR && c.priority === 2
    );
    if (foundSecondaryResponsibleDoctor.length) {
      newMember.secondaryResponsibleDoctor = foundSecondaryResponsibleDoctor[0].caregiver;
    }

    const foundSecondaryResponsibleNurse = member.caregivers.filter(
      (c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.NURSE && c.priority === 2
    );
    if (foundSecondaryResponsibleNurse.length) {
      newMember.secondaryResponsibleNurse = foundSecondaryResponsibleNurse[0].caregiver;
    }

    return newMember;
  }

  return member;
};

export const getAssignableCaregivers = (searchResults: FixMe, caregiverRole: FixMe, member: FixMe) => {
  const caregiverProperty = `responsible${caregiverRole === CAREGIVER_ROLE.DOCTOR ? 'Doctor' : 'Nurse'}`;
  const assignableCaregivers = searchResults.map((searchResult: FixMe) => {
    return {
      ...searchResult.user,
      ...searchResult.systemUserExtras
    };
  });

  const currentCaregiverIndex = assignableCaregivers.findIndex(
    (caregiver: FixMe) => caregiver.guid === member[caregiverProperty]?.guid
  );
  const currentCaregiver = assignableCaregivers.find((caregiver: FixMe) => caregiver.guid === member[caregiverProperty]?.guid);

  if (currentCaregiverIndex > -1) {
    assignableCaregivers.splice(currentCaregiverIndex, 1);
    assignableCaregivers.unshift(currentCaregiver);
  } else {
    assignableCaregivers.unshift(member[caregiverProperty]);
  }

  return assignableCaregivers;
};

const createImage = (src: FixMe) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => {
      reject(error);
    });
    image.src = src;
  });
};

export const getCroppedImage = async (imageSrc: FixMe, croppedArea: FixMe) => {
  const image: FixMe = await createImage(imageSrc);
  const canvas: FixMe = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);
  const imageData = ctx.getImageData(0, 0, image.width, image.height);

  // Resize canvas to crop size. This resets the context.
  canvas.width = croppedArea.width;
  canvas.height = croppedArea.height;

  // Put the stored image data onto the canvas with the correct offsets for x, y crop values.
  ctx.putImageData(imageData, -croppedArea.x, -croppedArea.y);

  return new Promise((resolve) => {
    canvas.toBlob((file: FixMe) => {
      resolve(file);
    }, 'image/jpeg');
  });
};

export const getEnvironmentPrefix = (env: string): string => {
  const environments = {
    development: 'dev',
    development2: 'dev2',
    staging: 'test',
    staging2: 'test2',
    sandbox: 'sandbox',
    local: 'local',
    production: ''
  };

  return environments[env];
};

export const capitalize = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

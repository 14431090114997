import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../Button';
import ContentLoader from '../ContentLoader';
import DatePickerWithFallback from '../DatePickerWithFallback';
import { getMembersWithNewLabResults } from '../../actions';
import './MembersWithNewLabResultsView.scss';

const MembersWithNewLabResultsView = ({ authToken, search, locale, getMembersWithNewLabResults }) => {
  const [copied, setCopied] = useState(false);
  const [fromDate, setfromDate] = useState();
  const [showResults, setShowResults] = useState(false);

  const createMarkup = () => {
    return { __html: search.extractedText.replace(/\n/g, '<br />') };
  };

  return (
    <div>
      <div className="card date-search-container mb-30">
        <div className="card-header">
          <Translate value="new_lab_results_view.header" />
        </div>
        <div className="card-content">
          <div className="columns">
            <div className="column">
              <DatePickerWithFallback
                locale={locale}
                selectedDate={fromDate}
                placeholderI18nKey="new_lab_results_view.since_date"
                onChange={(date) => {
                  setfromDate(date);
                  setShowResults(false);
                }}
                onChangeNative={(e) =>
                  e.target.value ? setfromDate(moment(e.target.value).toDate()) : setfromDate(undefined)
                }
              />
            </div>
            <div className="column is-4 text-right">
              <Button
                onClick={() => {
                  getMembersWithNewLabResults(authToken, moment(fromDate).format('YYYY-MM-DD'));
                  setShowResults(true);
                }}
                disabled={!fromDate}
              >
                <Translate value="new_lab_results_view.search_button" />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ContentLoader
        isLoading={search.loadingMembersWithNewLabResults}
        error={search.membersWithNewLabResultsError}
        errorTitleI18n="new_lab_results_view.error"
      >
        {search.extractedText && showResults ? (
          <Fragment>
            <div className="card text-container mb-20" dangerouslySetInnerHTML={createMarkup()}></div>

            <div className="text-center">
              <CopyToClipboard text={search.extractedText} onCopy={() => setCopied(true)}>
                <Button>
                  <Translate value="new_lab_results_view.action_button" />
                </Button>
              </CopyToClipboard>
              {copied ? (
                <div className="mt-10">
                  <span className="copied-highlight">
                    <Translate value="new_lab_results_view.copied" />
                  </span>
                </div>
              ) : null}
            </div>
          </Fragment>
        ) : search.membersWithNewLabResults && search.membersWithNewLabResults.length === 0 && showResults ? (
          <div className="card text-container mb-20">
            <Translate value="new_lab_results_view.no_results" /> {moment(fromDate).format('YYYY-MM-DD')}
          </div>
        ) : null}
      </ContentLoader>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    search: state.members,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  getMembersWithNewLabResults
};

export default connect(mapStateToProps, mapActionsToProps)(MembersWithNewLabResultsView);

import React from "react";
import "./Button.scss";

type ButtonProps = {
  children: React.ReactNode;
  /** Native button type */
  type?: "button" | "submit" | "reset";
  size?: "small" | "medium";
  /** The button's visual style */
  buttonType?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "destructive"
    | "text"
    | "input-attached";
  disabled?: boolean;
  /** Indicates whether the button should occupy the full width of its parent container */
  fullWidth?: boolean;
  onClick: () => void;
};

const Button: React.FC<ButtonProps> = ({
  children,
  type = "button",
  size,
  buttonType = "primary",
  disabled = false,
  fullWidth = false,
  onClick,
}) => {
  return (
    <button
      type={type}
      className={`button button-${buttonType} ${
        fullWidth ? "full-width" : ""
      } ${size ? `button-size-${size}` : ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;

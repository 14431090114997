import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Slide from '@material-ui/core/Slide';

class Notification extends Component {
  render() {
    const { open, message, duration, onClose, notificationType } = this.props;

    return (
      <Snackbar
        open={open}
        autoHideDuration={duration || 5000}
        onClose={onClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          className={`notification notification-${notificationType}`}
          aria-describedby="client-snackbar"
          message={
            <div className="columns is-mobile">
              <div className="column is-1">
                <div className="line"></div>
              </div>
              <div className="column is-11 vertical-align">{message}</div>
            </div>
          }
          action={[<span key="action-span" onClick={onClose} className="notification-icon"></span>]}
        />
      </Snackbar>
    );
  }
}

export default Notification;

import React from 'react';
import { Translate } from 'react-redux-i18n';
import './Card.scss';
import LoadingIcon from '../LoadingIcon';
import ErrorMessage from '../ErrorMessage';

const Card = ({
  titleI18nKey,
  descriptionI18nKey,
  children,
  additionalClass,
  isLoading,
  corner,
  actionButtons,
  error,
  errorI18nKey
}) => {
  return (
    <div className={`card ${additionalClass || ''}`}>
      {titleI18nKey ? (
        <div className="card-header">
          <div className="columns is-mobile">
            <div className="column no-padding">
              <Translate value={titleI18nKey} />
              {actionButtons ? <span className="card-actions">{actionButtons}</span> : null}
            </div>
            {isLoading !== undefined ? (
              <div className="column is-2 no-padding right-align">
                {isLoading ? <LoadingIcon type="spinner-secondary" size="small" /> : null}
              </div>
            ) : null}
            {corner && corner}
          </div>
        </div>
      ) : null}
      {descriptionI18nKey && (
        <div className="card-description">
          <Translate value={descriptionI18nKey} />
        </div>
      )}
      <div className="card-content">
        {error ? <ErrorMessage error={error} errorI18nKey={errorI18nKey} /> : children}
      </div>
    </div>
  );
};

export default Card;

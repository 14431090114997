export const en = {
  global: {
    title: 'Accumbo Admin',
    loading: 'Loading...',
    dark_mode: 'Dark mode',
    change_language: 'Change language',
    buttons: {
      save: 'Save',
      done: 'Done',
      cancel: 'Cancel',
      ok: 'OK',
      generate: 'Generate',
      block: 'Block user',
      reactivate: 'Reactivate user',
      send_message: 'Skicka meddelande',
      remove: 'Remove'
    },
    male: 'Man',
    female: 'Woman',
    authorities: 'Authorities',
    personal_number: 'Personal number',
    name: 'Name',
    created: 'Created',
    search: 'Search',
    previous: 'Previous',
    next: 'Next',
    device: {
      ANDROID: 'Android',
      IOS: 'iOS',
      UNKNOWN: 'Unknown'
    },
    years: 'years',
    show_error_info: 'Show detailed information',
    reason: 'Reason',
    logout: 'Sign out',
    login: 'Log in',
    status: 'Status',
    service_status: {
      active: 'Active',
      hidden: 'Inactive',
      blocked: 'Blocked',
      nurse: 'Handled by nurse',
      doctor: 'Handled by doctor'
    },
    choose: 'Select...',
    all: 'All',
    choose_date: 'Choose date',
    username: 'Username',
    password: 'Password',
    disallowed_route: "You don't have access to this page. 😐",
    from_date: 'Start date',
    to_date: 'End date',
    administrator: 'Administrator',
    user_title: 'Role',
    no_address: 'Address missing',
    copy: 'Copy',
    yes: 'Yes',
    no: 'No',
    doctor: 'Doctor',
    patients: 'patients',
    of: 'of',
    final_note_reason: {
      checkup: 'Appointment',
      new_member_checkup: 'New patient',
      three_month_checkup: '3-month appointment',
      yearly_checkup: 'Yearly appointment',
      extra_checkup: 'Extra checkup',
      six_week_checkup: '6-week checkup',
      initial_checkup: 'Initial checkup',
      nurse_treatment: 'Nurse treatment, hypertension',
      nurse_new_member: 'Nurse new patient',
      nurse_two_week: 'Nurse two-week checkup',
      nurse_six_month: 'Nurse six-month checkup',
      nurse_yearly: 'Nurse yearly checkup',
      nurse_extra: 'Nurse extra checkup',
      missing: 'Checkup type missing'
    },
    payment_state: {
      created: 'Payment requested',
      pending: 'Pending',
      exemption: 'Exemption',
      complete_unknown: 'Paid',
      complete_swish: 'Paid with Swish',
      complete_card: 'Paid with card',
      complete_invoice: 'Paid with invoice',
      failed: 'Payment failed',
      error: 'Payment failed',
      cancelled: 'Cancelled'
    },
    payment_provider: {
      nordeaconnect: 'Nordea',
      invoice: 'Invoice',
      billogram: 'Billogram'
    },
    show_all: 'Show all',
    show_fewer: 'Show fewer',
    monitor_size: {
      S: 'Small (18-21 cm)',
      M: 'Medium (22-32 cm)',
      L: 'Large (33-42 cm)',
      XL: 'XLarge (43-56 cm)'
    },
    optional: 'Optional',
    year: 'Year',
    quarter: 'Quarter',
    month: 'Month'
  },
  icon: {
    service_status: {
      blocked: 'Blocked'
    },
    last_measure_date: 'Last measurement',
    last_lab_result_date: 'Last lab result',
    closed_support_issues: 'Closed support issues: %{value}',
    open_support_issues: 'Open support issues: %{value}',
    last_monitor_sent_date: 'Monitor sent'
  },
  notification: {
    save_generic: {
      success: 'New value saved.',
      error: 'New value could not be saved.'
    },
    service_status_active: {
      success: '%{name} has been unblocked',
      error: '%{name} could not be unblocked'
    },
    service_status_blocked: {
      success: '%{name} has been blocked',
      error: '%{name} could not be blocked'
    },
    service_status_hidden: {
      success: '%{name} has been markes as inactive',
      error: '%{name} could not be marked as inactive'
    },
    save_arm_circumference: {
      success: 'New monitor size saved.',
      error: 'Could not save monitor size.'
    },
    send_bulk_message: {
      success: 'Message sent.',
      error: 'The message could not be sent.'
    },
    update_authorities: {
      error: 'Could not update authorities.'
    },
    update_hidden_regions: {
      error: 'Could not update disallowed regions.'
    },
    update_hidden_sources: {
      error: 'Could not update disallowed sources.'
    },
    new_vacation: {
      success: 'Vacation saved.',
      error: 'Could not save vacation.'
    },
    delete_vacation: {
      success: 'Vacation removed.',
      error: 'The vacation could not be removed.'
    },
    update_vacation: {
      success: 'End date updated.',
      error: 'Could not update end date.'
    },
    send_bulk_referrals: {
      success: 'Sending of referrals started.',
      error: 'Could not send referrals.'
    },
    save_comment: {
      success: 'Comment saved.',
      error: 'Could not save comment.'
    },
    create_jira_issue: {
      success: 'Issue created',
      error: 'Could not create issue.'
    },
    delete_shipment: {
      error: 'Could not delete the shipment.'
    },
    add_manual_shipment_outgoing: {
      success: 'Monitor shipment created.',
      error: 'Could not create monitor shipment.'
    },
    add_manual_shipment_return: {
      success: 'Return created.',
      error: 'Could not create monitor return.'
    },
    delay_shipment: {
      success: 'The shipment has been postponed.',
      error: 'Could not postpone the shipment.'
    },
    undo_delay_shipment: {
      success: 'The postponement was canceled.',
      error: 'The postponement could not be canceled.'
    },
    update_shipment_delay: {
      success: 'The postponement date was updated.',
      error: 'Could not update postponement date.'
    },
    update_communication: {
      error: 'Could not postpone the communication.'
    },
    delete_communication: {
      error: 'Could not remove the communication.'
    },
    update_canned_response_title: {
      success: 'Title updated to "%{title}"',
      error: 'Could not update title.'
    },
    update_canned_response_body: {
      success: 'Text content for "%{title}" updated.',
      error: 'Could not update the text content for "%{title}"'
    },
    create_canned_response: {
      error: 'Could not create the chat template.'
    },
    delete_canned_response: {
      error: 'Could not delete the chat template.'
    },
    update_canned_response: {
      error: 'Could not update the chat template.'
    },
    member_message: {
      success: 'Message sent.',
      error: 'The message could not be sent. Try again or contact technical support.'
    },
    export_journal: {
      error: 'Could not export journal. Try again or contact technical support.'
    },
    priority_saved: {
      success: 'Priority saved for %{name}.',
      error: 'Could not save the priority setting.'
    },
    priority_removed: {
      success: 'Priority removed for %{name}',
      error: 'Could not remove the priority setting.'
    },
    payment_exemption_added: {
      success: 'Payment exemption card saved.',
      error: 'Could not save the payment exemption card.'
    },
    payment_exemption_updated: {
      success: 'Payment exemption card updated.',
      error: 'Could not update the payment exemption card.'
    },
    payment_exemption_removed: {
      success: 'Payment exemption card removed.',
      error: 'Could not remove the payment exemption card.'
    },
    download_pdf: {
      error: 'Could not download PDF. Try again or contact technical support.'
    },
    download_receipt: {
      error: 'Could not download the receipt. Try again or contact technical support.'
    },
    download_invoice: {
      error: 'Could not download the invoice. Try again or contact technical support.'
    },
    upload_profile_picture: {
      success: "%{name}'s profile image was updated.",
      error: 'Could not upload the image.'
    },
    tag_incoming_patients: {
      success_single: 'The personal number was tagged with "%{source}".',
      success_multiple: '%{numPatients} personal numbers tagged with "%{source}".',
      error: 'Something went wrong when tagging. Try again or contact technical support.'
    },
    get_final_note_breakdown: {
      error: 'Could not fetch final note statistics.'
    },
    get_final_notes_per_type: {
      error: 'Could not fetch final note statistics.'
    }
  },
  menu: {
    user_search: 'Patients',
    support: 'Support center',
    system_users: 'System users',
    reports: 'Reports',
    services: 'Services'
  },
  login_view: {
    start_login: 'Start login',
    remember_me: 'Remember login details',
    start_bank_id: 'Start the app Mobilt BankID',
    bank_id_instruction: 'To log in, start the BankID application on your mobile device.',
    waiting_for_confirmation: 'Waiting for confirmation',
    footer_info: ' • Bredbandet 1, 392 30 Kalmar • 0480-797 960 • support@accumbo.se'
  },
  member_search: {
    input_placeholder: 'Search name, personal number, phone, or email address',
    no_results: 'Could not find a user matching the search term.',
    showing_x_of_y_results: 'Showing %{currentPage} of %{totalCount} results',
    error: 'Error loading users.',
    show_advanced_search: 'Show advanced search options',
    hide_advanced_search: 'Hide advanced search options',
    special_searches: 'Special searches',
    all_regions: 'All regions',
    all_sources: 'All sources',
    all_platforms: 'All platforms',
    all_arm_circumferences: 'All monitor sizes',
    created_date: 'Creation date'
  },
  system_user_search: {
    assignment_priority: {
      give_priority_header: 'Give priority to patient assignment',
      current_patients: 'Current number of patients',
      patients_increase: 'Number or patients to add',
      patients_increase_placeholder: 'For example 200',
      priority_overview_header: 'Overview of assignment priority ',
      priority_start_date: 'Start date',
      patients_at_start: 'Started with',
      priority_limit: 'Priority until',
      assigned: 'Assigned',
      delete_button: 'Remove priority',
      give_priority: 'Give priority',
      has_priority: 'Has priority'
    }
  },
  member_details: {
    error_fetching: 'Could not get user details.',
    error_fetching_region_list: 'Could not fetch region list',
    change_details: 'Change details',
    given_name: 'First name',
    family_name: 'Last name',
    phone_number: 'Phone number',
    email: 'Email address',
    age: 'Age',
    street_address: 'Street address',
    postal_code: 'Postal code',
    city: 'City',
    platform: 'Platform',
    created: 'Created',
    region: 'Region',
    source: 'Source',
    update_error: 'Could not save updated user details. Please try again.',
    unsaved_changes_prompt: 'There are unsaved changes. Leave page anyway?',
    responsible_doctor: 'Responsible doctor',
    responsible_nurse: 'Responsible nurse',
    not_active_yet: 'Not yet active',
    error_fetching_pal_list: 'Could not load list of doctors',
    error_fetching_pas_list: 'Could not load list of nurses',
    confirm_member_block: 'Are you sure you want to block this user?',
    block_member_error: 'Could not block the user.',
    reactivate_member_error: 'Could not reactivate the user.',
    treatment: 'Treatment',
    latest_lab_results: 'Latest lab results',
    testing: 'Testing',
    arm_circumference: 'Monitor size',
    select_monitor_size: 'Choose size',
    general: 'General',
    blocked: 'Blocked/deactivated',
    address_information: 'Address information',
    no_address_information: 'No address information',
    contact_information: 'Contact details',
    no_contact_information: 'No contact details',
    edit_profile: 'Edit profile',
    update_profile_error: 'Could not update profile.',
    save_details: 'Save details',
    biometrics: 'Biometrics',
    insurance_header: 'Insurance information',
    claim_id: 'ClaimID',
    create_issue: 'Create new issue',
    no_issues: 'No support issues',
    issue_single: '1 support issue',
    issue_multiple: '%{numIssues} support issues',
    choose_region: 'Choose a region',
    comment: 'Comment',
    last_measurement: 'Last measurement',
    last_login: 'Last login',
    send_message_to: 'Send message to %{name}',
    send_message: 'Send message',
    message_subject_header: 'Subject',
    message_body_header: 'Message',
    message_discard_alert: 'Discard the message?',
    monitor_sent: 'Monitor sent',
    mark_inactive: 'Mark as inactive',
    inactive_tooltip: "The patient will not show up in their doctor's errand list until they log in again.",
    chat_communication_header: 'Latest chat read',
    empty_chat: 'No chat messages',
    choose_journal_timespan: 'Choose period...',
    timespan_header: 'Timespan',
    journal_timespan: {
      two_weeks: '2 weeks',
      one_month: '1 month',
      ninety_days: '90 days'
    },
    updates: {
      responsible_doctor: {
        success: 'Responsible doctor changed to %{newValue}.',
        error: 'Could not change responsible doctor.'
      },
      responsible_nurse: {
        success: 'Responsible nurse changed to %{newValue}.',
        error: 'Could not change responsible nurse.'
      },
      region: {
        success: 'Region changed to %{newValue}.',
        error: 'Could not change region.'
      }
    },
    actions: {
      create_shipment: 'Send monitor',
      create_return: 'Create return',
      export_journal: 'Export journal',
      show_spar_info: 'Show information from SPAR',
      open_in_clinic: 'Open in Clinic'
    },
    scheduled_communication: {
      header: 'Scheduled communication',
      empty: 'No communication scheduled for this patient.',
      name: 'Subject',
      sends_in: 'Sends in',
      delete: 'Remove',
      confirm_delete: 'Are you sure you want to remove the communication?',
      postpone: 'Postpone 1 week',
      edit: 'Edit',
      error: 'Could not fetch scheduled communication.',
      type: {
        email: 'Email',
        sms: 'SMS',
        push: 'Push',
        jira: 'Jira',
        chat: 'Chat'
      }
    },
    spar: {
      modal_header: 'Information from SPAR',
      error: 'Could not fetch data from SPAR.',
      deregistration_code: {
        A: 'Avliden',
        AV: 'Avliden',
        G: 'Gammalt nummer',
        O: 'Övrig orsak',
        UV: 'Utvandrad',
        GN: 'Gammalt personnummer',
        GS: 'Gammalt samordningsnummer',
        AS: 'Annullering av samordningsnummer',
        OB: 'Försvunnen',
        TA: 'Teknisk avregistrering',
        FI: 'Falsk identitet'
      }
    },
    payment_exemption_header: 'Payment exemption',
    payment_exemption_card_number: 'Card number',
    payment_exemption_card_date: 'Valid until',
    payment_exemption_confirm_removal: "Are you sure you want to remove the patient's payment exemption card?",
    payment_history: {
      header: 'Patient fee history',
      table_headers: {
        visit_type: 'Visit',
        status: 'Status',
        date: 'Date',
        receipt: 'Receipt'
      },
      error: 'Could not fetch patient fee history.',
      no_history: 'The patient has no payment history.',
      download_receipt: 'Download receipt',
      download_invoice: 'Download invoice'
    }
  },
  new_lab_results_view: {
    header: 'Patients with new lab samples',
    search_button: 'Generate',
    action_button: 'Copy to clipboard',
    copied: 'Copied!',
    since_date: 'Since date',
    no_results: 'No patients with new lab samples since',
    error: 'Could not fetch patients with new lab samples.'
  },
  new_members_dispatch_view: {
    header: 'Dispatch to new patients',
    no_results: 'No patients were created, added their arm circumference, or set their region on this date.',
    error: 'Could not get patient list.'
  },
  system_user_details: {
    disallowed_regions: 'Disallowed regions',
    disallowed_sources: 'Disallowed sources',
    no_more_regions: 'No more regions',
    no_more_sources: 'No more sources',
    no_more_authorities: 'No more authorities',
    title: 'Title',
    vacation_planning: {
      header: 'Register vacation',
      secondary_caregivers_header: 'Temps',
      add_caregiver: '+ Add more',
      patients_remaining_header: 'Patients left to distribute',
      patients_remaining: '%{numPatients} patients',
      patients_remaining_singular: '1 patient',
      choose_doctor: 'Choose a doctor',
      choose_nurse: 'Choose a nurse',
      save_vacation: 'Save vacation',
      change_to_date: 'Change end date',
      save_to_date: 'Save end date',
      remove_vacation: 'Remove vacation',
      confirm_removal: 'Are you sure you want to remove the vacation?',
      period_header: 'Period',
      caregiver_list_header: 'Substituting doctors',
      error: {
        region: 'Cannot distribute all patients to the chosen doctor.',
        no_caregiver_selection: 'Please select a doctor.',
        invalid_number: 'Enter a valid number.',
        fetching_doctors: 'Could not fetch doctor list.',
        fetching_vacations: 'Could not fetch active vacations.'
      }
    }
  },
  reports_view: {
    select_page: 'Choose an option in the menu',
    page: {
      invoice_report: 'Invoice data',
      region_skane_referrals: 'Referrals for Region Skåne',
      bulk_message: 'Patient message',
      bulk_referrals: 'Referrals',
      shipments: 'Manage shipments',
      canned_responses: 'Manage chat templates',
      doctor_statistics: 'Caregiver statistics',
      tag_incoming_patients: 'Tag incoming patients'
    },
    invoice_report: {
      header: 'Invoice data',
      month_header: 'Month',
      year_header: 'Year',
      no_data: 'There is no invoice data for the selected month.',
      error: 'Could not download invoice data.'
    },
    referrals: {
      header: 'Generate referrals for new Region Skåne patients',
      modal_header: 'Configure print settings',
      save_settings: 'Save settings',
      new_referral: 'new referral',
      new_referrals: 'new referrals',
      no_new_referrals: 'No new referrals',
      error: 'Could not download referrals.',
      test_error: 'Could not download test referral.',
      main_button: 'Generate referrals',
      rutin: 'Rutin 1 (mm)',
      special: 'Special 3 (mm)',
      x_offset: 'X offset',
      y_offset: 'Y offset',
      test_referral: 'Generate test referral',
      configure: 'Configure',
      desktop_only_note: 'Referral generation is not available in the mobile version.',
      get_error: 'Could not fetch referrals.',
      no_referrals: 'No referrals to manage.'
    },
    bulk_message: {
      header: 'Patient message',
      description:
        'When you write a message, select one or more doctors, and click "Send message" below, a chat message will be sent to all patients who have the selected doctors as their responsible doctor. Note that the message cannot be changed after it has been sent, and that it may take a moment before it has reached all patients.',
      no_more_caregivers: 'No more doctors',
      select_caregivers: 'Select caregiver(s)',
      caregiver_header: 'Responsible doctor',
      message_header: 'Message to patients',
      send_message: 'Send message',
      message_placeholder: 'Type message here',
      confirm: 'Are you sure you want to send this message?'
    },
    bulk_referrals: {
      header: 'Referrals',
      description:
        'Upload a CSV file with personal numbers for those patients who should get referral information from their doctor in the chat. The file should contain one personal number per row. Based on their chosen region, the system will send each patient the correct referral information.',
      send_referrals: 'Send referrals',
      message_header: 'Chat message to the patient',
      message_placeholder: 'Type message here',
      confirm: 'Are you sure you want to send referrals to these patients?',
      file_format_error: 'Incorrectly formatted data in the selected file.',
      select_file: 'Select CSV file'
    },
    shipments: {
      header: 'Choose shipments',
      no_shipments: 'No shipments to manage.',
      no_delayed_shipments: 'No postponed shipments to manage.',
      error: 'Could not fetch shipments.',
      done_button: 'Send to Logistified',
      created_at: 'Created',
      reason: 'Reason',
      reasons: {
        manual: 'Manual',
        batch: 'Batch',
        service_cancelled: 'Service cancelled'
      },
      type: {
        outgoing: 'Outgoing',
        return: 'Return'
      },
      active_shipments: 'Shipments',
      delayed_shipments: 'Postponed shipments',
      delay: 'Postpone',
      delay_header: 'Postpone shipment',
      update_delay: 'Update date',
      update_delay_header: 'Change postponement date',
      current_delay: 'Postponed until %{delayDate}',
      undo_delay: 'Cancel postponement',
      change_delay_date: 'Change date',
      address_missing: 'Address missing'
    },
    canned_responses: {
      header: 'Manage chat templates',
      invalid_tag: 'The text contains an invalid tag. Check the tags and try again.',
      start_editing: 'Edit templates',
      cancel_editing: 'Cancel',
      save: 'Save changes',
      add_node: '+ Response',
      add_sub_category: '+ Subcategory',
      add_main_category: '+ Main category',
      edit_text: 'Edit text',
      tags_header: 'Available tags',
      confirm_cancel: 'There are unsaved changes. Discard changes?',
      expand_all: 'Expand all',
      collapse_all: 'Collapse all'
    },
    doctor_statistics: {
      header: 'Doctor statistics',
      final_notes_header: 'Final notes',
      final_notes_per_type_header: 'Final notes per type',
      choose_year: 'Select a year',
      choose_doctor: 'Select doctor',
      doctor_table_header: 'Doctor',
      nurse_table_header: 'Sjuksköterska',
      patient_totals_header: 'Assigned patients',
      total: 'Total',
      active: 'Active',
      hidden: 'Inactive',
      blocked: 'Blocked',
      statistics_error: 'Could not fetch final note statistics.',
      patient_totals_error: 'Could not fetch patient totals.',
      final_note_type_breakdown_header: 'Invoicing data',
      quarter: 'Quarter (%{monthRange})',
      final_notes_for_month: 'Final notes during %{period}',
      final_notes_for_quarter: 'Final notes during quarter (%{period})',
      show_details: 'Show details',
      download_pdf: 'Download PDF',
      final_note_remuneration: 'Remuneration per final note, inc. VAT',
      total_excl_vat: 'Total excl. VAT',
      total_incl_vat: 'Total incl. VAT',
      monthly_billing: 'Month',
      quarterly_billing: 'Quarter',
      billing_period: 'Billing period',
      caregiver_type: 'Caregiver type'
    },
    tag_incoming_patients: {
      header: 'Tag incoming patients',
      description:
        'Enter the personal number for a patient who should be tagged as a Min Doktor patient upon registration. If you enter a phone number we will send an sms with a download link to it.',
      add_more: '+ Add more'
    }
  },
  jira: {
    select_issue_type: 'Select issue type',
    modal_header: 'Create support issue',
    modal_button: 'Create issue',
    history_header: 'Previous issues for this patient',
    history_error: 'Could not fetch support history.',
    history_empty: 'No previous support issues.',
    history_column: {
      issue: 'Issue',
      created: 'Created',
      status: 'Status'
    },
    description: 'Description'
  },
  profile_image_upload: {
    header: 'Update profile picture',
    header_new: 'Select profile picture',
    action: 'Upload image',
    select_file: 'Select file',
    drag_here: 'or drag an image here'
  },
  authorities: {
    healthscientist: 'Health scientist',
    caregiver: 'Doctor',
    nurse: 'Nurse',
    login_clinic: 'Clinic login',
    login_admin: 'Admin login',
    manage_patients: 'Manage patients',
    manage_users: 'Manage system users',
    manage_content: 'Manage content',
    send_communications: 'Send communications',
    manage_billing: 'Manage billing',
    read_invoice_report: 'Create Privera file',
    view_patients: 'View patients',
    export_journal: 'Export journal data'
  },
  authority_description: {
    healthscientist: 'All health scientists in the system must have this.',
    caregiver: 'All doctors in the system must have this.',
    nurse: 'All nurses in the system must have this.',
    login_clinic: 'Required to log in to Clinic.',
    login_admin: 'Required to log in to Admin.',
    manage_patients: 'Required to change patient information (email address, phone number, etc.).',
    manage_users:
      "Required to administrate system users, and change their information (e.g. authorities or vacations) as well as to change a patient's PAL.",
    manage_content: 'Required to make changes to the chat templates.',
    send_communications:
      'Required to send mass communications from Admin (bulk chat messages and referrals), as well as to send email to individual patients.',
    manage_billing:
      'Required to see statistics for created final notes and assigned patients. Will be used to create invoice reports for doctors in Admin.',
    read_invoice_report: 'Required to create the Privera file (invoice data for Region Sörmland).',
    view_patients: 'Required to view any patient data at all.',
    export_journal: 'Required to download a patient journal as PDF.'
  },
  error_boundary: {
    header: 'Something went wrong :(',
    summary: 'Information about the error has been passed along to the development team.'
  },
  server_error: {
    db_access_error: 'Database access error',
    internal: 'Internal server error',
    unknown_user: 'Unknown user',
    insufficient_privileges: 'Insufficient privileges',
    authentication_error: 'Authentication error',
    user_canceled_login: 'User canceled login',
    login_already_in_process: 'Login already in progress',
    external_service_timeout: 'External service timeout',
    not_implemented: 'Not implemented',
    invalid_data_posted: 'Invalid data was posted',
    missing_data: 'Missing data',
    id_already_exists: 'ID already exists',
    fallback: 'Unknown error - contact technical support',
    connectivity: 'Could not reach backend server',
    unauthorized: 'Unauthorized'
  }
};

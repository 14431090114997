import React from 'react';
import { Switch, NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import PrivateRoute from '../PrivateRoute';
import { APPLICATION_BASE_PATH } from '../../constants';
import InvoiceReport from '../InvoiceReport';
import BulkMessageView from '../BulkMessageView';
import BulkReferralsView from '../BulkReferralsView';
import MonitorShipmentsView from '../MonitorShipmentsView';
import ReferralsManagementView from '../ReferralsManagementView';
import ManageCannedResponsesView from '../ManageCannedResponsesView';
import DoctorStatisticsView from '../DoctorStatisticsView';
import TagIncomingPatientsView from '../TagIncomingPatientsView';
import './ServicesView.scss';

const ServicesView = ({ authorities, authority }) => {
  return (
    <div className="services">
      <div className="columns">
        <div className="column is-3 no-padding sub-menu-wrapper">
          <div className="sub-menu">
            <NavLink
              disabled={!authorities.includes(authority.readInvoiceReport)}
              to={`/${APPLICATION_BASE_PATH}/services/invoices`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.invoice_report" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.managePatients)}
              to={`/${APPLICATION_BASE_PATH}/services/manage-referrals`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.region_skane_referrals" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.sendCommunications)}
              to={`/${APPLICATION_BASE_PATH}/services/bulkmessage`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.bulk_message" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.sendCommunications)}
              to={`/${APPLICATION_BASE_PATH}/services/bulkreferrals`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.bulk_referrals" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.managePatients)}
              to={`/${APPLICATION_BASE_PATH}/services/monitor-shipments`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.shipments" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.manageContent)}
              to={`/${APPLICATION_BASE_PATH}/services/chat-templates`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.canned_responses" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.manageBilling)}
              to={`/${APPLICATION_BASE_PATH}/services/doctor-statistics`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.doctor_statistics" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.managePatients)}
              to={`/${APPLICATION_BASE_PATH}/services/tag-incoming-patients`}
              activeClassName="is-active"
            >
              <Translate value="reports_view.page.tag_incoming_patients" />
            </NavLink>
          </div>
        </div>
        <div className="column is-9 no-padding">
          <Switch>
            <PrivateRoute exact path={`/${APPLICATION_BASE_PATH}/services`} component={NoSelection} />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/invoices`}
              component={InvoiceReport}
              requiredAuthority={authority.readInvoiceReport}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/manage-referrals`}
              component={ReferralsManagementView}
              requiredAuthority={authority.managePatients}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/bulkmessage`}
              component={BulkMessageView}
              requiredAuthority={authority.sendCommunications}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/bulkreferrals`}
              component={BulkReferralsView}
              requiredAuthority={authority.sendCommunications}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/monitor-shipments`}
              component={MonitorShipmentsView}
              requiredAuthority={authority.managePatients}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/chat-templates`}
              component={ManageCannedResponsesView}
              requiredAuthority={authority.manageContent}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/doctor-statistics`}
              component={DoctorStatisticsView}
              requiredAuthority={authority.manageBilling}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/services/tag-incoming-patients`}
              component={TagIncomingPatientsView}
              requiredAuthority={authority.loginAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes
  };
};

export default connect(mapStateToProps)(ServicesView);

const NoSelection = () => {
  return (
    <div>
      <div className="no-selection">
        <Translate value="reports_view.select_page" />
      </div>
      <div className="hint-arrow"></div>
    </div>
  );
};

/**
 * Authentication actions
 */

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const LOGOUT = 'LOGOUT';

/**
 * Theme actions
 */

export const SWITCH_THEME = 'SWITCH_THEME';
export const TOGGLE_NIGHT_MODE = 'TOGGLE_NIGHT_MODE';

/**
 * Search actions
 */

export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR';
export const SET_CURRENT_SEARCH_QUERY = 'SET_CURRENT_SEARCH_QUERY';
export const GET_MEMBERS_WITH_NEW_LAB_RESULTS_REQUEST = 'GET_MEMBERS_WITH_NEW_LAB_RESULTS_REQUEST';
export const GET_MEMBERS_WITH_NEW_LAB_RESULTS_SUCCESS = 'GET_MEMBERS_WITH_NEW_LAB_RESULTS_SUCCESS';
export const GET_MEMBERS_WITH_NEW_LAB_RESULTS_ERROR = 'GET_MEMBERS_WITH_NEW_LAB_RESULTS_ERROR';
export const GET_SYSTEM_USERS_REQUEST = 'GET_SYSTEM_USERS_REQUEST';
export const GET_SYSTEM_USERS_SUCCESS = 'GET_SYSTEM_USERS_SUCCESS';
export const GET_SYSTEM_USERS_ERROR = 'GET_SYSTEM_USERS_ERROR';
export const SET_CURRENT_SYSTEM_USER_SEARCH_QUERY = 'SET_CURRENT_SYSTEM_USER_SEARCH_QUERY';
export const GET_NEW_MEMBERS_DISPATCH_REQUEST = 'GET_NEW_MEMBERS_DISPATCH_REQUEST';
export const GET_NEW_MEMBERS_DISPATCH_SUCCESS = 'GET_NEW_MEMBERS_DISPATCH_SUCCESS';
export const GET_NEW_MEMBERS_DISPATCH_ERROR = 'GET_NEW_MEMBERS_DISPATCH_ERROR';

/**
 * Member actions
 */

export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_ERROR = 'GET_MEMBER_ERROR';
export const CLEAR_CURRENT_MEMBER = 'CLEAR_CURRENT_MEMBER';
export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_ERROR = 'UPDATE_MEMBER_ERROR';
export const GET_MEMBER_DEVICES_REQUEST = 'GET_MEMBER_DEVICES_REQUEST';
export const GET_MEMBER_DEVICES_SUCCESS = 'GET_MEMBER_DEVICES_SUCCESS';
export const GET_MEMBER_DEVICES_ERROR = 'GET_MEMBER_DEVICES_ERROR';
export const TOGGLE_EDITING_MEMBER_DETAILS = 'TOGGLE_EDITING_MEMBER_DETAILS';
export const SET_ADDITIONAL_SEARCH_CRITERIA = 'SET_ADDITIONAL_SEARCH_CRITERIA';
export const TOGGLE_ADVANCED_SEARCH = 'TOGGLE_ADVANCED_SEARCH';
export const SET_MEMBER_SERVICE_STATUS_REQUEST = 'SET_MEMBER_SERVICE_STATUS_REQUEST';
export const SET_MEMBER_SERVICE_STATUS_SUCCESS = 'SET_MEMBER_SERVICE_STATUS_SUCCESS';
export const SET_MEMBER_SERVICE_STATUS_ERROR = 'SET_MEMBER_SERVICE_STATUS_ERROR';
export const UPDATE_MEMBER_ANAMNESIS_ANSWER_REQUEST = 'UPDATE_MEMBER_ANAMNESIS_ANSWER_REQUEST';
export const UPDATE_MEMBER_ANAMNESIS_ANSWER_SUCCESS = 'UPDATE_MEMBER_ANAMNESIS_ANSWER_SUCCESS';
export const UPDATE_MEMBER_ANAMNESIS_ANSWER_ERROR = 'UPDATE_MEMBER_ANAMNESIS_ANSWER_ERROR';
export const GET_MEMBER_COMMENT_REQUEST = 'GET_MEMBER_COMMENT_REQUEST';
export const GET_MEMBER_COMMENT_SUCCESS = 'GET_MEMBER_COMMENT_SUCCESS';
export const GET_MEMBER_COMMENT_ERROR = 'GET_MEMBER_COMMENT_ERROR';
export const UPDATE_MEMBER_COMMENT_REQUEST = 'UPDATE_MEMBER_COMMENT_REQUEST';
export const UPDATE_MEMBER_COMMENT_SUCCESS = 'UPDATE_MEMBER_COMMENT_SUCCESS';
export const UPDATE_MEMBER_COMMENT_ERROR = 'UPDATE_MEMBER_COMMENT_ERROR';
export const UPDATE_MEMBER_COMMENT_TEXT = 'UPDATE_MEMBER_COMMENT_TEXT';
export const GET_MEMBER_PROFILE_IMAGE_REQUEST = 'GET_MEMBER_PROFILE_IMAGE_REQUEST';
export const GET_MEMBER_PROFILE_IMAGE_SUCCESS = 'GET_MEMBER_PROFILE_IMAGE_SUCCESS';
export const GET_MEMBER_PROFILE_IMAGE_ERROR = 'GET_MEMBER_PROFILE_IMAGE_ERROR';
export const GET_SCHEDULED_COMMUNICATION_REQUEST = 'GET_SCHEDULED_COMMUNICATION_REQUEST';
export const GET_SCHEDULED_COMMUNICATION_SUCCESS = 'GET_SCHEDULED_COMMUNICATION_SUCCESS';
export const GET_SCHEDULED_COMMUNICATION_ERROR = 'GET_SCHEDULED_COMMUNICATION_ERROR';
export const UPDATE_SCHEDULED_COMMUNICATION_REQUEST = 'UPDATE_SCHEDULED_COMMUNICATION_REQUEST';
export const UPDATE_SCHEDULED_COMMUNICATION_SUCCESS = 'UPDATE_SCHEDULED_COMMUNICATION_SUCCESS';
export const UPDATE_SCHEDULED_COMMUNICATION_ERROR = 'UPDATE_SCHEDULED_COMMUNICATION_ERROR';
export const DELETE_SCHEDULED_COMMUNICATION_REQUEST = 'DELETE_SCHEDULED_COMMUNICATION_REQUEST';
export const DELETE_SCHEDULED_COMMUNICATION_SUCCESS = 'DELETE_SCHEDULED_COMMUNICATION_SUCCESS';
export const DELETE_SCHEDULED_COMMUNICATION_ERROR = 'DELETE_SCHEDULED_COMMUNICATION_ERROR';
export const SHOW_MESSAGE_MODAL = 'SHOW_MESSAGE_MODAL';
export const HIDE_MESSAGE_MODAL = 'HIDE_MESSAGE_MODAL';
export const UPDATE_MESSAGE_SUBJECT = 'UPDATE_MESSAGE_SUBJECT';
export const UPDATE_MESSAGE_BODY = 'UPDATE_MESSAGE_BODY';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';
export const GET_MEMBER_JOURNAL_REQUEST = 'GET_MEMBER_JOURNAL_REQUEST';
export const GET_MEMBER_JOURNAL_SUCCESS = 'GET_MEMBER_JOURNAL_SUCCESS';
export const GET_MEMBER_JOURNAL_ERROR = 'GET_MEMBER_JOURNAL_ERROR';
export const TOGGLE_EXPORT_JOURNAL_MODAL = 'TOGGLE_EXPORT_JOURNAL_MODAL';
export const GET_MEMBER_CHAT_MESSAGES_REQUEST = 'GET_MEMBER_CHAT_MESSAGES_REQUEST';
export const GET_MEMBER_CHAT_MESSAGES_SUCCESS = 'GET_MEMBER_CHAT_MESSAGES_SUCCESS';
export const GET_MEMBER_CHAT_MESSAGES_ERROR = 'GET_MEMBER_CHAT_MESSAGES_ERROR';
export const GET_MEMBER_SPAR_DATA_REQUEST = 'GET_MEMBER_SPAR_DATA_REQUEST';
export const GET_MEMBER_SPAR_DATA_SUCCESS = 'GET_MEMBER_SPAR_DATA_SUCCESS';
export const GET_MEMBER_SPAR_DATA_ERROR = 'GET_MEMBER_SPAR_DATA_ERROR';
export const TOGGLE_SPAR_MODAL = 'TOGGLE_SPAR_MODAL';
export const UPDATE_PAYMENT_EXEMPTION_REQUEST = 'UPDATE_PAYMENT_EXEMPTION_REQUEST';
export const UPDATE_PAYMENT_EXEMPTION_SUCCESS = 'UPDATE_PAYMENT_EXEMPTION_SUCCESS';
export const UPDATE_PAYMENT_EXEMPTION_ERROR = 'UPDATE_PAYMENT_EXEMPTION_ERROR';
export const UPDATE_PAYMENT_EXEMPTION_PROPERTY = 'UPDATE_PAYMENT_EXEMPTION_PROPERTY';
export const POST_PAYMENT_EXEMPTION_REQUEST = 'POST_PAYMENT_EXEMPTION_REQUEST';
export const POST_PAYMENT_EXEMPTION_SUCCESS = 'POST_PAYMENT_EXEMPTION_SUCCESS';
export const POST_PAYMENT_EXEMPTION_ERROR = 'POST_PAYMENT_EXEMPTION_ERROR';
export const DELETE_PAYMENT_EXEMPTION_REQUEST = 'DELETE_PAYMENT_EXEMPTION_REQUEST';
export const DELETE_PAYMENT_EXEMPTION_SUCCESS = 'DELETE_PAYMENT_EXEMPTION_SUCCESS';
export const DELETE_PAYMENT_EXEMPTION_ERROR = 'DELETE_PAYMENT_EXEMPTION_ERROR';
export const GET_PAYMENT_HISTORY_REQUEST = 'GET_PAYMENT_HISTORY_REQUEST';
export const GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS';
export const GET_PAYMENT_HISTORY_ERROR = 'GET_PAYMENT_HISTORY_ERROR';
export const ASSIGN_CAREGIVER_REQUEST = 'ASSIGN_CAREGIVER_REQUEST';
export const ASSIGN_CAREGIVER_SUCCESS = 'ASSIGN_CAREGIVER_SUCCESS';
export const ASSIGN_CAREGIVER_ERROR = 'ASSIGN_CAREGIVER_ERROR';

/**
 * System user actions
 */

export const GET_SYSTEM_USER_REQUEST = 'GET_SYSTEM_USER_REQUEST';
export const GET_SYSTEM_USER_SUCCESS = 'GET_SYSTEM_USER_SUCCESS';
export const GET_SYSTEM_USER_ERROR = 'GET_SYSTEM_USER_ERROR';
export const CLEAR_CURRENT_SYSTEM_USER = 'CLEAR_CURRENT_SYSTEM_USER';
export const UPDATE_SYSTEM_USER_REQUEST = 'UPDATE_SYSTEM_USER_REQUEST';
export const UPDATE_SYSTEM_USER_SUCCESS = 'UPDATE_SYSTEM_USER_SUCCESS';
export const UPDATE_SYSTEM_USER_ERROR = 'UPDATE_SYSTEM_USER_ERROR';
export const GET_ASSIGNABLE_DOCTORS_REQUEST = 'GET_ASSIGNABLE_DOCTORS_REQUEST';
export const GET_ASSIGNABLE_DOCTORS_SUCCESS = 'GET_ASSIGNABLE_DOCTORS_SUCCESS';
export const GET_ASSIGNABLE_DOCTORS_ERROR = 'GET_ASSIGNABLE_DOCTORS_ERROR';
export const TOGGLE_HIDDEN_REGION = 'TOGGLE_HIDDEN_REGION';
export const CLEAR_HIDDEN_REGIONS = 'CLEAR_HIDDEN_REGIONS';
export const TOGGLE_AUTHORITY = 'TOGGLE_AUTHORITY';
export const CLEAR_AUTHORITIES = 'CLEAR_AUTHORITIES';
export const TOGGLE_HIDDEN_SOURCE = 'TOGGLE_HIDDEN_SOURCE';
export const CLEAR_HIDDEN_SOURCES = 'CLEAR_HIDDEN_SOURCES';
export const UPDATE_AUTHORITIES_REQUEST = 'UPDATE_AUTHORITIES_REQUEST';
export const UPDATE_AUTHORITIES_SUCCESS = 'UPDATE_AUTHORITIES_SUCCESS';
export const UPDATE_AUTHORITIES_ERROR = 'UPDATE_AUTHORITIES_ERROR';
export const UPDATE_HIDDEN_SOURCES_REQUEST = 'UPDATE_HIDDEN_SOURCES_REQUEST';
export const UPDATE_HIDDEN_SOURCES_SUCCESS = 'UPDATE_HIDDEN_SOURCES_SUCCESS';
export const UPDATE_HIDDEN_SOURCES_ERROR = 'UPDATE_HIDDEN_SOURCES_ERROR';
export const UPDATE_HIDDEN_REGIONS_REQUEST = 'UPDATE_HIDDEN_REGIONS_REQUEST';
export const UPDATE_HIDDEN_REGIONS_SUCCESS = 'UPDATE_HIDDEN_REGIONS_SUCCESS';
export const UPDATE_HIDDEN_REGIONS_ERROR = 'UPDATE_HIDDEN_REGIONS_ERROR';
export const POST_VACATION_REQUEST = 'POST_VACATION_REQUEST';
export const POST_VACATION_SUCCESS = 'POST_VACATION_SUCCESS';
export const POST_VACATION_ERROR = 'POST_VACATION_ERROR';
export const DELETE_VACATION_REQUEST = 'DELETE_VACATION_REQUEST';
export const DELETE_VACATION_SUCCESS = 'DELETE_VACATION_SUCCESS';
export const DELETE_VACATION_ERROR = 'DELETE_VACATION_ERROR';
export const UPDATE_VACATION_REQUEST = 'UPDATE_VACATION_REQUEST';
export const UPDATE_VACATION_SUCCESS = 'UPDATE_VACATION_SUCCESS';
export const UPDATE_VACATION_ERROR = 'UPDATE_VACATION_ERROR';
export const GET_VACATIONS_REQUEST = 'GET_VACATIONS_REQUEST';
export const GET_VACATIONS_SUCCESS = 'GET_VACATIONS_SUCCESS';
export const GET_VACATIONS_ERROR = 'GET_VACATIONS_ERROR';
export const SHOW_VACATION_DATE_CHANGE_MODAL = 'SHOW_VACATION_DATE_CHANGE_MODAL';
export const HIDE_VACATION_DATE_CHANGE_MODAL = 'HIDE_VACATION_DATE_CHANGE_MODAL';
export const SET_ASSIGNMENT_EDIT_USER = 'SET_ASSIGNMENT_EDIT_USER';
export const ADD_ASSIGNMENT_PRIORITY = 'ADD_ASSIGNMENT_PRIORITY';
export const REMOVE_ASSIGNMENT_PRIORITY = 'REMOVE_ASSIGNMENT_PRIORITY';
export const GET_SYSTEM_USER_PROFILE_IMAGE_REQUEST = 'GET_SYSTEM_USER_PROFILE_IMAGE_REQUEST';
export const GET_SYSTEM_USER_PROFILE_IMAGE_SUCCESS = 'GET_SYSTEM_USER_PROFILE_IMAGE_SUCCESS';
export const GET_SYSTEM_USER_PROFILE_IMAGE_ERROR = 'GET_SYSTEM_USER_PROFILE_IMAGE_ERROR';

/**
 * Shared data actions
 */

export const GET_REGIONS_REQUEST = 'GET_REGIONS_REQUEST';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR';
export const GET_AUTHORITIES = 'GET_AUTHORITIES';

/**
 * Report actions
 */

export const GENERATE_INVOICE_REPORT_REQUEST = 'GENERATE_INVOICE_REPORT_REQUEST';
export const GENERATE_INVOICE_REPORT_SUCCESS = 'GENERATE_INVOICE_REPORT_SUCCESS';
export const GENERATE_INVOICE_REPORT_ERROR = 'GENERATE_INVOICE_REPORT_ERROR';
export const SET_INVOICE_REPORT_MONTH = 'SET_INVOICE_REPORT_MONTH';
export const SET_INVOICE_REPORT_YEAR = 'SET_INVOICE_REPORT_YEAR';
export const SHOW_OFFSET_SETTINGS_MODAL = 'SHOW_OFFSET_SETTINGS_MODAL';
export const HIDE_OFFSET_SETTINGS_MODAL = 'HIDE_OFFSET_SETTINGS_MODAL';
export const GET_REFERRAL_PDFS_REQUEST = 'GET_REFERRAL_PDFS_REQUEST';
export const GET_REFERRAL_PDFS_SUCCESS = 'GET_REFERRAL_PDFS_SUCCESS';
export const GET_REFERRAL_PDFS_ERROR = 'GET_REFERRAL_PDFS_ERROR';
export const GET_REFERRAL_TEST_PDFS_REQUEST = 'GET_REFERRAL_TEST_PDFS_REQUEST';
export const GET_REFERRAL_TEST_PDFS_SUCCESS = 'GET_REFERRAL_TEST_PDFS_SUCCESS';
export const GET_REFERRAL_TEST_PDFS_ERROR = 'GET_REFERRAL_TEST_PDFS_ERROR';
export const SET_REFERRAL_PRINT_OFFSET = 'SET_REFERRAL_PRINT_OFFSET';
export const GET_CAREGIVERS_REQUEST = 'GET_CAREGIVERS_REQUEST';
export const GET_CAREGIVERS_SUCCESS = 'GET_CAREGIVERS_SUCCESS';
export const GET_CAREGIVERS_ERROR = 'GET_CAREGIVERS_ERROR';
export const GET_NURSES_REQUEST = 'GET_NURSES_REQUEST';
export const GET_NURSES_SUCCESS = 'GET_NURSES_SUCCESS';
export const GET_NURSES_ERROR = 'GET_NURSES_ERROR';
export const GET_CAREGIVER_STATISTICS_REQUEST = 'GET_CAREGIVER_STATISTICS_REQUEST';
export const GET_CAREGIVER_STATISTICS_SUCCESS = 'GET_CAREGIVER_STATISTICS_SUCCESS';
export const GET_CAREGIVER_STATISTICS_ERROR = 'GET_CAREGIVER_STATISTICS_ERROR';
export const GET_DOCTOR_PATIENT_TOTALS_REQUEST = 'GET_DOCTOR_PATIENT_TOTALS_REQUEST';
export const GET_DOCTOR_PATIENT_TOTALS_SUCCESS = 'GET_DOCTOR_PATIENT_TOTALS_SUCCESS';
export const GET_DOCTOR_PATIENT_TOTALS_ERROR = 'GET_DOCTOR_PATIENT_TOTALS_ERROR';
export const GET_ALL_DOCTORS_REQUEST = 'GET_ALL_DOCTORS_REQUEST';
export const GET_ALL_DOCTORS_SUCCESS = 'GET_ALL_DOCTORS_SUCCESS';
export const GET_ALL_DOCTORS_ERROR = 'GET_ALL_DOCTORS_ERROR';
export const GET_DOCTOR_FINAL_NOTE_BREAKDOWN_REQUEST = 'GET_DOCTOR_FINAL_NOTE_BREAKDOWN_REQUEST';
export const GET_DOCTOR_FINAL_NOTE_BREAKDOWN_SUCCESS = 'GET_DOCTOR_FINAL_NOTE_BREAKDOWN_SUCCESS';
export const GET_DOCTOR_FINAL_NOTE_BREAKDOWN_ERROR = 'GET_DOCTOR_FINAL_NOTE_BREAKDOWN_ERROR';
export const CLEAR_DOCTOR_FINAL_NOTE_BREAKDOWN = 'CLEAR_DOCTOR_FINAL_NOTE_BREAKDOWN';
export const GET_REMUNERATION_SCHEME_REQUEST = 'GET_REMUNERATION_SCHEME_REQUEST';
export const GET_REMUNERATION_SCHEME_SUCCESS = 'GET_REMUNERATION_SCHEME_SUCCESS';
export const GET_REMUNERATION_SCHEME_ERROR = 'GET_REMUNERATION_SCHEME_ERROR';
export const GET_FINAL_NOTES_PER_TYPE_REQUEST = 'GET_FINAL_NOTES_PER_TYPE_REQUEST';
export const GET_FINAL_NOTES_PER_TYPE_SUCCESS = 'GET_FINAL_NOTES_PER_TYPE_SUCCESS';
export const GET_FINAL_NOTES_PER_TYPE_ERROR = 'GET_FINAL_NOTES_PER_TYPE_ERROR';

/**
 * Notification actions
 */

export const SHOW_NOTIFICATION_MESSAGE = 'SHOW_NOTIFICATION_MESSAGE';
export const HIDE_NOTIFICATION_MESSAGE = 'HIDE_NOTIFICATION_MESSAGE';
export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE';

/**
 * Jira actions
 */

export const GET_MEMBER_JIRA_ISSUES_REQUEST = 'GET_MEMBER_JIRA_ISSUES_REQUEST';
export const GET_MEMBER_JIRA_ISSUES_SUCCESS = 'GET_MEMBER_JIRA_ISSUES_SUCCESS';
export const GET_MEMBER_JIRA_ISSUES_ERROR = 'GET_MEMBER_JIRA_ISSUES_ERROR';
export const POST_MEMBER_JIRA_ISSUE_REQUEST = 'POST_MEMBER_JIRA_ISSUE_REQUEST';
export const POST_MEMBER_JIRA_ISSUE_SUCCESS = 'POST_MEMBER_JIRA_ISSUE_SUCCESS';
export const POST_MEMBER_JIRA_ISSUE_ERROR = 'POST_MEMBER_JIRA_ISSUE_ERROR';
export const SHOW_JIRA_MODAL = 'SHOW_JIRA_MODAL';
export const HIDE_JIRA_MODAL = 'HIDE_JIRA_MODAL';
export const SHOW_JIRA_HISTORY_MODAL = 'SHOW_JIRA_HISTORY_MODAL';
export const HIDE_JIRA_HISTORY_MODAL = 'HIDE_JIRA_HISTORY_MODAL';
export const SET_JIRA_ISSUE_TYPE = 'SET_JIRA_ISSUE_TYPE';
export const SET_JIRA_ISSUE_DESCRIPTION = 'SET_JIRA_ISSUE_DESCRIPTION';
export const GET_JIRA_ISSUE_TYPES_REQUEST = 'GET_JIRA_ISSUE_TYPES_REQUEST';
export const GET_JIRA_ISSUE_TYPES_SUCCESS = 'GET_JIRA_ISSUE_TYPES_SUCCESS';
export const GET_JIRA_ISSUE_TYPES_ERROR = 'GET_JIRA_ISSUE_TYPES_ERROR';

/**
 * Services actions
 */

export const SEND_BULK_MESSAGE_REQUEST = 'SEND_BULK_MESSAGE_REQUEST';
export const SEND_BULK_MESSAGE_SUCCESS = 'SEND_BULK_MESSAGE_SUCCESS';
export const SEND_BULK_MESSAGE_ERROR = 'SEND_BULK_MESSAGE_ERROR';
export const SEND_BULK_REFERRALS_REQUEST = 'SEND_BULK_REFERRALS_REQUEST';
export const SEND_BULK_REFERRALS_SUCCESS = 'SEND_BULK_REFERRALS_SUCCESS';
export const SEND_BULK_REFERRALS_ERROR = 'SEND_BULK_REFERRALS_ERROR';
export const GET_SHIPMENT_ITEMS_REQUEST = 'GET_SHIPMENT_ITEMS_REQUEST';
export const GET_SHIPMENT_ITEMS_SUCCESS = 'GET_SHIPMENT_ITEMS_SUCCESS';
export const GET_SHIPMENT_ITEMS_ERROR = 'GET_SHIPMENT_ITEMS_ERROR';
export const TOGGLE_SHIPMENT_ITEM = 'TOGGLE_SHIPMENT_ITEM';
export const SCHEDULE_SHIPMENTS_REQUEST = 'SCHEDULE_SHIPMENTS_REQUEST';
export const SCHEDULE_SHIPMENTS_SUCCESS = 'SCHEDULE_SHIPMENTS_SUCCESS';
export const SCHEDULE_SHIPMENTS_ERROR = 'SCHEDULE_SHIPMENTS_ERROR';
export const ADD_MANUAL_SHIPMENT_REQUEST = 'ADD_MANUAL_SHIPMENT_REQUEST';
export const ADD_MANUAL_SHIPMENT_SUCCESS = 'ADD_MANUAL_SHIPMENT_SUCCESS';
export const ADD_MANUAL_SHIPMENT_ERROR = 'ADD_MANUAL_SHIPMENT_ERROR';
export const DELETE_SHIPMENT_REQUEST = 'DELETE_SHIPMENT_REQUEST';
export const DELETE_SHIPMENT_SUCCESS = 'DELETE_SHIPMENT_SUCCESS';
export const DELETE_SHIPMENT_ERROR = 'DELETE_SHIPMENT_ERROR';
export const GET_REFERRALS_REQUEST = 'GET_REFERRALS_REQUEST';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_ERROR = 'GET_REFERRALS_ERROR';
export const TOGGLE_REFERRAL_ITEM = 'TOGGLE_REFERRAL_ITEM';
export const TOGGLE_ALL_REFERRAL_ITEMS = 'TOGGLE_ALL_REFERRAL_ITEMS';
export const GET_CANNED_RESPONSES_REQUEST = 'GET_CANNED_RESPONSES_REQUEST';
export const GET_CANNED_RESPONSES_SUCCESS = 'GET_CANNED_RESPONSES_SUCCESS';
export const GET_CANNED_RESPONSES_ERROR = 'GET_CANNED_RESPONSES_ERROR';
export const GET_TEMPLATE_TAGS_REQUEST = 'GET_TEMPLATE_TAGS_REQUEST';
export const GET_TEMPLATE_TAGS_SUCCESS = 'GET_TEMPLATE_TAGS_SUCCESS';
export const GET_TEMPLATE_TAGS_ERROR = 'GET_TEMPLATE_TAGS_ERROR';
export const TOGGLE_ALL_SHIPMENTS_SELECTED = 'TOGGLE_ALL_SHIPMENTS_SELECTED';
export const SET_SELECT_DELAY_DATE_MODAL_VISIBILITY = 'SET_SELECT_DELAY_DATE_MODAL_VISIBILITY';
export const UPDATE_DELAY_DATE_MODAL_VISIBILITY = 'UPDATE_DELAY_DATE_MODAL_VISIBILITY';
export const DELAY_SHIPMENT_REQUEST = 'DELAY_SHIPMENT_REQUEST';
export const DELAY_SHIPMENT_SUCCESS = 'DELAY_SHIPMENT_SUCCESS';
export const DELAY_SHIPMENT_ERROR = 'DELAY_SHIPMENT_ERROR';
export const UNDO_DELAY_REQUEST = 'UNDO_DELAY_REQUEST';
export const UNDO_DELAY_SUCCESS = 'UNDO_DELAY_SUCCESS';
export const UNDO_DELAY_ERROR = 'UNDO_DELAY_ERROR';
export const UPDATE_DELAY_DATE_REQUEST = 'UPDATE_DELAY_DATE_REQUEST';
export const UPDATE_DELAY_DATE_SUCCESS = 'UPDATE_DELAY_DATE_SUCCESS';
export const UPDATE_DELAY_DATE_ERROR = 'UPDATE_DELAY_DATE_ERROR';

/**
 * User actions
 */

export const GET_PROFILE_IMAGE_REQUEST = 'GET_PROFILE_IMAGE_REQUEST';
export const GET_PROFILE_IMAGE_SUCCESS = 'GET_PROFILE_IMAGE_SUCCESS';
export const GET_PROFILE_IMAGE_ERROR = 'GET_PROFILE_IMAGE_ERROR';

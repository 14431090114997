import React from 'react';
import Select from '../Select';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import DropdownIndicator from '../DropdownIndicator';
import LoaderButton from '../LoaderButton';
import './SaveableMultiSelect.scss';

const SaveableMultiSelect = ({
  name,
  placeholderI18nKey,
  defaultValue,
  currentValue,
  noOptionsI18nKey,
  onChange,
  onSave,
  options,
  isSaving,
  actionI18nKey,
  requiredAuthority,
  authorities,
  fallbackKey
}) => {
  const wrappedOnChange = (value, event) => {
    onChange(value, event);
  };

  const savable = () => {
    return (
      (defaultValue && defaultValue.length !== currentValue.length) ||
      !defaultValue.map((val) => val.value).every((val) => currentValue.includes(val))
    );
  };

  if (requiredAuthority && authorities.indexOf(requiredAuthority) === -1) {
    if (currentValue.length) {
      if (fallbackKey) {
        return options
          .filter((option) => currentValue.includes(option.value))
          .map((option) => option[fallbackKey])
          .join(', ');
      }
      return currentValue.join(', ');
    }

    return '-';
  }

  return (
    <div className="saveable-multi-select">
      <Select
        isMulti
        name={name}
        placeholder={I18n.t(placeholderI18nKey)}
        defaultValue={defaultValue}
        noOptionsMessage={() => <span>{I18n.t(noOptionsI18nKey)}</span>}
        closeMenuOnSelect={options.length === 1}
        isSearchable={false}
        onChange={wrappedOnChange}
        classNamePrefix="multi-select"
        components={{ DropdownIndicator }}
        options={options}
      />
      <div className={`editable-value__control ${savable() ? 'active' : ''}`}>
        <LoaderButton onClick={onSave} buttonType="input-attached" isLoading={isSaving}>
          <Translate value={actionI18nKey} />
        </LoaderButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authorities: state.auth.authorities
  };
};

export default connect(mapStateToProps)(SaveableMultiSelect);

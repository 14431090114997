import React, { useState } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from '../Button';
import DatePickerWithFallback from '../DatePickerWithFallback';
import { getNewMembersDispatchRequest, getNewMembersDispatchSuccess, getNewMembersDispatchError } from '../../actions';
import api from '../../api/apiClient';
import { DATE_FORMAT } from '../../constants';

const NewMembersDispatchView = ({
  authToken,
  search,
  locale,
  getNewMembersDispatchRequest,
  getNewMembersDispatchSuccess,
  getNewMembersDispatchError
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [noResults, setNoResults] = useState(false);
  const pacsoftDelimiter = '\t';
  const excelDelimiter = ',';
  const EOL = '\r\n';

  const downloadNewPatientsFile = (selectedDate) => {
    const startDate = selectedDate;
    const endDate = moment(selectedDate, DATE_FORMAT).add(1, 'd').format('YYYY-MM-DD');
    const query = `(createdDate=ge=${startDate};createdDate=le=${endDate}),(armCircumferenceAddedDate=ge=${startDate};armCircumferenceAddedDate=le=${endDate}),(regionAddedDate=ge=${startDate};regionAddedDate=le=${endDate})`;

    getNewMembersDispatchRequest();

    api
      .getMembers(authToken, query, 0, 10000, 'createdDate:desc', [
        'armCircumference',
        'armCircumferenceAddedDate',
        'regionAddedDate'
      ])
      .then((response) => {
        getNewMembersDispatchSuccess(response);

        if (response.members.length) {
          setNoResults(false);
          const csvData = createCsvFromMemberData(response.members);
          const blob = new Blob([csvData], {
            type: 'text/csv'
          });
          saveAs(blob, `Utskick_${selectedDate}.csv`);

          const pacsoftCsvData = createPacsoftCsvFromMemberData(response.members);
          const pacsoftBlob = new Blob([pacsoftCsvData], {
            type: 'text/csv'
          });
          saveAs(pacsoftBlob, `Pacsoft_${selectedDate}.csv`);
        } else {
          setNoResults(true);
        }
      })
      .catch((error) => {
        console.log(error);
        getNewMembersDispatchError(error);
      });
  };

  const createPacsoftCsvFromMemberData = (members) => {
    let csv = '';
    const headers = `[name]${pacsoftDelimiter}[address1]${pacsoftDelimiter}[zipcode]${pacsoftDelimiter}[city]${pacsoftDelimiter}[phone]${pacsoftDelimiter}[sms]${pacsoftDelimiter}[senderref]${pacsoftDelimiter}[category4no]${pacsoftDelimiter}[category1text]${EOL}`;

    csv += headers;

    members
      .map((userSearchResult) => {
        return { ...userSearchResult.user, ...userSearchResult.userExtras };
      })
      .filter(
        (member) =>
          member.region &&
          member.region.id !== '00000000-0000-0000-0000-000000000000' &&
          member.armCircumference &&
          member.streetaddress &&
          member.city &&
          member.postalCode
      )
      .forEach((member, i, all) => {
        const parsedPhoneNumber = member.phoneNumber && parsePhoneNumberFromString(member.phoneNumber, 'SE');
        csv += `${member.givenName} ${member.familyName}${pacsoftDelimiter}`;
        csv += `${member.streetaddress}${pacsoftDelimiter}`;
        csv += `${member.postalCode}${pacsoftDelimiter}`;
        csv += `${member.city}${pacsoftDelimiter}`;
        csv += `${parsedPhoneNumber ? parsedPhoneNumber.formatNational() : ''}${pacsoftDelimiter}`;
        csv += `${parsedPhoneNumber ? parsedPhoneNumber.formatNational() : ''}${pacsoftDelimiter}`;
        csv += `${member.armCircumference}${pacsoftDelimiter}`;
        csv += `${member.armCircumference}${pacsoftDelimiter}`;
        csv += `${member.armCircumference}`;
        csv += i < all.length - 1 ? EOL : '';
      });

    return csv;
  };

  const createCsvFromMemberData = (members) => {
    let csv = '\uFEFF';
    const headers = `Namn${excelDelimiter}Gatuadress${excelDelimiter}Postnummer${excelDelimiter}Ort${excelDelimiter}Armmått${excelDelimiter}Skapad${excelDelimiter}Telefonnummer${excelDelimiter}E-postadress${excelDelimiter}Region${excelDelimiter}Pacsoft${EOL}`;

    csv += headers;

    members
      .map((userSearchResult) => {
        return { ...userSearchResult.user, ...userSearchResult.userExtras };
      })
      .forEach((member) => {
        const parsedPhoneNumber = member.phoneNumber && parsePhoneNumberFromString(member.phoneNumber, 'SE');
        csv += `${member.givenName} ${member.familyName}${excelDelimiter}`;
        csv += `${member.streetaddress}${excelDelimiter}`;
        csv += `${member.postalCode}${excelDelimiter}`;
        csv += `${member.city}${excelDelimiter}`;
        csv += `${member.armCircumference ? `${member.armCircumference}` : 'Inget armmått'}${excelDelimiter}`;
        csv += `${moment(member.createdDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}${excelDelimiter}`;
        csv += `${parsedPhoneNumber ? parsedPhoneNumber.formatNational() : 'Inget telefonnummer'}${excelDelimiter}`;
        csv += `${member.email}${excelDelimiter}`;
        csv += `${member.region ? member.region.name : 'Ingen vald region'}${excelDelimiter}`;
        csv += `${
          member.region &&
          member.region.id !== '00000000-0000-0000-0000-000000000000' &&
          member.armCircumference &&
          member.streetaddress &&
          member.city &&
          member.postalCode
            ? '*'
            : ''
        }`;
        csv += EOL;
      });

    return csv;
  };

  return (
    <div>
      <div className="card date-search-container mb-30">
        <div className="card-header">
          <Translate value="new_members_dispatch_view.header" />
        </div>
        <div className="card-content">
          <div className="columns">
            <div className="column">
              <DatePickerWithFallback
                locale={locale}
                selectedDate={selectedDate}
                placeholderI18nKey="global.choose_date"
                onChange={(date) => {
                  setSelectedDate(date);
                  setNoResults(false);
                }}
                onChangeNative={(e) =>
                  e.target.value ? setSelectedDate(moment(e.target.value).toDate()) : setSelectedDate(undefined)
                }
              />
            </div>
            <div className="column is-4 text-right">
              <Button
                onClick={() => {
                  downloadNewPatientsFile(moment(selectedDate).format('YYYY-MM-DD'));
                }}
                disabled={!selectedDate}
              >
                <Translate value="new_lab_results_view.search_button" />
              </Button>
            </div>
          </div>
          {noResults ? (
            <div>
              <Translate value="new_members_dispatch_view.no_results" />
            </div>
          ) : null}
          {search.newMembersDispatchError ? (
            <div className="error-message">
              <Translate value="new_members_dispatch_view.error" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    search: state.members,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  getNewMembersDispatchRequest,
  getNewMembersDispatchSuccess,
  getNewMembersDispatchError
};

export default connect(mapStateToProps, mapActionsToProps)(NewMembersDispatchView);

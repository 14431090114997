import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import MobileDetect from 'mobile-detect';
import queryString from 'query-string';
import ToggleSwitch from '../ToggleSwitch';
import Modal from '../Modal';
import api from '../../api/apiClient';
import { loginRequest, loginSuccess, loginError } from '../../actions';
import { loadState, saveState, removeItem } from '../../services/localStorage';
import { APPLICATION_BASE_PATH, API_ERROR_CODES } from '../../constants';
import metadata from '../../metadata.json';
import './LoginView.scss';

const allowCredentialsLogin =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_CREDENTIALS_LOGIN === 'enabled';

class LoginView extends Component {
  intervalId = 0;

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.updateLoadingDots = this.updateLoadingDots.bind(this);
    this.toggleCredentialLoginModal = this.toggleCredentialLoginModal.bind(this);
    this.onUsernameInput = this.onUsernameInput.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);

    this.state = {
      identifier: '',
      remembered: false,
      loadingDots: '.',
      refToken: null,
      credentialLoginModalVisible: false,
      username: '',
      password: ''
    };

    this.md = new MobileDetect(window.navigator.userAgent);
    this.from = null;
  }

  componentDidMount() {
    const storedIdentifier = loadState('identifier');
    const storedFrom = loadState('fromState', true);

    if (storedFrom) {
      this.from = storedFrom;
      removeItem('fromState');
    } else {
      if (this.md.mobile() && this.props.location.state && this.props.location.state.from) {
        saveState(this.props.location.state, 'fromState');
      }
    }

    if (storedIdentifier) {
      this.setState({
        identifier: storedIdentifier,
        remembered: true
      });
    }

    const parsedQueryString = queryString.parse(this.props.location.search);
    if (parsedQueryString.refToken) {
      this.setState({
        refToken: parsedQueryString.refToken
      });

      this.completeLogin(parsedQueryString.refToken, parsedQueryString.identifier);
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId);
  }

  toggleRememberMe() {
    const storedIdentifier = loadState('identifier');

    this.setState({
      remembered: !this.state.remembered
    });

    if (storedIdentifier) {
      removeItem('identifier');
    } else {
      saveState(this.state.identifier, 'identifier');
    }
  }

  onInputChange(event) {
    this.setState({
      identifier: event.target.value
    });
  }

  updateLoadingDots() {
    if (this.state.loadingDots.length < 3) {
      this.setState({
        loadingDots: this.state.loadingDots + '.'
      });
    } else {
      this.setState({
        loadingDots: '.'
      });
    }
  }

  startLoadingDots() {
    this.intervalId = window.setInterval(this.updateLoadingDots, 500);
  }

  completeLogin(refToken, identifier) {
    if (!this.intervalId) {
      this.startLoadingDots();
    }

    api
      .login(refToken, identifier)
      .then((result) => {
        this.props.onLoginSuccess(result);
        removeItem('fromState');
      })
      .catch((error) => {
        console.log(error);
        this.props.onLoginError(error);
      });
  }

  onSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.remembered) {
      saveState(this.state.identifier, 'identifier');
    }

    this.props.onLoginRequest();
    this.startLoadingDots();

    const identifier = this.state.identifier.replace('-', '');

    if (allowCredentialsLogin && this.state.credentialLoginModalVisible) {
      this.setState({ credentialLoginModalVisible: false });
      const credentials = { identifier: this.state.username, password: this.state.password, method: 'credentials' };

      api
        .logInTest(credentials)
        .then((result) => {
          this.props.onLoginSuccess(result);
          removeItem('fromState');
        })
        .catch((error) => {
          this.props.onLoginError(error);
        });
    } else {
      api.challenge(identifier).then((result) => {
        if (this.md.mobile()) {
          const newLocation = result.payload.replace(
            'redirect=null',
            `redirect=${window.encodeURIComponent(
              `${window.location.href}?refToken=${result.refToken}&identifier=${identifier}`
            )}`
          );
          window.location.replace(newLocation);
        } else {
          this.completeLogin(result.refToken, identifier);
        }
      });
    }
  }

  toggleCredentialLoginModal() {
    this.setState({
      credentialLoginModalVisible: !this.state.credentialLoginModalVisible
    });
  }

  onUsernameInput(e) {
    this.setState({
      username: e.target.value
    });
  }

  onPasswordInput(e) {
    this.setState({
      password: e.target.value
    });
  }

  render() {
    const { from } = this.from || this.props.location.state || { from: { pathname: `/${APPLICATION_BASE_PATH}` } };

    if (this.props.redirectToReferrer && !this.props.loginError) {
      return <Redirect to={from} />;
    }

    return (
      <Fragment>
        <div className="login-wrapper">
          <div className="login-container">
            <div className="login-form">
              {!this.props.isLoggingIn && !this.state.refToken ? (
                <form method="POST" onSubmit={this.onSubmit} autoComplete="off">
                  <input
                    className={this.props.loginError ? 'error' : ''}
                    type="text"
                    value={this.state.identifier}
                    onChange={this.onInputChange}
                    name="personal-number"
                    placeholder={I18n.t('global.personal_number')}
                    required={true}
                    pattern="[0-9]{6,}-*[0-9]+"
                  />
                  {this.props.loginError ? (
                    <div className="login-error">
                      {this.props.loginError.response ? (
                        <Translate
                          value={
                            API_ERROR_CODES[JSON.parse(this.props.loginError.response.text).code] ||
                            'server_error.fallback'
                          }
                        />
                      ) : (
                        <Translate value="server_error.connectivity" />
                      )}
                    </div>
                  ) : null}
                  <button type="submit">
                    <Translate value="login_view.start_login" />
                  </button>
                  <div className="columns mt-25 mr-0 ml-0">
                    <div className="column is-8 no-padding text-left">
                      <Translate value="login_view.remember_me" />
                    </div>
                    <div className="column is-4 no-padding text-right">
                      <ToggleSwitch onChange={this.toggleRememberMe} checked={this.state.remembered} />
                    </div>
                  </div>
                </form>
              ) : (
                <Fragment>
                  <div className="text-center">
                    <div className="login-bankid-logo"></div>
                  </div>
                  <h1>
                    <Translate value="login_view.start_bank_id" />
                  </h1>
                  <div className="text-center mt-10">
                    <Translate value="login_view.bank_id_instruction" />
                  </div>
                  <div className="loading-dots">
                    <Translate value="login_view.waiting_for_confirmation" />
                    <span>{this.state.loadingDots}</span>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <footer className="login-footer">
            <div className="footer-logo"></div>
            <strong>Blodtrycksdoktorn AB</strong>
            <Translate value="login_view.footer_info" />
          </footer>
        </div>
        <div className="build-number">
          <div>
            Version:{' '}
            <span onClick={allowCredentialsLogin ? this.toggleCredentialLoginModal : undefined}>
              {metadata.version}
            </span>{' '}
            (Build: #{process.env.REACT_APP_BITRISE_BUILD_NUMBER || 0})
          </div>
        </div>
        <Modal
          visible={this.state.credentialLoginModalVisible}
          actionCompletable={!!(this.state.username.length && this.state.password.length)}
          size="x-small"
          headerI18nKey="global.login"
          actionI18nKey="global.login"
          onClose={this.toggleCredentialLoginModal}
          onActionCompleted={this.onSubmit}
          allowEnterCompletion={true}
        >
          <div className="columns">
            <div className="column no-padding mb-20">
              <input
                placeholder="E-postadress"
                type="text"
                className="w-100"
                value={this.state.username || ''}
                onChange={this.onUsernameInput}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column no-padding">
              <input
                placeholder="Lösenord"
                type="password"
                className="w-100"
                value={this.state.password || ''}
                onChange={this.onPasswordInput}
              />
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    redirectToReferrer: state.auth.redirectToReferrer,
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.error
  };
};

const mapActionsToProps = {
  onLoginRequest: loginRequest,
  onLoginSuccess: loginSuccess,
  onLoginError: loginError
};

export default connect(mapStateToProps, mapActionsToProps)(LoginView);

import React, { useState, useEffect, useCallback, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Select from '../Select';
import Card from '../Card';
import Button from '../Button';
import Modal from '../Modal';
import LoadingIcon from '../LoadingIcon';
import FinalNoteBreakdownPdf from '../FinalNoteBreakdownPdf';
import YearlyFinalNoteStatistics from './YearlyFinalNoteStatistics';
import FinalNotesPerTypeList from './FinalNotesPerTypeList';
import { capitalize, decamelize } from '../../utils';
import {
  getDoctorPatientTotals,
  getCaregiverStatistics,
  getAllDoctors,
  getDoctorFinalNoteBreakdown,
  clearDoctorFinalNoteBreakdown,
  showNotification,
  getFinalNotesPerType
} from '../../actions';
import {
  DATE_FORMAT,
  DOCTORS_ON_LEGACY_REMUNERATION_SCHEME,
  DOCTOR_NOTE_TYPES,
  FINAL_NOTE_LEGACY_COST,
  VAT_RATE
} from '../../constants';
import api from '../../api/apiClient';
import './DoctorStatisticsView.scss';

const formatter = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' });

export const getTableData = (caregiverId, finalNoteBreakdown, remunerationScheme, billingPeriod) => {
  return finalNoteBreakdown.finalNoteTotals
    .filter((breakdown) => DOCTOR_NOTE_TYPES.includes(breakdown.noteType))
    .map((breakdown) => {
      return {
        noteType: I18n.t(`global.final_note_reason.${decamelize(breakdown.noteType)}`),
        numFinalNotes: breakdown[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`],
        remuneration: DOCTORS_ON_LEGACY_REMUNERATION_SCHEME.includes(caregiverId)
          ? FINAL_NOTE_LEGACY_COST
          : remunerationScheme[breakdown.noteType] || 0,
        totalExclVat:
          ((DOCTORS_ON_LEGACY_REMUNERATION_SCHEME.includes(caregiverId)
            ? FINAL_NOTE_LEGACY_COST
            : remunerationScheme[breakdown.noteType] || 0) *
            breakdown[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`]) /
          VAT_RATE,
        totalInclVat:
          (DOCTORS_ON_LEGACY_REMUNERATION_SCHEME.includes(caregiverId)
            ? FINAL_NOTE_LEGACY_COST
            : remunerationScheme[breakdown.noteType] || 0) *
          breakdown[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`]
      };
    });
};

export const getTotalRemuneration = (
  finalNoteTotals,
  remunerationScheme,
  caregiverId,
  billingPeriod,
  vatIncluded = true
) => {
  return finalNoteTotals.reduce(
    (acc, curr) =>
      acc +
      (curr[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`] *
        (DOCTORS_ON_LEGACY_REMUNERATION_SCHEME.includes(caregiverId)
          ? FINAL_NOTE_LEGACY_COST
          : remunerationScheme[curr.noteType] || 0)) /
        (vatIncluded ? 1 : VAT_RATE),
    0
  );
};

export const getTotalFinalNotes = (finalNoteTotals, billingPeriod) => {
  return finalNoteTotals.reduce(
    (acc, curr) => acc + curr[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`],
    0
  );
};

const DoctorStatisticsView = ({
  authToken,
  reports,
  doctors,
  getCaregiverStatistics,
  getDoctorPatientTotals,
  getAllDoctors,
  getDoctorFinalNoteBreakdown,
  clearDoctorFinalNoteBreakdown,
  showNotification,
  getFinalNotesPerType,
  locale
}) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const [selectedDoctor, setSelectedDoctor] = useState({
    value: null,
    label: I18n.t('reports_view.doctor_statistics.choose_doctor')
  });
  const [billingDetailsVisible, setBillingDetailsVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(reports.months[currentMonth === 0 ? 11 : currentMonth - 1]);
  const [selectedQuarter, setSelectedQuarter] = useState(reports.quarters[0]);
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [billingPeriodYear, setBillingPeriodYear] = useState(currentMonth === 0 ? currentYear - 1 : currentYear);

  const billingPeriods = [
    {
      label: I18n.t('reports_view.doctor_statistics.monthly_billing'),
      value: 'monthly'
    },
    {
      label: I18n.t('reports_view.doctor_statistics.quarterly_billing'),
      value: 'quarterly'
    }
  ];

  useEffect(() => {
    if (selectedDoctor.value) {
      getDoctorFinalNoteBreakdown(authToken, selectedDoctor.value, getBaseDate());
    } else {
      clearDoctorFinalNoteBreakdown();
    }
  }, [
    selectedDoctor,
    authToken,
    getDoctorFinalNoteBreakdown,
    clearDoctorFinalNoteBreakdown,
    billingPeriodYear,
    selectedQuarter,
    selectedMonth,
    billingPeriod
  ]);

  useEffect(() => {
    getDoctorPatientTotals(authToken);
  }, [authToken, getDoctorPatientTotals]);

  useEffect(() => {
    getAllDoctors(authToken);
  }, [authToken, getAllDoctors]);

  const getBaseDate = () => {
    const startOfYear = `${billingPeriodYear}-01-01T00:00:00.000Z`;
    const date = moment(startOfYear, DATE_FORMAT);

    if (billingPeriod === 'quarterly') {
      date.add(selectedQuarter.value, 'Q');
    } else {
      date.add(parseInt(selectedMonth.value, 10), 'months');
    }

    return date.format('YYYY-MM-DD');
  };

  const generatePdfDocument = () => {
    const filename = `accumbo_debiteringsunderlag_${selectedDoctor.label.replace(/\s+/g, '-')}.pdf`;

    api
      .getDoctorFinalNoteBreakdown(authToken, selectedDoctor.value, getBaseDate())
      .then((response) => {
        return pdf(
          <FinalNoteBreakdownPdf
            doctor={selectedDoctor}
            tableData={getTableData(selectedDoctor.value, response, reports.remunerationScheme, billingPeriod)}
            totalFinalNotes={getTotalFinalNotes(response.finalNoteTotals, billingPeriod)}
            totalExclVat={getTotalRemuneration(
              response.finalNoteTotals,
              reports.remunerationScheme,
              selectedDoctor.value,
              billingPeriod,
              false
            )}
            totalInclVat={getTotalRemuneration(
              response.finalNoteTotals,
              reports.remunerationScheme,
              selectedDoctor.value,
              billingPeriod
            )}
            finalNoteMeta={response.meta}
            billingPeriod={billingPeriod}
          />
        ).toBlob();
      })
      .then((blob) => saveAs(blob, filename))
      .catch(() => {
        showNotification(I18n.t('notification.download_pdf.error'), 'error');
      });
  };

  const getYearlyStatistics = useCallback((year, caregiverRole) => {
    getCaregiverStatistics(authToken, year, caregiverRole);
  });

  const closeBillingDetails = () => {
    setBillingDetailsVisible(false);
    setSelectedDoctor({ value: null });
    setSelectedMonth(reports.months[currentMonth === 0 ? 11 : currentMonth - 1]);
    setBillingPeriodYear(currentMonth === 0 ? currentYear - 1 : currentYear);
    setSelectedQuarter(reports.quarters[0]);
    setBillingPeriod('monthly');
  };

  const getFinalNotesPerTypeData = useCallback((fromDate, toDate, caregiverRole) => {
    getFinalNotesPerType(authToken, fromDate, toDate, caregiverRole);
  });

  return (
    <Fragment>
      <Card
        titleI18nKey="reports_view.doctor_statistics.final_note_type_breakdown_header"
        error={reports.doctorFinalNoteBreakdownError}
        errorI18nKey="reports_view.doctor_statistics.statistics_error"
        additionalClass="x-large"
      >
        <div className="table-container doctor-statistics">
          <table className="table is-striped w-100">
            <tbody>
              {doctors.map((doctor) => (
                <tr key={doctor.value}>
                  <th>{doctor.label}</th>
                  <td className="text-right">
                    <Button
                      size="medium"
                      onClick={() => {
                        setSelectedDoctor(doctor);
                        setBillingDetailsVisible(true);
                      }}
                    >
                      <Translate value="reports_view.doctor_statistics.show_details" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      <YearlyFinalNoteStatistics
        isLoading={reports.loadingCaregiverStatistics}
        error={reports.caregiverStatisticsError}
        caregiverStatistics={reports.caregiverStatistics}
        years={reports.years}
        getYearlyStatistics={getYearlyStatistics}
      />
      <FinalNotesPerTypeList
        isLoading={reports.loadingFinalNotesPerType}
        error={reports.finalNotesPerTypeError}
        finalNotesPerType={reports.finalNotesPerType}
        getFinalNotesPerType={getFinalNotesPerTypeData}
        locale={locale}
      />
      <Card
        titleI18nKey="reports_view.doctor_statistics.patient_totals_header"
        isLoading={reports.loadingDoctorPatientTotals}
        error={reports.doctorPatientTotalsError}
        errorI18nKey="reports_view.doctor_statistics.patient_totals_error"
        additionalClass="x-large"
      >
        <div className={`table-container ${reports.loadingDoctorPatientTotals ? 'opacity-6' : ''}`}>
          <table className="table is-striped w-100">
            <thead>
              <tr>
                <th>
                  <Translate value="reports_view.doctor_statistics.doctor_table_header" />
                </th>
                <th>
                  <Translate value="reports_view.doctor_statistics.total" />
                </th>
                <th>
                  <Translate value="reports_view.doctor_statistics.active" />
                </th>
                <th>
                  <Translate value="reports_view.doctor_statistics.hidden" />
                </th>
                <th>
                  <Translate value="reports_view.doctor_statistics.blocked" />
                </th>
                <th>EuroAccident</th>
              </tr>
            </thead>
            <tbody>
              {reports.doctorPatientTotals.map((row, i) => {
                return (
                  <tr key={i}>
                    <th>
                      {row.caregiver.givenName} {row.caregiver.familyName}
                    </th>
                    <td>{row.patients.total}</td>
                    <td>
                      {row.patients.active}{' '}
                      {row.patients.total > 0
                        ? `(${Math.round((row.patients.active / row.patients.total) * 100)} %)`
                        : null}
                    </td>
                    <td>
                      {row.patients.hidden}{' '}
                      {row.patients.total > 0
                        ? `(${Math.round((row.patients.hidden / row.patients.total) * 100)} %)`
                        : null}
                    </td>
                    <td>
                      {row.patients.blocked}{' '}
                      {row.patients.total > 0
                        ? `(${Math.round((row.patients.blocked / row.patients.total) * 100)} %)`
                        : null}
                    </td>
                    <td>{row.patients.externalSource}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th>
                  <Translate value="reports_view.doctor_statistics.total" />
                </th>
                <th>{reports.doctorPatientTotalSums.total}</th>
                <th>
                  {reports.doctorPatientTotalSums.active} (
                  {reports.doctorPatientTotalSums.active
                    ? Math.round((reports.doctorPatientTotalSums.active / reports.doctorPatientTotalSums.total) * 100)
                    : 0}{' '}
                  %)
                </th>
                <th>
                  {reports.doctorPatientTotalSums.hidden} (
                  {reports.doctorPatientTotalSums.hidden
                    ? Math.round((reports.doctorPatientTotalSums.hidden / reports.doctorPatientTotalSums.total) * 100)
                    : 0}{' '}
                  %)
                </th>
                <th>
                  {reports.doctorPatientTotalSums.blocked} (
                  {reports.doctorPatientTotalSums.blocked
                    ? Math.round((reports.doctorPatientTotalSums.blocked / reports.doctorPatientTotalSums.total) * 100)
                    : 0}{' '}
                  %)
                </th>
                <th>{reports.doctorPatientTotalSums.externalSource}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </Card>
      <Modal
        headerI18nKey="reports_view.doctor_statistics.final_note_type_breakdown_header"
        actionI18nKey="global.buttons.ok"
        visible={billingDetailsVisible}
        onClose={closeBillingDetails}
        onActionCompleted={closeBillingDetails}
        actionCompletable={true}
        size="auto"
      >
        {reports.loadingDoctorFinalNoteBreakdown && !reports.doctorFinalNoteBreakdown.meta ? (
          <div className="vertical-align justify-center" style={{ width: '1000px' }}>
            <LoadingIcon type="spinner-secondary" />
          </div>
        ) : selectedDoctor.value && reports.doctorFinalNoteBreakdown.meta ? (
          <>
            <div className="space-between doctor-statistics">
              <div>
                <div className="picker mb-10">
                  {billingPeriod === 'quarterly' ? (
                    <>
                      <h4>
                        <Translate value="global.quarter" />
                      </h4>
                      <Select
                        key={'quarterly'}
                        isSearchable={false}
                        value={selectedQuarter}
                        onChange={(e) => {
                          setSelectedQuarter(e);
                        }}
                        classNamePrefix="single-select"
                        options={reports.quarters}
                      />
                    </>
                  ) : (
                    <>
                      <h4>
                        <Translate value="global.month" />
                      </h4>
                      <Select
                        key={'monthly'}
                        isSearchable={false}
                        value={selectedMonth}
                        onChange={(e) => {
                          setSelectedMonth(e);
                        }}
                        classNamePrefix="single-select"
                        options={reports.months}
                      />
                    </>
                  )}
                </div>
                <div className="picker ml-10">
                  <h4>
                    <Translate value="global.year" />
                  </h4>
                  <Select
                    isSearchable={false}
                    defaultValue={{
                      label: currentMonth === 0 ? currentYear - 1 : currentYear,
                      value: currentMonth === 0 ? currentYear - 1 : currentYear
                    }}
                    onChange={(e) => {
                      setBillingPeriodYear(e.value);
                    }}
                    classNamePrefix="single-select"
                    options={reports.years}
                  />
                </div>
              </div>
              <div className="picker mb-10">
                <h4>
                  <Translate value="reports_view.doctor_statistics.billing_period" />
                </h4>
                <Select
                  isSearchable={false}
                  defaultValue={billingPeriods[0]}
                  onChange={(e) => {
                    setBillingPeriod(e.value);
                  }}
                  classNamePrefix="single-select"
                  options={billingPeriods}
                />
              </div>
            </div>
            <div className={`table-container ${reports.loadingDoctorFinalNoteBreakdown ? 'opacity-6' : ''}`}>
              <table className="table is-striped">
                <thead>
                  <tr>
                    <th>{selectedDoctor.label}</th>
                    <th className="billing-period-header">
                      {reports.doctorFinalNoteBreakdown.meta ? (
                        billingPeriod === 'monthly' ? (
                          capitalize(
                            moment(reports.doctorFinalNoteBreakdown.meta.previousMonthStart, DATE_FORMAT).format('MMMM')
                          )
                        ) : (
                          <Translate
                            value={`reports_view.doctor_statistics.quarter`}
                            monthRange={`${moment(
                              reports.doctorFinalNoteBreakdown.meta.previousQuarterStart,
                              DATE_FORMAT
                            ).format('MMM.')}–${moment(
                              reports.doctorFinalNoteBreakdown.meta.previousQuarterEnd,
                              DATE_FORMAT
                            )
                              .add(-1, 'day')
                              .format('MMM.')}`}
                          />
                        )
                      ) : null}
                    </th>
                    <th>
                      <Translate value="reports_view.doctor_statistics.final_note_remuneration" />
                    </th>
                    <th>
                      <Translate value="reports_view.doctor_statistics.total_excl_vat" />
                    </th>
                    <th>
                      <Translate value="reports_view.doctor_statistics.total_incl_vat" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports.doctorFinalNoteBreakdown.finalNoteTotals
                    .filter((breakdown) => DOCTOR_NOTE_TYPES.includes(breakdown.noteType))
                    .map((breakdown, i) => (
                      <tr key={i}>
                        <th>
                          <Translate value={`global.final_note_reason.${decamelize(breakdown.noteType)}`} />
                        </th>
                        <td className="text-right">
                          {breakdown[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`]}
                        </td>
                        <td className="text-right">
                          {DOCTORS_ON_LEGACY_REMUNERATION_SCHEME.includes(selectedDoctor.value)
                            ? FINAL_NOTE_LEGACY_COST
                            : reports.remunerationScheme[breakdown.noteType] || 0}
                        </td>
                        <td className="text-right">
                          {formatter
                            .format(
                              ((DOCTORS_ON_LEGACY_REMUNERATION_SCHEME.includes(selectedDoctor.value)
                                ? FINAL_NOTE_LEGACY_COST
                                : reports.remunerationScheme[breakdown.noteType] || 0) *
                                breakdown[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`]) /
                                VAT_RATE
                            )
                            .slice(0, -3)}
                        </td>
                        <td className="text-right">
                          {formatter
                            .format(
                              (DOCTORS_ON_LEGACY_REMUNERATION_SCHEME.includes(selectedDoctor.value)
                                ? FINAL_NOTE_LEGACY_COST
                                : reports.remunerationScheme[breakdown.noteType] || 0) *
                                breakdown[`previous${billingPeriod === 'monthly' ? 'Month' : 'Quarter'}Total`]
                            )
                            .slice(0, -3)}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th>
                      <Translate value="reports_view.doctor_statistics.total" />
                    </th>
                    <th className="text-right">
                      {getTotalFinalNotes(reports.doctorFinalNoteBreakdown.finalNoteTotals, billingPeriod)}
                    </th>
                    <th></th>
                    <th className="text-right">
                      {formatter
                        .format(
                          getTotalRemuneration(
                            reports.doctorFinalNoteBreakdown.finalNoteTotals,
                            reports.remunerationScheme,
                            selectedDoctor.value,
                            billingPeriod,
                            false
                          )
                        )
                        .slice(0, -3)}
                    </th>
                    <th className="text-right">
                      {formatter
                        .format(
                          getTotalRemuneration(
                            reports.doctorFinalNoteBreakdown.finalNoteTotals,
                            reports.remunerationScheme,
                            selectedDoctor.value,
                            billingPeriod
                          )
                        )
                        .slice(0, -3)}
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div className="text-right pdf-download-button">
                <Button size="large" buttonType="secondary" onClick={generatePdfDocument}>
                  <Translate value="reports_view.doctor_statistics.download_pdf" /> <span className="icon pdf"></span>
                </Button>
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    reports: state.reports,
    authority: state.sharedData.authorityTypes,
    doctors: state.sharedData.doctors,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  getCaregiverStatistics,
  getDoctorPatientTotals,
  getAllDoctors,
  getDoctorFinalNoteBreakdown,
  clearDoctorFinalNoteBreakdown,
  showNotification,
  getFinalNotesPerType
};

export default connect(mapStateToProps, mapActionsToProps)(DoctorStatisticsView);

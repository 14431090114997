import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import LoaderButton from '../LoaderButton';
import './EditableValue.scss';

const EditableValue = ({ value, unit, onSave, isSaving, actionI18nKey, requiredAuthority, authorities, range }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const originalValue = value ? value.toString() : '';

  const onChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const onKeyUp = (e) => {
    if (saveable() && e.key === 'Enter') {
      onSave(currentValue);
    }
  };

  const saveable = () => {
    const hasUpdatedValue = currentValue.length && currentValue !== originalValue;

    if (range) {
      const minValue = range[0];
      const maxValue = range[1];
      const currentValueInt = parseInt(currentValue, 10);

      return hasUpdatedValue && currentValueInt >= minValue && currentValueInt <= maxValue;
    }

    return hasUpdatedValue;
  };

  if (authorities.indexOf(requiredAuthority) === -1) {
    if (value) {
      return `${value}${unit ? ` ${unit}` : ''}`;
    }

    return '-';
  }

  return (
    <Fragment>
      <div className="editable-value">
        <div
          className={`editable-value__current-value ${unit ? 'has-unit' : ''} ${
            currentValue.length && currentValue !== originalValue && !saveable() ? 'invalid-value' : ''
          }`}
        >
          <input type="text" defaultValue={value} onChange={onChange} onKeyUp={onKeyUp} />
        </div>
        {unit ? <div className="editable-value__unit">{unit}</div> : null}
        <div className={`editable-value__control ${saveable() ? 'active' : ''}`} onClick={() => onSave(currentValue)}>
          <LoaderButton buttonType="input-attached" isLoading={isSaving}>
            <Translate value={actionI18nKey} />
          </LoaderButton>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authorities: state.auth.authorities
  };
};

export default connect(mapStateToProps)(EditableValue);

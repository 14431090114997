import React, { useState, useCallback, useRef, Fragment } from 'react';
import SearchBar from './SearchBar';
import SearchResultsTable from './SearchResultsTable';
import { MEMBER_SEARCH_LIMIT } from '../../constants';
import ContentLoader from '../ContentLoader';
import SearchContainer from './SearchContainer';
import AdvancedSearch from './AdvancedSearch';
import { getQueryString } from '../../utils';

const MemberSearch = ({
  onSearch,
  searchResult,
  urlFragment,
  predefinedSearches,
  additionalCriteriaOptions,
  additionalCriteria,
  ...rest
}) => {
  const initialQuery = searchResult.currentSearchQuery;
  const [searchInput, setSearchInput] = useState((initialQuery && initialQuery.searchTerm) || '');
  const [searchQuery, setSearchQuery] = useState();

  const numSearches = useRef(0);

  const onSearchSubmit = (event) => {
    event.preventDefault();
    const rawInput = event.target.value !== undefined ? event.target.value : searchInput;
    setSearchInput(rawInput);

    const query = getQueryString(rawInput, additionalCriteria);

    setSearchQuery(query);

    onSearch({
      query,
      offset: 0,
      limit: MEMBER_SEARCH_LIMIT,
      returnToIndexPage: initialQuery.pageIndex > 0,
      searchTerm: rawInput,
      sortBy: initialQuery && initialQuery.sortBy
    });
  };

  const onClearSearch = (e) => {
    e.preventDefault();
    const query = '';
    setSearchInput(query);
    setSearchQuery(query);

    const searchOptions = {
      query,
      offset: 0,
      limit: MEMBER_SEARCH_LIMIT,
      returnToIndexPage: initialQuery.pageIndex > 0,
      searchTerm: query,
      sortBy: initialQuery && initialQuery.sortBy
    };

    onSearch(searchOptions);
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      let getMembersOptions;
      let sortProperty;

      if (initialQuery && initialQuery.sortBy && !sortBy[0]) {
        sortProperty = initialQuery.sortBy;
      } else if (sortBy[0]) {
        sortProperty = `${sortBy[0].id === 'nameAndGender' ? 'familyName' : sortBy[0].id}:${
          sortBy[0].desc ? 'desc' : 'asc'
        }`;
      }

      // If the initial query has a page index set, gotoPage will trigger this callback to run again,
      // which means that in that case numSearches will be 1, not 0
      if (
        (numSearches.current === 0 && initialQuery) ||
        (numSearches.current === 1 && initialQuery && initialQuery.pageIndex)
      ) {
        getMembersOptions = { ...initialQuery, returnToIndexPage: false };
      } else {
        getMembersOptions = {
          query: searchQuery !== undefined ? searchQuery : getQueryString(initialQuery.searchTerm),
          offset: pageIndex * pageSize,
          limit: MEMBER_SEARCH_LIMIT,
          returnToIndexPage: false,
          sortBy: sortProperty,
          pageIndex: pageIndex,
          searchTerm: searchInput
        };
      }

      onSearch(getMembersOptions);

      numSearches.current++;
    },
    [onSearch, searchQuery, initialQuery, searchInput]
  );

  return (
    <Fragment>
      <SearchContainer>
        <SearchBar
          value={searchInput}
          isLoading={searchResult.fetchingMembers}
          onSearchSubmit={onSearchSubmit}
          onClearSearch={onClearSearch}
        />
        <AdvancedSearch
          predefinedSearches={predefinedSearches}
          additionalCriteriaOptions={additionalCriteriaOptions}
          additionalCriteria={additionalCriteria}
        />
      </SearchContainer>
      <ContentLoader isLoading={false} error={searchResult.membersError} errorTitleI18n="member_search.error">
        <SearchResultsTable
          data={searchResult.members || []}
          fetchData={fetchData}
          isLoading={searchResult.fetchingMembers}
          limit={MEMBER_SEARCH_LIMIT}
          jumpToFirstPage={searchResult.returnToIndexPage}
          initalSort={
            (searchResult.currentSearchQuery && searchResult.currentSearchQuery.sortBy) || 'nameAndGender:asc'
          }
          initialPage={
            numSearches.current === 0 && searchResult.currentSearchQuery && searchResult.currentSearchQuery.pageIndex
          }
          pagination={searchResult && searchResult.pagination}
          urlFragment={urlFragment}
          {...rest}
        />
      </ContentLoader>
    </Fragment>
  );
};

export default MemberSearch;

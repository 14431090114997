import React, { useEffect } from 'react';
import { Translate } from 'react-redux-i18n';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/sv';

const MemberMessageEditor = ({ locale, messageSubject, messageBody, onSubjectChanged, onBodyChanged }) => {
  useEffect(() => {
    window.addEventListener('beforeunload', preventNavigation);

    return () => window.removeEventListener('beforeunload', preventNavigation);
  });

  const preventNavigation = (e) => {
    if (messageSubject.length || messageBody.length) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  return (
    <div>
      <h4>
        <Translate value="member_details.message_subject_header" />
      </h4>
      <input type="text" value={messageSubject} onChange={onSubjectChanged} />
      <h4>
        <Translate value="member_details.message_body_header" />
      </h4>
      <CKEditor
        config={{
          language: locale,
          toolbar: {
            items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
          }
        }}
        editor={ClassicEditor}
        onChange={(e, editor) => {
          onBodyChanged(editor.getData());
        }}
      />
    </div>
  );
};

export default MemberMessageEditor;

import React, { Fragment, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Prompt } from 'react-router-dom';
import SortableTree from 'react-sortable-tree';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import CanPerform from '../CanPerform';
import Card from '../Card';
import Modal from '../Modal';
import Button from '../Button';
import EditableValue from '../EditableValue';
import { getCannedResponses, getTemplateTags, showNotification } from '../../actions';
import { getNodeKey, reducer, initialState, actionTypes } from './treeReducer';
import api from '../../api/apiClient';
import 'react-sortable-tree/style.css';
import './ManageCannedResponsesView.scss';

const ManageCannedResponsesView = ({
  authToken,
  cannedMessages,
  templateTags,
  authority,
  getCannedResponses,
  getTemplateTags,
  showNotification
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getCannedResponses(authToken);
    getTemplateTags(authToken);
  }, [authToken, getCannedResponses, getTemplateTags]);

  useEffect(() => {
    dispatch({ type: actionTypes.setTreeData, treeData: cannedMessages });
  }, [cannedMessages]);

  useEffect(() => {
    window.addEventListener('beforeunload', preventNavigation);

    return () => window.removeEventListener('beforeunload', preventNavigation);
  });

  const preventNavigation = (e) => {
    if (state.addedNodes.length || state.removedNodes.length || state.updatedNodes.length) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const saveNewNodes = (subTree) => {
    subTree.forEach((node) => {
      if (state.addedNodes.map((n) => n.id).includes(node.id)) {
        api
          .postCannedResponse(authToken, node)
          .then(() => {
            dispatch({ type: actionTypes.addNodeSuccess, nodeId: node.id });
            if (node.children) {
              saveNewNodes(node.children);
            }
          })
          .catch(() => showNotification(I18n.t('notification.create_canned_response.error'), 'error'));
      } else if (node.children) {
        saveNewNodes(node.children);
      }
    });
  };

  const saveEditedNodeBody = () => {
    const leftCurlies = state.editedNodeBody.match(/{/g);
    const rightCurlies = state.editedNodeBody.match(/}/g);

    if (
      (leftCurlies !== null && !rightCurlies) ||
      (rightCurlies !== null && !leftCurlies) ||
      (leftCurlies !== null && rightCurlies !== null && leftCurlies.length !== rightCurlies.length)
    ) {
      showNotification(I18n.t('reports_view.canned_responses.invalid_tag'), 'error');
      return;
    }

    const tagRegex = /%{(\w+)}/g;
    const tags = state.editedNodeBody.match(tagRegex);

    if (tags && tags.length) {
      const allTags = templateTags.map((tag) => tag.key);
      if (tags.some((tag) => !allTags.includes(tag))) {
        showNotification(I18n.t('reports_view.canned_responses.invalid_tag'), 'error');
        return;
      }
    }

    if (state.addedNodes.map((n) => n.id).includes(state.editedNode.id)) {
      return dispatch({ type: actionTypes.updateNodeBodySuccess });
    }

    dispatch({ type: actionTypes.updateCannedResponseRequest });
    api
      .updateCannedResponse(authToken, { ...state.editedNode, body: state.editedNodeBody })
      .then(() => {
        dispatch({ type: actionTypes.updateNodeBodySuccess });
        showNotification(
          I18n.t('notification.update_canned_response_body.success', { title: state.editedNode.title }),
          'success'
        );
      })
      .catch(() => {
        showNotification(
          I18n.t('notification.update_canned_response_body.error', { title: state.editedNode.title }),
          'error'
        );
      });
  };

  const saveEditedNodeTitle = (value, path, node) => {
    if (state.addedNodes.map((n) => n.id).includes(node.id)) {
      return dispatch({ type: actionTypes.updateNodeTitleSuccess, newTitle: value, path, node });
    }

    dispatch({ type: actionTypes.updateCannedResponseRequest });
    api
      .updateCannedResponse(authToken, { ...node, title: value })
      .then(() => {
        dispatch({ type: actionTypes.updateNodeTitleSuccess, newTitle: value, path, node });
        showNotification(I18n.t('notification.update_canned_response_title.success', { title: value }), 'success');
      })
      .catch(() => {
        showNotification(I18n.t('notification.update_canned_response_title.error'), 'error');
      });
  };

  const cancelEdit = () => {
    if (state.addedNodes.length || state.removedNodes.length || state.updatedNodes.length) {
      if (window.confirm(I18n.t('reports_view.canned_responses.confirm_cancel'))) {
        return dispatch({ type: actionTypes.setEditMode, editable: false });
      }
    } else {
      dispatch({ type: actionTypes.setEditMode, editable: false });
    }
  };

  const commitPendingChanges = () => {
    if (state.removedNodes.length) {
      const removedNodesPromises = state.removedNodes.map((node) => api.deleteCannedResponse(authToken, node.id));

      Promise.all(removedNodesPromises)
        .then(() => {
          dispatch({ type: actionTypes.removeNodesSuccess });
        })
        .catch(() => showNotification(I18n.t('notification.delete_canned_response.error'), 'error'));
    }

    if (state.updatedNodes.length) {
      state.updatedNodes.forEach((node) => {
        let promises = node.children
          .filter((childNode) => !state.addedNodes.map((n) => n.id).includes(childNode.id))
          .map((childNode) => {
            return api.updateCannedResponse(authToken, childNode);
          });

        Promise.all(promises)
          .then(() => {
            dispatch({ type: actionTypes.updateNodeOrderSuccess });

            saveNewNodes(state.treeData);
          })
          .catch(() => showNotification(I18n.t('notification.update_canned_response.error'), 'error'));
      });
    } else {
      saveNewNodes(state.treeData);
    }
  };

  const addMainCategory = () => {
    dispatch({ type: actionTypes.addTopLevelNode });

    window.setTimeout(() => {
      let container = document.querySelector('.ReactVirtualized__Grid');

      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth'
      });
    }, 0);
  };

  return (
    <Fragment>
      <Prompt
        message={() => {
          if (state.addedNodes.length || state.removedNodes.length || state.updatedNodes.length) {
            return I18n.t('reports_view.canned_responses.confirm_cancel');
          }

          return true;
        }}
      />
      <div className="canned-responses">
        <CanPerform action={authority.manageContent}>
          <Card
            titleI18nKey="reports_view.canned_responses.header"
            additionalClass="x-large"
            actionButtons={
              <Fragment>
                {!state.isEditing ? (
                  <Button size="medium" onClick={() => dispatch({ type: actionTypes.setEditMode, editable: true })}>
                    <Translate value="reports_view.canned_responses.start_editing" />
                  </Button>
                ) : undefined}

                {state.addedNodes.length || state.removedNodes.length || state.updatedNodes.length ? (
                  <Button size="medium" onClick={commitPendingChanges}>
                    <Translate value="reports_view.canned_responses.save" />
                  </Button>
                ) : undefined}
                {state.isEditing ? (
                  <Button size="medium" buttonType="secondary" onClick={cancelEdit}>
                    <Translate value="reports_view.canned_responses.cancel_editing" />
                  </Button>
                ) : null}
                {state.isEditing ? (
                  <Button size="small" onClick={addMainCategory}>
                    <Translate value="reports_view.canned_responses.add_main_category" />
                  </Button>
                ) : null}
                <Button
                  size="medium"
                  buttonType="secondary"
                  onClick={() => dispatch({ type: actionTypes.toggleExpandAll })}
                >
                  <Translate
                    value={`reports_view.canned_responses.${state.allNodesExpanded ? 'collapse' : 'expand'}_all`}
                  />
                </Button>
              </Fragment>
            }
          >
            <div style={{ height: '70vh' }}>
              <SortableTree
                treeData={state.treeData}
                rowHeight={78}
                canDrag={state.isEditing}
                canDrop={({ node, nextParent }) => {
                  return (node.parent === null && nextParent === null) || (nextParent && nextParent.id === node.parent);
                }}
                onMoveNode={({ nextParentNode }) => {
                  dispatch({ type: actionTypes.nodeOrderUpdated, node: nextParentNode });
                }}
                onChange={(data) => dispatch({ type: actionTypes.setTreeData, treeData: data })}
                getNodeKey={getNodeKey}
                generateNodeProps={({ node, path }) => {
                  return {
                    style: {
                      boxShadow: 'none',
                      border:
                        state.addedNodes.map((n) => n.id).includes(node.id) && state.isEditing
                          ? '2px dashed #00308f'
                          : 'unset',
                      borderLeft: !state.isEditing
                        ? `15px solid rgba(48, 96, 190, ${1.2 - path.length * 0.2})`
                        : 'unset',
                      borderRadius: '4px'
                    },
                    title: state.isEditing ? (
                      <EditableValue
                        value={node.title}
                        isEditing={true}
                        onSave={(value) => saveEditedNodeTitle(value, path, node)}
                        isSaving={false}
                        actionI18nKey="global.buttons.save"
                        requiredAuthority={authority.manageContent}
                      />
                    ) : node.children && node.children.length ? (
                      `${node.title} (${node.children.length})`
                    ) : (
                      node.title
                    ),
                    buttons: !state.isEditing
                      ? []
                      : [
                          !node.isCategory ? (
                            <Button
                              size="small"
                              onClick={() => dispatch({ type: actionTypes.startEditingNode, node, path })}
                            >
                              <Translate value="reports_view.canned_responses.edit_text" />
                            </Button>
                          ) : undefined,
                          node.isCategory ? (
                            <Button
                              size="small"
                              onClick={() => dispatch({ type: actionTypes.addNode, path, isCategory: false })}
                            >
                              <Translate value="reports_view.canned_responses.add_node" />
                            </Button>
                          ) : undefined,
                          node.isCategory ? (
                            <Button
                              size="small"
                              onClick={() => dispatch({ type: actionTypes.addNode, path, isCategory: true })}
                            >
                              <Translate value="reports_view.canned_responses.add_sub_category" />
                            </Button>
                          ) : undefined,
                          !node.isCategory || !node.children || !node.children.length ? (
                            <span
                              onClick={() => dispatch({ type: actionTypes.removeNode, node, path })}
                              className="delete-icon"
                            ></span>
                          ) : undefined
                        ]
                  };
                }}
              />
            </div>
          </Card>
        </CanPerform>
      </div>
      <Modal
        visible={state.editModalVisible}
        actionCompletable={true}
        header={state.editedNode ? state.editedNode.title : ''}
        actionI18nKey="global.buttons.save"
        size="medium"
        onClose={() => dispatch({ type: actionTypes.setModalVisibility, visible: false })}
        actionCompleting={state.isUpdatingResponse}
        onActionCompleted={saveEditedNodeBody}
      >
        <div className="columns h-100">
          <div className="column is-7 no-padding mr-20">
            <textarea
              value={state.editedNodeBody}
              onChange={(e) => dispatch({ type: actionTypes.updateEditedNodeBody, body: e.target.value })}
            />
          </div>
          <div className="column no-padding oy-auto">
            <h2>
              <Translate value="reports_view.canned_responses.tags_header" />
            </h2>
            <ul>
              {templateTags.map((tag, i) => (
                <li key={i}>
                  <strong className="no-wrap">{tag.key}</strong>
                  <CopyToClipboard text={tag.key}>
                    <Tooltip
                      title={
                        <div className="fs-12">
                          <Translate value="global.copy" />
                        </div>
                      }
                      arrow
                      placement="top"
                    >
                      <span className="ml-5 pointer icon copy"></span>
                    </Tooltip>
                  </CopyToClipboard>
                  <br />
                  <span>{tag.value}.</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    cannedMessages: state.cannedMessages.cannedMessages,
    templateTags: state.cannedMessages.templateTags,
    authority: state.sharedData.authorityTypes
  };
};

const mapActionsToProps = {
  getCannedResponses,
  getTemplateTags,
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(ManageCannedResponsesView);

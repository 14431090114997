import React, { useReducer, useEffect, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Button from '../Button';
import LoaderButton from '../LoaderButton';
import DatePickerWithFallback from '../DatePickerWithFallback';
import EditableSelect from '../EditableSelect';
import Modal from '../Modal';
import {
  postVacation,
  deleteVacation,
  updateVacation,
  showVacationDateChangeModal,
  hideVacationDateChangeModal
} from '../../actions';
import { DATE_FORMAT } from '../../constants';
import vacationReducer, { initialState } from '../../reducers/vacationReducer';
import './VacationPlanner.scss';
import ContentLoader from '../ContentLoader';

const VacationPlanner = ({
  authToken,
  user,
  sharedData,
  locale,
  authority,
  systemUsers,
  postVacation,
  deleteVacation,
  updateVacation,
  showVacationDateChangeModal,
  hideVacationDateChangeModal
}) => {
  const [state, dispatch] = useReducer(vacationReducer, initialState);

  useEffect(() => {
    if (state.numRemaining === state.numTotal) {
      dispatch({ type: 'setNumRemaining', numRemaining: user.numberOfPatients });
    }
  }, [user, state.numRemaining, state.numTotal]);

  if (!user.personalNumber) {
    return null;
  }

  if (!user.authorities.includes(authority.caregiver) && !user.authorities.includes(authority.nurse)) {
    return null;
  }

  let caregiverType = 'doctor';

  if (user.authorities.includes(authority.nurse)) {
    caregiverType = 'nurse';
  }

  const caregivers = sharedData[caregiverType === 'doctor' ? 'caregivers' : 'nurses'];
  const caregiverOptions = caregivers
    .filter((caregiver) => caregiver.guid !== user.guid)
    .map((caregiver) => {
      return { value: caregiver.guid, label: `${caregiver.givenName} ${caregiver.familyName}` };
    });

  const setNumberForSecondary = (id) => {
    return (e) => {
      let value = parseInt(e.target.value, 10);
      if (window.isNaN(value)) {
        value = '';
      }
      dispatch({ type: 'setPropertyForSecondary', id, propertyName: 'numPatients', propertyValue: value });
    };
  };

  const setCaregiverForSecondary = (id) => {
    return (e) => {
      dispatch({ type: 'setPropertyForSecondary', id, propertyName: 'caregiverId', propertyValue: e.value });
    };
  };

  const deduplicateSecondaries = (secondaries) => {
    return secondaries.reduce((acc, curr) => {
      const found = acc.find((elem) => elem.caregiverId === curr.caregiverId);

      if (found) {
        found.numPatients += curr.numPatients;
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);
  };

  const hasEmptyCaregiver = () => {
    return state.selectedSecondaries.some(
      (selection) => !selection.caregiverId || selection.caregiverId === 'no-selection'
    );
  };

  const hasUnassignableSelection = () => {
    if (state.selectedSecondaries.length === 1 && state.numRemaining <= 0) {
      const selectedSecondary = caregivers.filter(
        (caregiver) => caregiver.guid === state.selectedSecondaries[0].caregiverId
      )[0];

      if (!selectedSecondary.hiddenRegions) {
        return false;
      }

      if (user.hiddenRegions && selectedSecondary.hiddenRegions) {
        return !(
          user.hiddenRegions.length === selectedSecondary.hiddenRegions.length &&
          user.hiddenRegions.every((region) => selectedSecondary.hiddenRegions.map((r) => r.id).includes(region.id))
        );
      }
    }

    return false;
  };

  const hasInvalidNumPatients = () => {
    const totalNumPatients = state.selectedSecondaries.reduce((curr, acc) => (curr += acc.numPatients), 0);

    if (totalNumPatients > state.numTotal) {
      return true;
    }

    return state.selectedSecondaries.some(
      (caregiver) =>
        caregiver.numPatients > state.numTotal ||
        caregiver.numPatients < 0 ||
        window.isNaN(parseInt(caregiver.numPatients, 10))
    );
  };

  const registerVacation = () => {
    if (hasEmptyCaregiver()) {
      return dispatch({ type: 'setValidationError', validationError: 'no_caregiver_selection' });
    }

    if (hasUnassignableSelection()) {
      return dispatch({ type: 'setValidationError', validationError: 'region' });
    }

    if (hasInvalidNumPatients()) {
      return dispatch({ type: 'setValidationError', validationError: 'invalid_number' });
    }

    dispatch({ type: 'setValidationError', validationError: null });

    let patientDistribution = JSON.parse(JSON.stringify(deduplicateSecondaries(state.selectedSecondaries)));

    patientDistribution = patientDistribution.filter((s) => s.numPatients > 0);

    patientDistribution.forEach((caregiver) => {
      caregiver.percent = Math.round((caregiver.numPatients / user.numberOfPatients) * 100);
      caregiver.caregiver = caregiver.caregiverId;
    });

    patientDistribution.forEach((caregiver) => {
      delete caregiver.caregiverId;
      delete caregiver.numPatients;
      delete caregiver.id;
    });

    const totalPercentagesDiff = 100 - patientDistribution.reduce((arr, curr) => (arr += curr.percent), 0);

    if (totalPercentagesDiff > 0) {
      patientDistribution[0].percent += totalPercentagesDiff;
    } else if (totalPercentagesDiff < 0) {
      patientDistribution[0].percent -= totalPercentagesDiff;
    }

    postVacation(
      authToken,
      moment(state.startDate).format(DATE_FORMAT),
      moment(state.endDate).add(1, 'd').format(DATE_FORMAT),
      user.guid,
      patientDistribution
    );
  };

  const removeVacation = () => {
    if (window.confirm(I18n.t('system_user_details.vacation_planning.confirm_removal'))) {
      deleteVacation(authToken, user.postedVacation.id);
    }
  };

  const startEditingNewToDate = () => {
    dispatch({ type: 'setNewToDate', date: moment(user.postedVacation.endDate, DATE_FORMAT).add(-1, 'd').toDate() });
    showVacationDateChangeModal();
  };

  const saveNewToDate = () => {
    const updatedVacation = {
      ...user.postedVacation,
      endDate: moment(state.newToDate).add(1, 'd').format(DATE_FORMAT)
    };
    updateVacation(authToken, updatedVacation);
  };

  return (
    <Fragment>
      <ContentLoader
        isLoading={systemUsers.loadingVacations}
        error={systemUsers.vacationsError}
        errorTitleI18n="system_user_details.vacation_planning.error.fetching_vacations"
      >
        <div className="card-container vacation-planner">
          {user.postedVacation && moment(user.postedVacation.endDate, DATE_FORMAT).isAfter(moment()) ? (
            <Fragment>
              <h2>
                <Translate value="system_user_details.vacation_planning.header" />
              </h2>
              <h4>
                <Translate value="system_user_details.vacation_planning.period_header" />
              </h4>
              <div className="mb-20">{`${moment(user.postedVacation.startDate, DATE_FORMAT).format(
                'YYYY-MM-DD'
              )}–${moment(user.postedVacation.endDate, DATE_FORMAT).add(-1, 'd').format('YYYY-MM-DD')}`}</div>

              <h4>
                <Translate value="system_user_details.vacation_planning.caregiver_list_header" />
              </h4>
              <div className="secondary-pal-list">
                {user.postedVacation.patientDistribution.map((c) => {
                  const fullCaregiver = caregivers.find((caregiver) => caregiver.guid === c.caregiver);

                  if (!fullCaregiver) return null;

                  return (
                    <div key={fullCaregiver.guid}>{`${fullCaregiver.givenName} ${
                      fullCaregiver.familyName
                    } (${Math.round((user.numberOfPatients / 100) * c.percent)} pat.)`}</div>
                  );
                })}
              </div>
              <div className="mt-25">
                <Button onClick={startEditingNewToDate} buttonType="secondary" fullWidth={true}>
                  <Translate value="system_user_details.vacation_planning.change_to_date" />
                </Button>
                <LoaderButton
                  onClick={removeVacation}
                  buttonType="destructive"
                  fullWidth={true}
                  isLoading={systemUsers.deletingVacation}
                >
                  <Translate value="system_user_details.vacation_planning.remove_vacation" />
                </LoaderButton>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <h2>
                <Translate value="system_user_details.vacation_planning.header" />
              </h2>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="global.from_date" />
                  </h4>
                  <DatePickerWithFallback
                    locale={locale}
                    selectedDate={state.startDate}
                    maxDate={state.endDate || null}
                    minDate={moment().toDate()}
                    selectsStart
                    startDate={state.startDate}
                    endDate={state.endDate}
                    placeholderI18nKey="global.choose_date"
                    onChange={(date) => {
                      dispatch({ type: 'setFromDate', date });
                    }}
                    onChangeNative={(e) =>
                      e.target.value
                        ? dispatch({ type: 'setFromDate', date: moment(e.target.value).toDate() })
                        : dispatch({ type: 'setFromDate', date: undefined })
                    }
                  />
                </div>
                <div className="column">
                  <h4>
                    <Translate value="global.to_date" />
                  </h4>
                  <DatePickerWithFallback
                    locale={locale}
                    selectedDate={state.endDate}
                    maxDate={null}
                    minDate={state.startDate || moment().toDate()}
                    selectsEnd
                    startDate={state.startDate}
                    endDate={state.endDate}
                    placeholderI18nKey="global.choose_date"
                    onChange={(date) => {
                      dispatch({ type: 'setToDate', date });
                    }}
                    onChangeNative={(e) =>
                      e.target.value
                        ? dispatch({ type: 'setToDate', date: moment(e.target.value).toDate() })
                        : dispatch({ type: 'setToDate', date: undefined })
                    }
                  />
                </div>
              </div>
              <h4>
                <Translate value="system_user_details.vacation_planning.secondary_caregivers_header" />
              </h4>
              {state.selectedSecondaries.map((secondary, i) => {
                return (
                  <div className="columns" key={secondary.id}>
                    <div className="column is-8">
                      <EditableSelect
                        isEditable={true}
                        isEditing={true}
                        defaultValue="no-selection"
                        options={caregiverOptions}
                        placeholder={I18n.t(`system_user_details.vacation_planning.choose_${caregiverType}`)}
                        isLoadingOptions={false}
                        error={null}
                        errorI18nKey="system_user_details.vacation_planning.error.fetching_doctors"
                        onChange={setCaregiverForSecondary(secondary.id)}
                        name="secondary-pal-select"
                      />
                    </div>
                    <div className={`column ${i > 0 ? 'is-3' : 'is-4'}`}>
                      <input type="text" value={secondary.numPatients} onChange={setNumberForSecondary(secondary.id)} />
                    </div>
                    {i > 0 ? (
                      <div className="column is-1 vertical-align no-padding">
                        <span
                          onClick={() => dispatch({ type: 'removeSecondary', id: secondary.id })}
                          className="icon x-small cross pointer"
                        ></span>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              {state.selectedSecondaries.length < caregivers.length && state.numRemaining > 0 ? (
                <Button onClick={() => dispatch({ type: 'addSecondary' })} buttonType="secondary" fullWidth={true}>
                  <Translate value="system_user_details.vacation_planning.add_caregiver" />
                </Button>
              ) : null}

              <h4>
                <Translate value="system_user_details.vacation_planning.patients_remaining_header" />
              </h4>
              <div className="mb-25">
                <Translate
                  value={`system_user_details.vacation_planning.patients_remaining${
                    state.numRemaining === 1 ? '_singular' : ''
                  }`}
                  numPatients={state.numRemaining > 0 ? state.numRemaining.toLocaleString() : 0}
                />
              </div>

              <LoaderButton
                onClick={registerVacation}
                isLoading={systemUsers.postingVacation}
                disabled={
                  !state.startDate ||
                  !state.endDate ||
                  state.numRemaining > 0 ||
                  !state.selectedSecondaries.every((s) => !!s.caregiverId)
                }
                fullWidth={true}
              >
                <Translate value="system_user_details.vacation_planning.save_vacation" />
              </LoaderButton>

              {state.validationError ? (
                <div className="error-message-block">
                  <Translate value={`system_user_details.vacation_planning.error.${state.validationError}`} />
                </div>
              ) : null}
            </Fragment>
          )}
        </div>
      </ContentLoader>
      <Modal
        visible={systemUsers.vacationDateChangeModalVisible}
        actionCompletable={true}
        size="auto"
        headerI18nKey="system_user_details.vacation_planning.change_to_date"
        actionI18nKey="system_user_details.vacation_planning.save_to_date"
        onClose={hideVacationDateChangeModal}
        onActionCompleted={saveNewToDate}
        actionCompleting={systemUsers.updatingVacation}
      >
        <DatePickerWithFallback
          inline
          locale={locale}
          selectedDate={state.newToDate}
          maxDate={null}
          minDate={user.postedVacation && moment(user.postedVacation.startDate, DATE_FORMAT).add(1, 'd').toDate()}
          placeholderI18nKey="global.choose_date"
          onChange={(date) => {
            dispatch({ type: 'setNewToDate', date });
          }}
          onChangeNative={(e) =>
            e.target.value
              ? dispatch({ type: 'setNewToDate', date: moment(e.target.value).toDate() })
              : dispatch({ type: 'setNewToDate', date: undefined })
          }
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    sharedData: state.sharedData,
    systemUsers: state.systemUsers,
    authority: state.sharedData.authorityTypes,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  postVacation,
  deleteVacation,
  updateVacation,
  showVacationDateChangeModal,
  hideVacationDateChangeModal
};

export default connect(mapStateToProps, mapActionsToProps)(VacationPlanner);

import * as types from '../constants/actionTypes';
import moment from 'moment';
import {
  getGenderFromPersonalNumber,
  extractProperties,
  getAgeFromPersonalNumber,
  removeItemFromArray,
  extractCaregivers,
  getAssignableCaregivers
} from '../utils';
import { USER_PROPERTY_KEYS, DATE_FORMAT, HTTP_STATUS_CODES, CAREGIVER_ROLE, MONITOR_SIZES } from '../constants';

const initialState = {
  members: [],
  returnToIndexPage: false,
  currentMember: {},
  memberDevice: {},
  editingMember: false,
  assignableDoctors: [],
  assignableNurses: [],
  additionalCriteria: {},
  advancedSearchVisible: false,
  memberComment: { text: '' },
  memberCommentText: '',
  currentSearchQuery: {
    limit: 15,
    offset: 0,
    pageIndex: 0,
    query: '',
    returnToIndexPage: false,
    searchTerm: '',
    sortBy: undefined
  },
  scheduledCommunication: [],
  messageModalActive: false,
  messageSubject: '',
  messageBody: '',
  hasChatMessages: false,
  latestMessageRead: false,
  exportJournalModalVisible: false,
  sparData: {},
  sparModalVisible: false,
  paymentExemption: {
    cardNumber: '',
    validUntil: null
  },
  paymentHistory: []
};

const clearState = {
  currentMember: {},
  memberDevice: {},
  editingMember: false,
  assignableDoctors: [],
  assignableNurses: [],
  updateMemberStatusError: undefined,
  memberComment: { text: '' },
  memberCommentText: '',
  scheduledCommunication: [],
  messageModalActive: false,
  messageSubject: '',
  messageBody: '',
  hasChatMessages: false,
  latestMessageRead: false,
  exportJournalModalVisible: false,
  sparData: {},
  sparModalVisible: false,
  paymentExemption: {
    cardNumber: '',
    validUntil: null
  },
  paymentHistory: []
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEMBERS_REQUEST:
      return { ...state, fetchingMembers: true };
    case types.GET_MEMBERS_SUCCESS:
      return {
        ...state,
        fetchingMembers: false,
        members: action.payload.members
          .map((userSearchResult) => {
            return { ...userSearchResult.user, ...userSearchResult.userExtras };
          })
          .map((member) => extractProperties(member, USER_PROPERTY_KEYS))
          .map((member) => {
            return {
              ...member,
              nameAndGender: {
                name: `${member.givenName} ${member.familyName}`,
                gender: getGenderFromPersonalNumber(member.personalNumber)
              },
              icons: [
                {
                  data: {
                    key: 'serviceStatus',
                    value: member.serviceStatus === 'blocked' ? 'blocked' : undefined
                  },
                  dataType: 'enum',
                  iconType: 'blocked'
                },
                {
                  data: {
                    key: 'lastMeasureDate',
                    value: member.lastMeasureDate
                  },
                  dataType: 'date',
                  iconType: 'measurement'
                },
                {
                  data: {
                    key: 'lastLabResultDate',
                    value: member.lastLabResultDate
                  },
                  dataType: 'date',
                  iconType: 'lab-result'
                },
                {
                  data: {
                    key: 'openSupportIssues',
                    value: member.openSupportIssues
                  },
                  dataType: 'number',
                  iconType: 'support-issue-open'
                },
                {
                  data: {
                    key: 'closedSupportIssues',
                    value: member.closedSupportIssues
                  },
                  dataType: 'number',
                  iconType: 'support-issue-closed'
                },
                {
                  data: {
                    key: 'lastMonitorSentDate',
                    value: member.lastMonitorSentDate
                  },
                  dataType: 'date',
                  iconType: 'monitor-shipped'
                }
              ]
            };
          }),
        pagination: action.payload.pagination,
        returnToIndexPage: action.returnToIndexPage,
        membersError: undefined
      };
    case types.GET_MEMBERS_ERROR:
      return { ...state, fetchingMembers: false, membersError: action.payload };
    case types.SET_CURRENT_SEARCH_QUERY:
      return { ...state, currentSearchQuery: action.payload };
    case types.GET_MEMBER_REQUEST:
      return { ...state, fetchingMember: true };
    case types.GET_MEMBER_SUCCESS: {
      let currentMember = extractProperties(
        { ...action.payload.members[0].user, ...action.payload.members[0].userExtras },
        USER_PROPERTY_KEYS
      );

      currentMember = extractCaregivers(currentMember);

      let paymentExemption = state.paymentExemption;
      if (currentMember.paymentExemption) {
        paymentExemption = currentMember.paymentExemption;
      }

      return { ...state, fetchingMember: false, currentMember, paymentExemption, memberError: undefined };
    }
    case types.GET_MEMBER_ERROR:
      return { ...state, fetchingMember: false, memberError: action.payload };
    case types.CLEAR_CURRENT_MEMBER:
      return { ...state, ...clearState };
    case types.UPDATE_MEMBER_REQUEST:
      return { ...state, updatingMember: true };
    case types.UPDATE_MEMBER_SUCCESS: {
      let updatedCurrentMember = { ...state.currentMember, ...extractProperties(action.payload, USER_PROPERTY_KEYS) };
      updatedCurrentMember = extractCaregivers(updatedCurrentMember);

      return {
        ...state,
        updatingMember: false,
        currentMember: updatedCurrentMember,
        memberUpdateError: undefined,
        blockMemberError: undefined
      };
    }
    case types.UPDATE_MEMBER_ERROR:
      return { ...state, updatingMember: false, memberUpdateError: action.payload };
    case types.GET_MEMBER_DEVICES_REQUEST:
      return { ...state, loadingMemberDevices: true };
    case types.GET_MEMBER_DEVICES_SUCCESS:
      return { ...state, loadingMemberDevices: false, memberDevice: action.payload, memberDevicesError: undefined };
    case types.GET_MEMBER_DEVICES_ERROR:
      return { ...state, loadingMemberDevices: false, memberDevicesError: action.payload };
    case types.TOGGLE_EDITING_MEMBER_DETAILS:
      return { ...state, editingMember: !state.editingMember };
    case types.GET_MEMBERS_WITH_NEW_LAB_RESULTS_REQUEST:
      return { ...state, loadingMembersWithNewLabResults: true };
    case types.GET_MEMBERS_WITH_NEW_LAB_RESULTS_SUCCESS:
      return {
        ...state,
        loadingMembersWithNewLabResults: false,
        membersWithNewLabResultsError: undefined,
        membersWithNewLabResults: action.payload.members,
        extractedText: action.payload.members
          .map((userSearchResult) => {
            return { ...userSearchResult.user, ...userSearchResult.userExtras };
          })
          .map(
            (member) =>
              `${member.givenName}\t${member.givenName}\t${member.familyName}\t${member.streetaddress || '-'}\t${
                member.postalCode || '-'
              }\t${member.city || '-'}\t${member.armCircumference ? member.armCircumference : '-'}\t${
                member.phoneNumber || '-'
              }\t${member.email || '-'}\t${moment(member.createdDate, DATE_FORMAT).format(
                'YYYY-MM-DD HH:mm'
              )}\t${getAgeFromPersonalNumber(member.personalNumber)}\t${''}\t${member.source || 'Sörmland'}\t${
                member.mobileDevice.platform
              }`
          )
          .join('\n')
      };
    case types.GET_MEMBERS_WITH_NEW_LAB_RESULTS_ERROR:
      return { ...state, loadingMembersWithNewLabResults: false, membersWithNewLabResultsError: action.payload };
    case types.GET_ASSIGNABLE_DOCTORS_REQUEST:
      return { ...state, loadingAssignableDoctors: true };
    case types.GET_ASSIGNABLE_DOCTORS_SUCCESS:
      return {
        ...state,
        loadingAssignableDoctors: false,
        assignableDoctors: getAssignableCaregivers(action.payload.members, CAREGIVER_ROLE.DOCTOR, state.currentMember),
        assignableDoctorsError: undefined
      };
    case types.GET_ASSIGNABLE_DOCTORS_ERROR:
      return { ...state, loadingAssignableDoctors: false, assignableDoctorsError: action.payload };
    case types.SET_ADDITIONAL_SEARCH_CRITERIA: {
      let additionalCriteria = { ...state.additionalCriteria };
      const { property, value } = action.payload;

      if (!value) {
        delete additionalCriteria[property];
      } else {
        additionalCriteria[property] = value;
      }

      return {
        ...state,
        additionalCriteria
      };
    }
    case types.TOGGLE_ADVANCED_SEARCH:
      return { ...state, advancedSearchVisible: !state.advancedSearchVisible };
    case types.SET_MEMBER_SERVICE_STATUS_REQUEST:
      return { ...state, updatingMemberStatus: true };
    case types.SET_MEMBER_SERVICE_STATUS_SUCCESS:
      return {
        ...state,
        updatingMemberStatus: false,
        currentMember: { ...state.currentMember, ...extractProperties(action.payload, USER_PROPERTY_KEYS) },
        editingMember: false,
        reactivateMemberError: undefined,
        memberUpdateError: undefined
      };
    case types.SET_MEMBER_SERVICE_STATUS_ERROR:
      return { ...state, updatingMemberStatus: false, updateMemberStatusError: action.payload };
    case types.GET_NEW_MEMBERS_DISPATCH_REQUEST:
      return { ...state, fetchingCSVFileContent: true };
    case types.GET_NEW_MEMBERS_DISPATCH_SUCCESS:
      return { ...state, fetchingCSVFileContent: false, newMembersDispatchError: undefined };
    case types.GET_NEW_MEMBERS_DISPATCH_ERROR:
      return { ...state, fetchingCSVFileContent: false, newMembersDispatchError: action.payload };
    case types.UPDATE_MEMBER_ANAMNESIS_ANSWER_REQUEST:
      return { ...state, updatingAnamnesisAnswer: true };
    case types.UPDATE_MEMBER_ANAMNESIS_ANSWER_SUCCESS:
      return {
        ...state,
        updatingAnamnesisAnswer: false,
        currentMember: {
          ...state.currentMember,
          [action.payload.memberProperty]:
            action.payload.memberProperty === 'armCircumference'
              ? Object.keys(MONITOR_SIZES)[Object.values(MONITOR_SIZES).indexOf(action.payload.value[0])]
              : action.payload.value[0]
        },
        updateAnamnesisAnswerError: undefined
      };
    case types.UPDATE_MEMBER_ANAMNESIS_ANSWER_ERROR:
      return { ...state, updatingAnamnesisAnswer: false, updateAnamnesisAnswerError: action.payload };
    case types.GET_MEMBER_COMMENT_REQUEST:
      return { ...state, loadingMemberComment: true };
    case types.GET_MEMBER_COMMENT_SUCCESS:
      return {
        ...state,
        loadingMemberComment: false,
        memberComment: action.payload,
        memberCommentText: action.payload.text || '',
        memberCommentError: undefined
      };
    case types.GET_MEMBER_COMMENT_ERROR:
      return {
        ...state,
        loadingMemberComment: false,
        memberComment: { text: '' },
        memberCommentError:
          action.payload.status && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.UPDATE_MEMBER_COMMENT_REQUEST:
      return { ...state, updatingMemberComment: true };
    case types.UPDATE_MEMBER_COMMENT_SUCCESS:
      return {
        ...state,
        updatingMemberComment: false,
        memberComment: action.payload,
        memberCommentText: action.payload.text || '',
        updatingMemberCommentError: undefined
      };
    case types.UPDATE_MEMBER_COMMENT_ERROR:
      return { ...state, updatingMemberComment: false, updatingMemberCommentError: action.payload };
    case types.UPDATE_MEMBER_COMMENT_TEXT:
      return { ...state, memberCommentText: action.payload };
    case types.GET_MEMBER_PROFILE_IMAGE_REQUEST:
      return { ...state, loadingProfileImage: true };
    case types.GET_MEMBER_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loadingProfileImage: false,
        currentMember: { ...state.currentMember, profileImageUrl: window.URL.createObjectURL(action.payload) },
        memberProfileImageError: undefined
      };
    case types.GET_MEMBER_PROFILE_IMAGE_ERROR:
      return { ...state, loadingProfileImage: false, memberProfileImageError: action.payload };
    case types.ADD_MANUAL_SHIPMENT_REQUEST:
      return { ...state, addingManualShipment: true };
    case types.ADD_MANUAL_SHIPMENT_SUCCESS:
      return { ...state, addingManualShipment: false, addManualShipmentError: undefined };
    case types.ADD_MANUAL_SHIPMENT_ERROR:
      return { ...state, addingManualShipment: false, addManualShipmentError: action.payload };
    case types.GET_SCHEDULED_COMMUNICATION_REQUEST:
      return { ...state, loadingScheduledCommunication: true };
    case types.GET_SCHEDULED_COMMUNICATION_SUCCESS:
      return {
        ...state,
        loadingScheduledCommunication: false,
        scheduledCommunication: action.payload,
        scheduledCommunicationError: undefined
      };
    case types.GET_SCHEDULED_COMMUNICATION_ERROR:
      return { ...state, loadingScheduledCommunication: false, scheduledCommunicationError: action.payload };
    case types.UPDATE_SCHEDULED_COMMUNICATION_REQUEST:
      return { ...state, updatingScheduledCommunication: true };
    case types.UPDATE_SCHEDULED_COMMUNICATION_SUCCESS: {
      const originalDate = moment(action.originalDate, DATE_FORMAT);
      const newDate = moment(action.updatedCommunication.scheduledDate, DATE_FORMAT);
      const diffInDays = newDate.diff(originalDate, 'd');

      const postponedCommunications = state.scheduledCommunication.map((c) => {
        if (moment(c.scheduledDate, DATE_FORMAT).isSameOrAfter(originalDate)) {
          return {
            ...c,
            scheduledDate: moment(c.scheduledDate, DATE_FORMAT).add(diffInDays, 'd').toISOString(),
            // The 'updated' property exists solely to support animating the changed values
            // more than once. If it's an even number one animation will run, if it's an odd
            // number another (identical) animation will run.
            updated: c.updated ? c.updated + 1 : 1
          };
        }

        return c;
      });

      return {
        ...state,
        scheduledCommunication: postponedCommunications,
        updatingScheduledCommunication: false,
        scheduledCommunicationUpdateError: undefined
      };
    }
    case types.UPDATE_SCHEDULED_COMMUNICATION_ERROR:
      return { ...state, updatingScheduledCommunication: false, scheduledCommunicationUpdateError: action.payload };
    case types.DELETE_SCHEDULED_COMMUNICATION_REQUEST:
      return { ...state, deletingScheduledCommunication: true };
    case types.DELETE_SCHEDULED_COMMUNICATION_SUCCESS:
      return {
        ...state,
        deletingScheduledCommunication: false,
        scheduledCommunication: removeItemFromArray(state.scheduledCommunication, {
          index: state.scheduledCommunication.findIndex((c) => c.id === action.deletedId)
        }),
        scheduledCommunicationDeleteError: undefined
      };
    case types.DELETE_SCHEDULED_COMMUNICATION_ERROR:
      return { ...state, deletingScheduledCommunication: false, scheduledCommunicationDeleteError: action.payload };
    case types.SHOW_MESSAGE_MODAL:
      return { ...state, messageModalActive: true };
    case types.HIDE_MESSAGE_MODAL:
      return { ...state, messageModalActive: false, messageSubject: '', messageBody: '' };
    case types.SEND_MESSAGE_REQUEST:
      return { ...state, sendingMessage: true };
    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendingMessage: false,
        messageModalActive: false,
        messageSubject: '',
        messageBody: '',
        sendMessageError: undefined
      };
    case types.SEND_MESSAGE_ERROR:
      return { ...state, sendingMessage: false, sendMessageError: action.payload };
    case types.UPDATE_MESSAGE_SUBJECT:
      return { ...state, messageSubject: action.payload };
    case types.UPDATE_MESSAGE_BODY:
      return { ...state, messageBody: action.payload };
    case types.GET_MEMBER_JOURNAL_REQUEST:
      return { ...state, fetchingMemberJournal: true };
    case types.GET_MEMBER_JOURNAL_SUCCESS:
      return {
        ...state,
        fetchingMemberJournal: false,
        exportJournalModalVisible: false,
        memberJournalError: undefined
      };
    case types.GET_MEMBER_JOURNAL_ERROR:
      return { ...state, fetchingMemberJournal: false, memberJournalError: action.payload };
    case types.TOGGLE_EXPORT_JOURNAL_MODAL:
      return { ...state, exportJournalModalVisible: !state.exportJournalModalVisible };
    case types.GET_MEMBER_CHAT_MESSAGES_REQUEST:
      return { ...state, fetchingChatMessages: true };
    case types.GET_MEMBER_CHAT_MESSAGES_SUCCESS: {
      const caregiverMessages = action.payload.messages.filter(
        (message) => message.type !== 'banner' && message.author !== action.memberGuid
      );

      return {
        ...state,
        fetchingChatMessages: false,
        hasChatMessages: caregiverMessages.length > 0,
        latestMessageRead: caregiverMessages.length && caregiverMessages[caregiverMessages.length - 1].memberRead,
        chatMessagesError: undefined
      };
    }
    case types.GET_MEMBER_CHAT_MESSAGES_ERROR:
      return { ...state, fetchingChatMessages: false, chatMessagesError: action.payload };
    case types.GET_MEMBER_SPAR_DATA_REQUEST:
      return { ...state, fetchingSparData: true };
    case types.GET_MEMBER_SPAR_DATA_SUCCESS:
      return { ...state, fetchingSparData: false, sparData: action.payload, sparError: undefined };
    case types.GET_MEMBER_SPAR_DATA_ERROR:
      return { ...state, fetchingSparData: false, sparError: action.payload };
    case types.TOGGLE_SPAR_MODAL:
      return { ...state, sparModalVisible: !state.sparModalVisible };
    case types.POST_PAYMENT_EXEMPTION_REQUEST:
      return { ...state, postingPaymentExemption: true };
    case types.POST_PAYMENT_EXEMPTION_SUCCESS: {
      let paymentExemption = {
        cardNumber: action.payload.cardNumber,
        validUntil: moment(action.payload.validUntil).toDate()
      };

      return {
        ...state,
        postingPaymentExemption: false,
        currentMember: {
          ...state.currentMember,
          paymentExemption
        },
        paymentExemption,
        paymentExemptionUpdateError: undefined
      };
    }
    case types.POST_PAYMENT_EXEMPTION_ERROR:
      return { ...state, postingPaymentExemption: false, paymentExemptionPostError: action.payload };
    case types.UPDATE_PAYMENT_EXEMPTION_REQUEST:
      return { ...state, updatingPaymentExemption: true };
    case types.UPDATE_PAYMENT_EXEMPTION_SUCCESS: {
      let paymentExemption = {
        cardNumber: action.payload.cardNumber,
        validUntil: moment(action.payload.validUntil).toDate()
      };

      return {
        ...state,
        updatingPaymentExemption: false,
        currentMember: {
          ...state.currentMember,
          paymentExemption
        },
        paymentExemption,
        paymentExemptionUpdateError: undefined
      };
    }
    case types.UPDATE_PAYMENT_EXEMPTION_ERROR:
      return { ...state, updatingPaymentExemption: false, paymentExemptionUpdateError: action.payload };
    case types.UPDATE_PAYMENT_EXEMPTION_PROPERTY:
      return {
        ...state,
        paymentExemption: {
          ...state.paymentExemption,
          [action.property]: action.payload
        }
      };
    case types.DELETE_PAYMENT_EXEMPTION_REQUEST:
      return { ...state, deletingPaymentExemption: true };
    case types.DELETE_PAYMENT_EXEMPTION_SUCCESS:
      return {
        ...state,
        deletingPaymentExemption: false,
        currentMember: { ...state.currentMember, paymentExemption: null },
        paymentExemption: { cardNumber: '', validUntil: null },
        deletePaymentExemptionError: undefined
      };
    case types.DELETE_PAYMENT_EXEMPTION_ERROR:
      return { ...state, deletingPaymentExemption: false, deletePaymentExemptionError: action.payload };
    case types.GET_PAYMENT_HISTORY_REQUEST:
      return { ...state, fetchingPaymentHistory: true };
    case types.GET_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingPaymentHistory: false,
        paymentHistory: action.payload,
        paymentHistoryError: undefined
      };
    case types.GET_PAYMENT_HISTORY_ERROR:
      return { ...state, fetchingPaymentHistory: false, paymentHistoryError: action.payload };
    case types.ASSIGN_CAREGIVER_REQUEST:
      return { ...state, assigningCaregiver: true };
    case types.ASSIGN_CAREGIVER_SUCCESS:
      return {
        ...state,
        assigningCaregiver: false,
        currentMember: { ...state.currentMember, ...extractCaregivers(action.payload) }
      };
    case types.ASSIGN_CAREGIVER_ERROR:
      return { ...state, assigningCaregiver: false, assignCaregiverError: action.payload };
    default:
      return state;
  }
};

export default membersReducer;

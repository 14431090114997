import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import {
  getSystemUsers,
  setAssignmentEditUser,
  showNotification,
  addAssignmentPriority,
  removeAssignmentPriority
} from '../../actions';
import api from '../../api/apiClient';
import MemberSearch from '../MemberSearch';
import Modal from '../Modal';
import { DATE_FORMAT } from '../../constants';

const SystemUserSearchView = (props) => {
  const [priorityLimit, setPriorityLimit] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [assignmentPriorityModalVisible, setAssignmentPriorityModalVisible] = useState(false);
  const [assignmentPriorityOverviewModalVisible, setAssignmentPriorityOverviewModalVisible] = useState(false);

  const getSystemUsers = (options) => {
    props.getSystemUsers(props.authToken, options);
  };

  const removeCaregiverPriority = () => {
    setIsUpdating(true);
    api
      .deleteAssignmentPriority(props.authToken, props.search.assignmentEditUser.guid)
      .then(() => {
        setIsUpdating(false);
        setAssignmentPriorityOverviewModalVisible(false);
        props.removeAssignmentPriority(props.search.assignmentEditUser.guid);
        props.showNotification(
          I18n.t('notification.priority_removed.success', {
            name: `${assignmentEditUser.givenName} ${assignmentEditUser.familyName}`
          }),
          'success'
        );
      })
      .catch((error) => {
        console.log(error);
        setIsUpdating(false);
        props.showNotification(I18n.t('notification.priority_removed.error'), 'error');
      });
  };

  const setCaregiverPriorityLimit = () => {
    setIsUpdating(true);
    api
      .postAssignmentPriority(props.authToken, props.search.assignmentEditUser.guid, { limit: priorityLimit })
      .then((response) => {
        setIsUpdating(false);
        setAssignmentPriorityModalVisible(false);
        setPriorityLimit('');
        props.addAssignmentPriority(props.search.assignmentEditUser.guid, response);
        props.showNotification(
          I18n.t('notification.priority_saved.success', {
            name: `${assignmentEditUser.givenName} ${assignmentEditUser.familyName}`
          }),
          'success'
        );
      })
      .catch((error) => {
        console.log(error);
        setIsUpdating(false);
        props.showNotification(I18n.t('notification.priority_saved.error'), 'error');
      });
  };

  const { search } = props;
  const { assignmentEditUser } = search;

  return (
    <Fragment>
      <MemberSearch
        onSearch={getSystemUsers}
        searchResult={search}
        urlFragment="user"
        onEditUserInline={props.setAssignmentEditUser}
        onAssignmentPriorityOverview={() => setAssignmentPriorityOverviewModalVisible(true)}
        onAssignmentPriority={() => setAssignmentPriorityModalVisible(true)}
      />
      <Modal
        headerI18nKey="system_user_search.assignment_priority.give_priority_header"
        actionI18nKey="global.buttons.save"
        visible={assignmentPriorityModalVisible}
        actionCompletable={!!priorityLimit}
        actionCompleting={isUpdating}
        onClose={() => setAssignmentPriorityModalVisible(false)}
        onActionCompleted={setCaregiverPriorityLimit}
      >
        <div className="stack">
          <div>
            <Translate value="global.doctor" />:{' '}
            <strong>
              {assignmentEditUser.givenName} {assignmentEditUser.familyName}
            </strong>
          </div>
          <div>
            <Translate value="system_user_search.assignment_priority.current_patients" />:{' '}
            <strong>{assignmentEditUser.numberOfPatients || '0'}</strong>
          </div>
          <div>
            <h4>
              <Translate value="system_user_search.assignment_priority.patients_increase" />
            </h4>
            <input
              type="number"
              placeholder={I18n.t('system_user_search.assignment_priority.patients_increase_placeholder')}
              value={priorityLimit}
              onChange={(e) => setPriorityLimit(e.target.value ? parseInt(e.target.value) : '')}
            />
          </div>
        </div>
      </Modal>
      <Modal
        headerI18nKey="system_user_search.assignment_priority.priority_overview_header"
        actionI18nKey="system_user_search.assignment_priority.delete_button"
        visible={assignmentPriorityOverviewModalVisible}
        actionCompletable={true}
        actionCompleting={isUpdating}
        onClose={() => setAssignmentPriorityOverviewModalVisible(false)}
        onActionCompleted={removeCaregiverPriority}
      >
        <div className="stack">
          <div>
            <Translate value="global.doctor" />:{' '}
            <strong>
              {assignmentEditUser.givenName} {assignmentEditUser.familyName}
            </strong>
          </div>
          {assignmentEditUser.assignmentPriority ? (
            <Fragment>
              <div>
                <Translate value="system_user_search.assignment_priority.priority_start_date" />:{' '}
                <strong>
                  {moment(assignmentEditUser.assignmentPriority.startDate, DATE_FORMAT).format('YYYY-MM-DD')}
                </strong>
              </div>
              <div>
                <Translate value="system_user_search.assignment_priority.patients_at_start" />:{' '}
                <strong>{assignmentEditUser.assignmentPriority.patientsAtStart}</strong>{' '}
                <Translate value="global.patients" />
              </div>
              <div>
                <Translate value="system_user_search.assignment_priority.priority_limit" />:{' '}
                <strong>{assignmentEditUser.assignmentPriority.limit}</strong> <Translate value="global.patients" />
              </div>
              <div>
                <Translate value="system_user_search.assignment_priority.assigned" />:{' '}
                <strong>{assignmentEditUser.assignmentPriority.patientsAssigned}</strong>{' '}
                <Translate value="global.of" /> <strong>{assignmentEditUser.assignmentPriority.limit}</strong>{' '}
                <Translate value="global.patients" />
              </div>
            </Fragment>
          ) : null}
        </div>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    search: state.systemUsers
  };
};

const mapActionsToProps = {
  getSystemUsers,
  setAssignmentEditUser,
  showNotification,
  addAssignmentPriority,
  removeAssignmentPriority
};

export default connect(mapStateToProps, mapActionsToProps)(SystemUserSearchView);

import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { motion, AnimatePresence } from 'framer-motion';
import Tooltip from '@material-ui/core/Tooltip';
import { Translate } from 'react-redux-i18n';
import ContentLoader from '../../ContentLoader';
import { DATE_FORMAT } from '../../../constants';
import Button from '../../Button';

const ScheduledCommunication = ({ items, isLoading, error, onUpdate, onDelete }) => {
  const [editingId, setEditingId] = useState();

  return (
    <ContentLoader isLoading={isLoading} error={error} errorTitleI18n="member_details.scheduled_communication.error">
      {!items.length ? (
        <Translate value="member_details.scheduled_communication.empty" />
      ) : (
        <Fragment>
          <div className="columns">
            <div className="column is-5 pb-0">
              <h4>
                <Translate value="member_details.scheduled_communication.name" />
              </h4>
            </div>
            <div className="column is-2 pb-0">
              <h4>
                <Translate value="member_details.scheduled_communication.sends_in" />
              </h4>
            </div>
            <div className="column is-5 pb-0"></div>
          </div>
          <div className="scheduled-communication">
            <AnimatePresence>
              {items.map((item) => {
                return (
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, y: -30, height: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.3 }}
                    className={`columns ${editingId === item.id ? 'fw-bold' : ''}`}
                    key={item.id}
                  >
                    <div className="column is-5">
                      <Tooltip
                        title={
                          <div className="fs-12">
                            <Translate
                              value={`member_details.scheduled_communication.type.${item.communicationType}`}
                            />
                          </div>
                        }
                        arrow
                        placement="top"
                      >
                        <span className="cursor-default">{item.name}</span>
                      </Tooltip>
                    </div>
                    <div className="column is-2">
                      <Tooltip
                        title={<div className="fs-12">{moment(item.scheduledDate, DATE_FORMAT).format('LLLL')}</div>}
                        arrow
                        placement="top"
                      >
                        <span
                          className={`cursor-default updated ${
                            item.updated !== undefined ? (item.updated % 2 === 0 ? 'even' : 'odd') : ''
                          }`}
                        >
                          {moment(item.scheduledDate, DATE_FORMAT).fromNow(true)}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="column is-5">
                      {editingId === item.id ? (
                        <div className="communication-actions">
                          <Button size="medium" onClick={() => onUpdate(item)}>
                            <Translate value="member_details.scheduled_communication.postpone" />
                          </Button>
                          <Button size="medium" buttonType="secondary" onClick={() => onDelete(item)}>
                            <Translate value="member_details.scheduled_communication.delete" />
                          </Button>
                          <span className="icon x-small cross pointer" onClick={() => setEditingId(null)}></span>
                        </div>
                      ) : (
                        <span className="edit-communication" onClick={() => setEditingId(item.id)}>
                          <Translate value="member_details.scheduled_communication.edit" />
                        </span>
                      )}
                    </div>
                  </motion.div>
                );
              })}
            </AnimatePresence>
          </div>
        </Fragment>
      )}
    </ContentLoader>
  );
};

export default ScheduledCommunication;
